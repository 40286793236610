import React from "react";
import { useState, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import VuiSelect from "components/VuiSelect";
import FormGroup from "@mui/material/FormGroup";
import container from "./container.png";
import truck from "./truck.png";
import boxes from "./boxes.png";
import bigBags from "./bigBags.png";
import sacks from "./sacks.png";
import barrel from "./barrel.png";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// NewUser page components

import FormField from "layouts/pages/users/new-user/components/FormField";

function Address(props) {
  //const [state, setState] = useState("...");
  // const { formField, values, errors, touched } = formData;
  // const { address1, address2, city, zip } = formField;
  // const { address1: address1V, address2: address2V, city: cityV, zip: zipV } = values;

  // const handleSetState = (event) => setState(event.target.value);

  const { values, handleChange, addRow, deleteRow } = props;
  const { tableList } = values;
  console.log(tableList);

  const [imgSrc, setImgSrc] = useState("");

  const [cargoOptions, setCargoOptions] = useState();
  console.log(cargoOptions);

  const imgHandler = () => {
    if (values.cargoType === "Boxes") {
      setImgSrc(boxes);
      setCargoOptions({ value: "Boxes", label: "Boxes" });
    } else if (values.cargoType === "BigBags") {
      setImgSrc(bigBags);
      setCargoOptions({ value: "BigBags", label: "BigBags" });
    } else if (values.cargoType === "Sacks") {
      setImgSrc(sacks);
      setCargoOptions({ value: "Sacks", label: "Sacks" });
    } else if (values.cargoType === "Barrel") {
      setImgSrc(barrel);
      setCargoOptions({ value: "Barrel", label: "Barrel" });
    } else {
      console.log("error");
    }
  };

  const optionsHandler = (e) => {
    console.log(e.value);

    if (e.value === "Boxes") {
      setImgSrc(boxes);
      setCargoOptions({ value: "Boxes", label: "Boxes" });
    } else if (e.value === "BigBags") {
      setImgSrc(bigBags);
      setCargoOptions({ value: "BigBags", label: "BigBags" });
    } else if (e.value === "Sacks") {
      setImgSrc(sacks);
      setCargoOptions({ value: "Sacks", label: "Sacks" });
    } else if (e.value === "Barrel") {
      setImgSrc(barrel);
      setCargoOptions({ value: "Barrel", label: "Barrel" });
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    imgHandler();
  }, []);

  return (
    <VuiBox>
      <VuiBox textAlign="center">
        <VuiTypography variant="SubTitle1" color="text" fontWeight="light" fontSize="1.5rem">
          Cargo Parameters
        </VuiTypography>
      </VuiBox>
      <Grid container>
        <Grid container item xs={6}>
          <VuiTypography margin={"auto"} color="text" fontWeight="light" fontSize="1rem">
            Enter Cargo data for stuffing.
          </VuiTypography>
          <VuiTypography margin={"auto"} color="text" fontWeight="light" fontSize="1rem">
            You cannot add more than 30 cargo types
          </VuiTypography>
        </Grid>
        <Grid container item xs={6} textAlign="center">
          <Grid item xs={12}>
            <img src={imgSrc} alt="GG" width={"80%"} />
          </Grid>
          <Grid item xs={12} display="grid" gridTemplateColumns={"190px auto"}>
            <VuiSelect
              color="info"
              value={cargoOptions}
              options={[
                { value: "Boxes", label: "Boxes" },
                { value: "BigBags", label: "BigBags" },
                { value: "Sacks", label: "Sacks" },
                { value: "Barrel", label: "Barrel" },
              ]}
              onChange={(e) => optionsHandler(e)}
            />

            <VuiButton
              name="addCargo"
              variant="gradient"
              color="info"
              onClick={(e) => addRow(e, cargoOptions)}
            >
              Add cargo
            </VuiButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={"center"} marginTop={"40px"}>
          <Grid item>
            <VuiTypography color="text" fontWeight="light" fontSize="1.1rem">
              Cargo dimensions have to be entered in millimeters (mm) and cargo weight in kilogram
              (kg)!
            </VuiTypography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
          marginTop={"2rem"}
        >
          <Grid item xs={1}>
            {/* <img src={imgSrc} alt="GG" width={38} height={35} /> */}
            {/* <VuiInput /> */}
          </Grid>
          <Grid item xs={2}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Name
            </VuiTypography>
            {/*   <VuiInput name="name" defaultValue={values.name} onChange={handleChange} /> */}
          </Grid>
          <Grid item xs={1.5}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Length
            </VuiTypography>
            {/*   <VuiInput name="length" defaultValue={values.length} onChange={handleChange} />*/}
          </Grid>
          <Grid item xs={1.5}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Width
            </VuiTypography>
            {/*   <VuiInput name="width" defaultValue={values.width} onChange={handleChange} />*/}
          </Grid>
          <Grid item xs={1.5}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Height
            </VuiTypography>
            {/*     <VuiInput name="height" defaultValue={values.height} onChange={handleChange} />*/}
          </Grid>
          <Grid item xs={1.5}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Weight
            </VuiTypography>
            {/*    <VuiInput name="weight" defaultValue={values.weight} onChange={handleChange} />*/}
          </Grid>
          <Grid item xs={1.5}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Qty
            </VuiTypography>
            {/*   <VuiInput name="qty" defaultValue={values.qty} onChange={handleChange} />*/}
          </Grid>
          <Grid item xs={1.5}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Type
            </VuiTypography>
            {/*   <VuiInput name="color" defaultValue={values.color} onChange={handleChange} />*/}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
          marginTop={"1.5rem"}
        >
          {tableList.map((val, idx) => {
            let name = `name-${idx}`,
              length = `length-${idx}`,
              width = `width-${idx}`,
              height = `height-${idx}`,
              weight = `weight-${idx}`,
              qty = `qty-${idx}`,
              color = `color-${idx}`,
              typeImg;

            if (val.ttype.value === "Boxes") {
              typeImg = boxes;
            } else if (val.ttype.value === "BigBags") {
              typeImg = bigBags;
            } else if (val.ttype.value === "Barrel") {
              typeImg = barrel;
            } else if (val.ttype.value === "Sacks") {
              typeImg = sacks;
            } else {
              console.log("error", val.ttype.value);
            }

            return (
              <Grid container key={val.index}>
                <Grid item xs={1}>
                  {idx === 0 ? (
                    <VuiBox>
                      <img src={imgSrc} alt="GG" width={"60%"} />
                      {/* <VuiInput /> */}
                    </VuiBox>
                  ) : (
                    <VuiBox color="white" fontWeight="light">
                      <img src={typeImg} alt="GG" width={"60%"} />
                      <FontAwesomeIcon
                        icon={faXmark}
                        cursor="pointer"
                        onClick={() => deleteRow("cargo", idx)}
                      />
                    </VuiBox>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <VuiInput
                    required
                    name="tname"
                    defaultValue={val.tname}
                    id={name}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <VuiInput
                    required
                    type="number"
                    name="tlength"
                    defaultValue={val.tlength}
                    data-id={idx}
                    id={length}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <VuiInput
                    required
                    type="number"
                    name="twidth"
                    defaultValue={val.twidth}
                    data-id={idx}
                    id={width}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <VuiInput
                    required
                    type="number"
                    name="theight"
                    defaultValue={val.theight}
                    data-id={idx}
                    id={height}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <VuiInput
                    required
                    type="number"
                    name="tweight"
                    defaultValue={val.tweight}
                    data-id={idx}
                    id={weight}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <VuiInput
                    required
                    type="number"
                    name="tqty"
                    defaultValue={val.tqty}
                    data-id={idx}
                    id={qty}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <VuiInput
                    name="ttype"
                    // value={cargoOptions.value}
                    defaultValue={val.ttype.value}
                    data-id={idx}
                    id={color}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </VuiBox>
  );
}

// typechecking props for Address
//Address.propTypes = {
//  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
//};

export default Address;
