import React from "react";
// @mui material components
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import Icon from "@mui/material/Icon";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
import FormField from "layouts/pages/account/components/FormField";

function SearchBoxVessel() {
  return (
    <VuiBox>
      <Card sx={{ marginBottom: "2rem" }}>
        <VuiBox>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={8}>
              <FormField
                placeholder="Enter Vessel Number"
                // onChange={e => setCategory(e.target.value)}
              />
            </Grid>
            <Grid item>
              <VuiButton variant="gradient" color="info" size="meduim" fontSize="16px">
                <Icon>search</Icon>&nbsp; Search
              </VuiButton>
            </Grid>
          </Grid>
        </VuiBox>
      </Card>
      <VuiBox marginBottom="1rem" display="flex" justifyContent="space-around" textAlign="center">
        <VuiTypography fontWeight="light" variant="lg" color="text" fontSize="2rem">
          Tracking feature is coming soon
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

export default SearchBoxVessel;
