import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import { useHistory } from "react-router-dom";
import { postBookShip } from "services/shipService";
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import Footer from "examples/Footer";
import Globe from "examples/Globe";

let Map = () => {
  return <GoogleMap defaultZoom={10} defaultCenter={{ lat: 45.421532, lng: -75.697189 }} />;
};
const WrappedMap = withScriptjs(withGoogleMap(Map));

function BookedLclDetails(props) {
  let bookShipDetails = props.location.details;

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear();
    return date;
  };

  let getTime = (time) => {
    var d = new Date(time);
    var time = d.getHours() + " : " + d.getMinutes();
    return time;
  };

  const dateWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    return time;
  };

  const { values } = breakpoints;

  const bookShipHandler = (shipKey, shipOwner) => {
    let bookingDetail = {
      serviceProviderID: shipOwner,
      shipMmsiKey: shipKey,
      status: "Booked",
    };
    postBookShip(bookingDetail);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Grid container>
          <VuiBox sx={{ width: "100%", height: "60vh" }}>
            <WrappedMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </VuiBox>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={10}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="text"
              >
                Book Ship Detail
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>

        <Card sx={{ overflow: "visible", zIndex: 10 }}>
          <VuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  MMSI KEY : {bookShipDetails.shipMmsiKey}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Current Port : {bookShipDetails.currentPort}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Departure Time : {getTime(bookShipDetails.departureTime)}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Arrival Time : {getTime(bookShipDetails.arrivalTime)}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Destination Port : {bookShipDetails.destinationPort}
                </VuiTypography>
              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      </VuiBox>
    </DashboardLayout>
  );
}

export default BookedLclDetails;
