import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import Alerts from "layouts/pages/alerts";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";

// Add ship //
import AvailableShip from "layouts/pages/ship/available-ship";
import BookedShip from "layouts/pages/ship/booked-ship";
import InActiveShip from "layouts/pages/ship/inactive-ship";
import SubmitRequest from "layouts/pages/support/submit-request";

// container //
import AvailableFCLContainer from "layouts/pages/container/available-fcl-container";
import AvailableLCLContainer from "layouts/pages/container/available-lcl-container";
import BookedFCLContainer from "layouts/pages/container/booked-fcl-container";
import BookedLCLContainer from "layouts/pages/container/booked-lcl-container";
import LoadCalculator from "layouts/pages/container/load-calculator";

//smart doc //
import ProfomaInvoice from "layouts/pages/smart-document/profoma-invoice";
import CommercialInvoice from "layouts/pages/smart-document/commercial-invoice";
import Quotation from "layouts/pages/smart-document/quotation";

import PurchaseOrder from "layouts/pages/smart-document/purchase-order";

// doc //
import VesselTracking from "layouts/pages/tracking/vessel-tracking";
import ContainerTracking from "layouts/pages/tracking/Container-tracking";
import BookFreight from "layouts/pages/freight/book-freight";
import BookShip from "layouts/pages/ship/book-ship";

// React icons
import { IoDocument } from "react-icons/io5";
// import { ImLocation2} from "react-icons/io5";
import { IoDocuments } from "react-icons/io5";
import {
  FaShoppingCart,
  FaShip,
  FaShippingFast,
  FaReceipt,
  FaFileInvoiceDollar,
  FaBoxOpen,
} from "react-icons/fa";
import { MdTrackChanges } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import BookShipPaymentPending from "layouts/pages/ship/book-ship-payment-pending";
import BookFclContainerPaymentPending from "layouts/pages/container/book-fcl-container-payment-pending";
import BookLclContainerPaymentPending from "layouts/pages/container/book-lcl-container-payment-pending";
import RejectedShipRequest from "layouts/pages/ship/rejected-ship-request";
import RejectedFclRequest from "layouts/pages/container/rejected-fcl-request";
import RejectedLclRequest from "layouts/pages/container/rejected-lcl-request";
import BillofLading from "layouts/pages/smart-document/bill-of-lading/BillofLading";
import ParcelOrderList from "layouts/pages/parcel/parcel-order-list";
import RecentSearchedOrders from "layouts/pages/parcel/recentSearchedOrders";
import SupportRequest from "layouts/pages/supportRequest";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <IoHome size="15px" />,
    collapse: [
      {
        name: "Book Shipment",
        key: "default",
        route: "/shipper",
        component: BookFreight,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },

  {
    type: "collapse",
    name: "Parcel",
    key: "parcel",
    icon: <FaBoxOpen size="15px" />,
    collapse: [
      {
        name: "Order list",
        key: "Order-list",
        route: "/shipper/order-list",
        component: ParcelOrderList,
      },
      {
        name: "Recent Searches",
        key: "recent-searches",
        route: "/shipper/recent-searches",
        component: RecentSearchedOrders,
      },
    ],
  },

  {
    type: "collapse",
    name: "Ship",
    key: "pages",
    icon: <FaShip size="15px" />,
    collapse: [
      {
        name: "Available Ship",
        key: "Available-ship",
        route: "/shipper/available-Ship",
        component: AvailableShip,
      },

      {
        name: "Booked Ships",
        key: "Booked-ship",
        route: "/shipper/booked-ships",
        component: BookedShip,
      },

      // {
      //   name: "Booked Ship with Payment Pending",
      //  key: "Booked-Ship-with-Payment-Pending",
      //  route: "/shipper/pending-booking-request",
      //  component: BookShipPaymentPending,
      // },

      // {
      //   name: "Rejected Ship Booking Request",
      //   key: "Rejected-Ship-Booking-Request",
      //   route: "/shipper/reject-booking-request",
      //   component: RejectedShipRequest,
      // },
      // {
      //   name: "Inactive Ships",
      //   key: "inactive-ships",
      //   route: "/merchant/inactive-ships",
      //   component: InActiveShip,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Container",
    key: "applications",
    icon: <FaShippingFast size="15px" />,
    collapse: [
      {
        name: "Available Container",
        key: "available-container",
        collapse: [
          {
            name: "Available FCL Container",
            key: "Available-FCL-Container",
            route: "/shipper/available-fcl-container",
            component: AvailableFCLContainer,
          },

          {
            name: "Available LCL Container",
            key: "Available-LCL-Container",
            route: "/shipper/available-lcl-container",
            component: AvailableLCLContainer,
          },
        ],
      },
      {
        name: "Booked Container",
        key: "booked-container",
        collapse: [
          {
            name: "Booked FCL Container",
            key: "Booked-FCL-Container",
            route: "/shipper/booked-fcl-container",
            component: BookedFCLContainer,
          },
          {
            name: "Booked LCL Container",
            key: "Booked-LCL-Container",
            route: "/shipper/booked-lcl-container",
            component: BookedLCLContainer,
          },
        ],
      },

      //  {
      //   name: "Book Container with Payment Pending",
      //   key: "book-container-payment-pending",
      //   collapse: [
      //    {
      //       name: "FCL Container",
      //       key: "Book-FCL-Container-With-Payment-Pending",
      //       route: "/shipper/fclContainer-pending-booking-request",
      //       component: BookFclContainerPaymentPending,
      //    },
      //    {
      //      name: "LCL Container",
      //      key: "Book-LCL-Container-With-Payment-Pending",
      //     route: "/shipper/lclContainer-pending-booking-request",
      //     component: BookLclContainerPaymentPending,
      //   },
      // ],
      // },

      // {
      //   name: "Rejected Booking Request",
      //   key: "rejected-booking-request",
      //   collapse: [
      //     {
      //      name: "FCL Container",
      //     key: "Rejected-Fcl-Booking-Request",
      //     route: "/shipper/reject-fcl-booking-request",
      //     component: RejectedFclRequest,
      //   },
      //   {
      //     name: "LCL Container",
      //     key: "Rejected-lcl-Booking-Request",
      //    route: "/shipper/reject-lcl-booking-request",
      //     component: RejectedLclRequest,
      //    },
      //   ],
      //},

      {
        name: "Load Calculator",
        key: "load-calculator",
        route: "/shipper/load-calculator",
        component: LoadCalculator,
      },
    ],
  },
  {
    type: "collapse",
    name: "Smart Documents",
    key: "smart-documents",
    icon: <FaFileInvoiceDollar size="15px" />,
    collapse: [
      {
        name: "BitDocs",
        key: "invoice",
        route: "/shipper/profoma-invoice",
        component: ProfomaInvoice,
      },
    ],
  },
  {
    type: "collapse",
    name: "Tracking",
    key: "tracking",
    icon: <MdTrackChanges size="15px" />,
    collapse: [
      {
        name: "Vessel Tracking",
        key: "vessel-tracking",
        route: "/shipper/vessel-tracking",
        component: VesselTracking,
      },
      {
        name: "Container Tracking",
        key: "container-tracking",
        route: "/shipper/container-tracking",
        component: ContainerTracking,
      },
    ],
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <FaReceipt size="15px" />,
    collapse: [
      {
        name: "Submit Request",
        key: "Submit-Request",
        route: "/shipper/submit-request",
        component: SupportRequest,
      },
    ],
  },
];

export default routes;
