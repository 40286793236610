import React from "react";
import BasicLayoutDoc from "layouts/authentication/components/BasicLayoutDoc";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";

import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components

import FormField from "layouts/pages/account/components/FormField";
import VuiInput from "components/VuiInput";
import VuiBadge from "components/VuiBadge";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import VuiButton from "components/VuiButton";
import Icon from "@mui/material/Icon";
import swal from "sweetalert";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "layouts/loader/loader";

import { getPorts } from "services/shipService";
import { searchShip, searchLcl, searchFcl, searchShipNew } from "services/logisticsService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineItem from "examples/Timeline/TimelineItem";
import { IoBoatOutline } from "react-icons/io5";
import { MdCompareArrows } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { IoMdWarning } from "react-icons/io";
import ErrorImg from "./exclamation-mark.png";

import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import DataTable from "examples/Tables/DataTable";

import QuickRequestDetails from "layouts/pages/container/quick-request";

// import QuickRequestDetails from "../container/quick-request";

import { useForm } from "react-hook-form";
import TabPanel from "./tab";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import LockIcon from "@mui/icons-material/Lock";
import ShipPic from "./favicon.png";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { getAddress, createShipment } from "services/parcelService";

import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { getAddressNew } from "services/parcelService";
import { getAddressDetails } from "services/parcelService";
import { validateAddress } from "services/parcelService";
import { createTransaction } from "services/parcelService";
import NewLoader from "./Loader.gif";
import { isMobile, isBrowser } from "react-device-detect";
import NewBitnauticLogo from "../../../assets/images/bitnautic-white-log.png";

import useStyles from "./styles";

// Vision UI Dashboard PRO React example components

// import Footer from "examples/Footer";

function PublicSearch() {
  const classes = useStyles();

  let history = useHistory();

  const [errorMsg, setErrorMsg] = useState({
    fromName: { errorMsg: "" },
    fromStreet1: { errorMsg: "" },
    fromCountry: { errorMsg: "" },
    fromCity: { errorMsg: "" },
    fromState: { errorMsg: "" },
    fromZip: { errorMsg: "" },
    toName: { errorMsg: "" },
    toStreet1: { errorMsg: "" },
    toCountry: { errorMsg: "" },
    toCity: { errorMsg: "" },
    toState: { errorMsg: "" },
    toZip: { errorMsg: "" },
    parcelLength: { errorMsg: "" },
    parcelWidth: { errorMsg: "" },
    parcelHeight: { errorMsg: "" },
    parcelDistanceUnit: { errorMsg: "" },
    parcelWeight: { errorMsg: "" },
    parcelMassUnit: { errorMsg: "" },
  });

  const [loading, setLoading] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [selectedMainTab, setSelectedMainTab] = useState(0);
  const [selectedFreightosTab, setSelectedFreightosTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const { values } = breakpoints;
  const [type, setType] = useState("");
  const [readyToLoad, setReadyToLoad] = useState("");
  const [typeOfContainer, setTypeOfContainer] = useState("");
  const [cargoWeight, setCargoWeight] = useState(0);
  const [cargoVolume, setCargoVolume] = useState(0);
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [currentPort, setCurrentPort] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [currentPortCode, setCurrentPortCode] = useState("");
  const [destinationPortCode, setDestinationPortCode] = useState("");
  const [featuredShipData, setFeaturedShipData] = useState([]);
  const [newShipData, setNewShipData] = useState([]);
  const [shipData, setShipData] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [newShipping, setNewShipping] = useState(false);
  const [open, setOpen] = useState(false);

  const [parcelRes, setParcelRes] = useState();
  const [parcelboolean, setParcelBoolean] = useState(false);
  const [parcelRatesboolean, setParcelRatesBoolean] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [responseLoader, setResponseLoader] = useState(false);
  const [responseLoader1, setResponseLoader1] = useState(false);

  const [fromName, setFromName] = useState("");
  const [fromCompany, setFromCompany] = useState("");
  const [fromStreet1, setFromStreet1] = useState("");
  const [fromStreet1Only, setFromStreet1Only] = useState("");
  const [fromStreet2, setFromStreet2] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [fromState, setFromState] = useState("");
  const [fromZip, setFromZip] = useState("");
  const [fromCountry, setFromCountry] = useState("");
  const [fromPhone, setFromPhone] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [fromIconValue, setFromIconValue] = useState(true);

  const [toName, setToName] = useState("");
  const [toCompany, setToCompany] = useState("");
  const [toStreet1, setToStreet1] = useState("");
  const [toStreet1Only, setToStreet1Only] = useState("");
  const [toStreet2, setToStreet2] = useState("");
  const [toCity, setToCity] = useState("");
  const [toState, setToState] = useState("");
  const [toZip, setToZip] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [toPhone, setToPhone] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [toIconValue, setToIconValue] = useState(true);

  const [parcelLength, setParcelLength] = useState("");
  const [parcelWidth, setParcelWidth] = useState("");
  const [parcelHeight, setParcelHeight] = useState("");
  const [parcelWeight, setParcelWeight] = useState("");
  const [parcelDistanceUnit, setParcelDistanceUnit] = useState({ value: "in", label: "IN" });
  const [parcelMassUnit, setParcelMassUnit] = useState({ value: "lb", label: "LB" });
  const [loadIconValue, setLoadIconValue] = useState(true);
  const [currency, setCurrency] = useState();
  const [goodsValue, setGoodsValue] = useState();
  const [goodsReady, setGoodsReady] = useState();

  const [addressObj, setAddressObj] = useState("");
  const [addressObj2, setAddressObj2] = useState("");
  const [newAddOnly, setNewAddOnly] = useState("");
  const [geoAdd, setGeoAdd] = useState("");
  const [searchedAddress1, setSearchedAddress1] = useState([]);

  const fromIcon = fromIconValue === true ? null : <img src={ErrorImg} width="15px" />;
  const toIcon = toIconValue === true ? null : <img src={ErrorImg} width="15px" />;
  const loadIcon = loadIconValue === true ? null : <img src={ErrorImg} width="15px" />;

  useEffect(() => {
    // Setting portsData from url if redirected from landing page

    if (window.location.search !== "") {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let pickup = urlParams.get("pickup");
      let destination = urlParams.get("destination");
      let pickupValue = pickup.substring(0, pickup.indexOf(" "));
      let destinationValue = destination.substring(0, destination.indexOf(" "));
      console.log(pickup);
      console.log(pickupValue);
      console.log(destination);
      console.log(destinationValue);

      getAddress(pickup).then((response) => {
        console.log(response);
        const resp = response.map((data) => {
          data.label = data.description;
          data.value = data.description;
          return data;
        });
        console.log(resp);

        const testFunc = async (val) => {
          console.log(val);
          setFromStreet1(val);
          console.log(val[0].place_id);

          const geocodeObj = await getAddressDetails(val[0].place_id);
          console.log(geocodeObj);
          const addressObject = geocodeObj && getAddressObject(geocodeObj.address_components);
          console.log("addressObject", addressObject);
          setAddressObj(addressObject);
          setFromStreet1Only(addressObject?.address);

          setFromCity(addressObject?.city);
          setFromCountry(addressObject?.country);
          setFromState(addressObject?.state);
          setFromZip(addressObject?.postal_code);
        };
        testFunc(resp);
      });

      getAddress(destination).then((response) => {
        console.log(response);
        const resp = response.map((data) => {
          data.label = data.description;
          data.value = data.description;
          return data;
        });
        console.log(resp);

        const testFunc2 = async (val) => {
          console.log(val);
          setToStreet1(val);
          console.log(val[0].place_id);

          const geocodeObj = await getAddressDetails(val[0].place_id);
          console.log(geocodeObj);
          const addressObject = geocodeObj && getAddressObject(geocodeObj.address_components);
          console.log("addressObject", addressObject);
          setAddressObj(addressObject);
          setToStreet1Only(addressObject?.address);

          setToCity(addressObject?.city);
          setToCountry(addressObject?.country);
          setToState(addressObject?.state);
          setToZip(addressObject?.postal_code);
        };
        testFunc2(resp);
      });

      // getPorts(pickupValue).then((response) => {
      //   const resp = response.map((data) => {
      //     let code = data.unloCode;
      //     let newUnloCode = code?.split(" ").join("");
      //     data.label = `${data.portName} (${newUnloCode}) (${data.countryName})`;
      //     data.key = newUnloCode;
      //     data.value = data.portName;
      //     return data;
      //   });
      //   console.log(resp);
      //   console.log(resp[0]);
      //   console.log(resp[0].value);
      //   console.log(resp[0].label);
      //   setCurrentPort(resp[0]);
      //   setCurrentPortCode(resp[0].key);
      // });

      // getPorts(destinationValue).then((response) => {
      //   const resp = response.map((data) => {
      //     let code = data.unloCode;
      //     let newUnloCode = code?.split(" ").join("");
      //     data.label = `${data.portName} (${newUnloCode}) (${data.countryName})`;
      //     data.key = newUnloCode;
      //     data.value = data.portName;
      //     return data;
      //   });
      //   console.log(resp);
      //   console.log(resp[0]);
      //   console.log(resp[0].value);
      //   console.log(resp[0].label);
      //   setDestinationPort(resp[0]);
      //   setDestinationPortCode(resp[0].key);
      // });
    } else {
      console.log("emptyurl");
    }
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetMainTabValue = (event, newValue) => setSelectedMainTab(newValue);

  const handleSetFreightosTabValue = (event, newValue) => setSelectedFreightosTab(newValue);

  const handleSetTabValue = (event, newValue) => setSelectedTab(newValue);

  const getAddressObject = (address_components) => {
    console.log(address_components);
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code_prefix", "postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "postal_town",
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    });

    // Fix the shape to match our schema

    address.address = address.street_number
      ? address.street_number + " " + address.street
      : address.street;

    // address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "United States") {
      address.state = address.province;
      delete address.province;
    } else {
      address.state = "";
    }
    return address;
  };

  let getDay = (date) => {
    var d = new Date(date);
    var dayy = d.toLocaleString("default", { weekday: "long" });
    var month = d.toLocaleString("default", { month: "short" });
    var date = dayy + ", " + d.getDate() + "-" + month + "-" + d.getUTCFullYear();
    return date;
  };

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear();
    return date;
  };

  let getTime = (time) => {
    var d = new Date(time);
    var time = d.getHours() + " : " + d.getMinutes();
    return time;
  };

  const dateWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    return time;
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      console.log(e);
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          let code = data.unloCode;

          let newUnloCode = code?.split(" ").join("");

          data.label = `${data.portName} (${newUnloCode}) (${data.countryName})`;
          data.key = newUnloCode;
          data.value = data.portName;

          return data;
        });

        setSearchedPorts(resp);
      });
    }
  };

  const handleSearch = () => {
    console.log(currentPort, destinationPort, readyToLoad, type);
    if (currentPort !== "" && destinationPort !== "" && readyToLoad !== "" && type !== "") {
      console.log("all ok");

      // swal("", "All Fields are required", "error");
      // return;
    } else {
      console.log(currentPort, destinationPort, readyToLoad, type);
      swal("", "All Fields are required", "error");
      return;
    }

    setResponseLoader1(true);

    let searchData;
    let searchDataNew;

    if (type === "ship") {
      searchData = {
        currentPort: currentPort.value,
        destinationPort: destinationPort.value,
        date: readyToLoad,
        type: type,
        typeOfContainer: typeOfContainer,
      };
      searchDataNew = {
        currentPort: currentPortCode,
        destinationPort: destinationPortCode,
        date: readyToLoad,
        type: type,
        typeOfContainer: typeOfContainer,
      };
    } else if (type === "fcl") {
      searchData = {
        currentPort: currentPort.value,
        destinationPort: destinationPort.value,
        date: readyToLoad,
        type: type,
        typeOfContainer: typeOfContainer,
      };
      searchDataNew = {
        currentPort: currentPortCode,
        destinationPort: destinationPortCode,
        date: readyToLoad,
        type: type,
        typeOfContainer: typeOfContainer,
      };
    } else {
      searchData = {
        currentPort: currentPort.value,
        destinationPort: destinationPort.value,
        date: readyToLoad,
        type: type,
        "cargoWeight(KG)": cargoWeight,
        "cargoVolume(CBM)": cargoVolume,
      };
      searchDataNew = {
        currentPort: currentPortCode,
        destinationPort: destinationPortCode,
        date: readyToLoad,
        type: type,
        typeOfContainer: typeOfContainer,
      };
    }

    console.log(searchData);
    console.log(searchDataNew);

    const handlersByType = {
      ship: searchShip,
      lcl: searchLcl,
      fcl: searchFcl,
    };
    console.log(searchData.type);
    console.log(searchDataNew.type);

    handlersByType[searchData.type](searchData).then((response) => {
      console.log(response, ">>>>>>>>>>>>>>>>>");
      setResponseLoader1(false);
      setFeaturedShipData(response);

      console.log(searchData.type);

      if (searchData.type === "ship") {
        console.log("error", searchData.type);
        searchShipNew(searchDataNew).then((res) => {
          console.log(res);
          setNewShipData(res);
          if (res.length === 0) {
            setShipData(false);
          } else {
            setShipData(true);
          }
        });
      }

      // let newResponse = [];
      // for (let item of response) {
      //   newResponse.push({
      //     ...item,
      //     departureTime: getTime(item.departureTime),
      //     arrivalTime: getTime(item.arrivalTime),
      //     action: (
      //       <VuiButton variant="gradient" color="info" onClick={() => addShipDetail(item)}>
      //         BookShip
      //       </VuiButton>
      //     ),
      //   });
      // }
      // setAvailableShipData(newResponse);
      // if (newResponse.length === 0) {
      //   setShipData(false);
      // } else {
      //   setShipData(true);
      // }
    });
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.parcelSenderValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    if (name === "fromName") {
      setFromName(value);
    } else if (name === "fromCompany") {
      setFromCompany(value);
    }
    // else if (name==="fromStreet1"){
    //   setFromStreet1(value)
    // }
    else if (name === "fromStreet2") {
      setFromStreet2(value);
    }
    // else if (name==="fromCity"){
    //   setFromCity(value)
    // }else if (name==="fromState"){
    //   setFromState(value)
    // }else if (name==="fromZip"){
    //   setFromZip(value)
    // }else if (name==="fromCountry"){
    //   setFromCountry(value)
    // }
    else if (name === "fromPhone") {
      setFromPhone(value);
    } else if (name === "fromEmail") {
      setFromEmail(value);
    } else if (name === "toName") {
      setToName(value);
    } else if (name === "toCompany") {
      setToCompany(value);
    }
    // else if (name==="toStreet1"){
    //   setToStreet1(value)
    // }
    else if (name === "toStreet2") {
      setToStreet2(value);
    }
    // else if (name==="toCity"){
    //   setToCity(value)
    // }else if (name==="toState"){
    //   setToState(value)
    // }else if (name==="toZip"){
    //   setToZip(value)
    // }else if (name==="toCountry"){
    //   setToCountry(value)
    // }
    else if (name === "toPhone") {
      setToPhone(value);
    } else if (name === "toEmail") {
      setToEmail(value);
    } else if (name === "parcelLength") {
      setParcelLength(value);
    } else if (name === "parcelWidth") {
      setParcelWidth(value);
    } else if (name === "parcelHeight") {
      setParcelHeight(value);
    } else if (name === "parcelWeight") {
      setParcelWeight(value);
    } else if (name === "parcelDistanceUnit") {
      setParcelDistanceUnit(value);
    } else if (name === "parcelMassUnit") {
      setParcelMassUnit(value);
    }
  };

  const handleEdit = (name, value) => {
    if (name === "fromZip") {
      setFromZip(value);
    } else if (name === "toZip") {
      setToZip(value);
    } else if (name === "fromCountry") {
      setFromCountry(value);
    } else if (name === "toCountry") {
      setToCountry(value);
    } else if (name === "fromCity") {
      setFromCity(value);
    } else if (name === "toCity") {
      setToCity(value);
    } else if (name === "fromState") {
      setFromState(value);
    } else if (name === "toState") {
      setToState(value);
    }
  };

  const findAddressNew = (e) => {
    if (e != undefined || e != "") {
      console.log(e);
      if (e.length < 2) return;
      getAddress(e).then((response) => {
        console.log(response);
        const resp = response.map((data) => {
          // let code = data.unloCode;

          // let newUnloCode = code?.split(" ").join("");

          data.label = data.description;
          // data.key = newUnloCode;
          data.value = data.description;

          return data;
        });
        console.log(resp);

        setSearchedAddress1(resp);
      });
    }
  };

  const handleSenderNxt = () => {
    // let errorLengthFromName = customValidations("fromName", fromName);
    // let errorLengthFromStreet1 = customValidations("fromStreet1", fromStreet1Only);
    let errorLengthFromCountry = customValidations("fromCountry", fromCountry);
    let errorLengthFromCity = customValidations("fromCity", fromCity);
    // let errorLengthState = customValidations("fromState", fromState);
    let errorLengthFromZip = customValidations("fromZip", fromZip);

    if (
      // errorLengthFromName !== 1 &&
      errorLengthFromCountry !== 1 &&
      errorLengthFromCity !== 1 &&
      // errorLengthFromStreet1 !== 1 &&
      // errorLengthState !== 1 &&
      errorLengthFromZip !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(
        // errorLengthFromName,
        errorLengthFromCountry,
        errorLengthFromCity,
        // errorLengthFromStreet1,
        // errorLengthState,
        errorLengthFromZip
      );

      setFromIconValue(false);

      return;
    }

    const validFromAdd = {
      address: {
        name: fromName,
        street1: fromStreet1Only,
        street2: fromStreet2,
        city: fromCity,
        state: fromState,
        zip: fromZip,
        country: fromCountry,
        // phone: addressFrom.phone,
        email: fromEmail,
      },
    };

    // validateAddress(validFromAdd).then((res) => {
    //   console.log("validatedResponse", res);

    //   if (res.is_valid === true) {
    //     setFromIconValue(true);
    //     setSelectedFreightosTab((prevState) => prevState + 1);
    //   } else {
    //     Swal.fire("", "Street (line 1) address is not valid", "warning");
    //   }
    // });
    setFromIconValue(true);
    setSelectedFreightosTab((prevState) => prevState + 1);
  };

  const handleReceiverNxt = () => {
    // let errorLengthToName = customValidations("toName", toName);
    // let errorLengthToStreet1 = customValidations("toStreet1", toStreet1Only);
    let errorLengthToCountry = customValidations("toCountry", toCountry);
    let errorLengthToCity = customValidations("toCity", toCity);
    // let errorLengthState = customValidations("fromState", fromState);
    let errorLengthToZip = customValidations("toZip", toZip);

    if (
      // errorLengthToName !== 1 &&
      errorLengthToCountry !== 1 &&
      errorLengthToCity !== 1 &&
      // errorLengthToStreet1 !== 1 &&
      // errorLengthState !== 1 &&
      errorLengthToZip !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(
        // errorLengthToName,
        errorLengthToCountry,
        errorLengthToCity,
        // errorLengthToStreet1,
        // errorLengthState,
        errorLengthToZip
      );

      setToIconValue(false);

      return;
    }

    const validToAdd = {
      address: {
        name: toName,
        street1: toStreet1Only,
        street2: toStreet2,
        city: toCity,
        state: toState,
        zip: toZip,
        country: toCountry,
        // phone: addressFrom.phone,
        email: toEmail,
      },
    };

    // validateAddress(validToAdd).then((res) => {
    //   console.log("validatedResponse", res);

    //   if (res.is_valid === false) {
    //     setToIconValue(true);
    //     setSelectedFreightosTab((prevState) => prevState + 1);
    //   } else {
    //     Swal.fire("", "Street (line 1) address is not valid", "warning");
    //   }
    // });
    setToIconValue(true);
    setSelectedFreightosTab((prevState) => prevState + 1);
  };

  // const handleLoadNxt = () => {
  //   let errorLengthParcelLength = customValidations("parcelLength", parcelLength);
  //   let errorLengthParcelWidth = customValidations("parcelWidth", parcelWidth);
  //   let errorLengthParcelHeight = customValidations("parcelHeight", parcelHeight);
  //   let errorLengthParcelDistanceUnit = customValidations("parcelDistanceUnit", parcelDistanceUnit);
  //   let errorLengthParcelWeight = customValidations("parcelWeight", parcelWeight);
  //   let errorLengthParcelMassUnit = customValidations("parcelMassUnit", parcelMassUnit);

  //   if (
  //     errorLengthParcelLength !== 1 &&
  //     errorLengthParcelWidth !== 1 &&
  //     errorLengthParcelHeight !== 1 &&
  //     errorLengthParcelDistanceUnit !== 1 &&
  //     errorLengthParcelWeight !== 1 &&
  //     errorLengthParcelMassUnit !== 1
  //   ) {
  //     console.log("all ok ");
  //   } else {
  //     console.log(
  //       errorLengthParcelLength,
  //       errorLengthParcelWidth,
  //       errorLengthParcelHeight,
  //       errorLengthParcelDistanceUnit,
  //       errorLengthParcelWeight,
  //       errorLengthParcelMassUnit
  //     );

  //     return;
  //   }

  //   setLoadIconValue(true);

  //   setSelectedFreightosTab((prevState) => prevState + 1);
  // };

  const handleConfirmedData = () => {
    // console.log("deffault value", parcelDistanceUnit.value);
    // console.log("deffault value", parcelMassUnit.value);
    let errorLengthFromCountry = customValidations("fromCountry", fromCountry);
    let errorLengthFromCity = customValidations("fromCity", fromCity);
    let errorLengthFromZip = customValidations("fromZip", fromZip);

    let errorLengthToCountry = customValidations("toCountry", toCountry);
    let errorLengthToCity = customValidations("toCity", toCity);
    let errorLengthToZip = customValidations("toZip", toZip);

    let errorLengthParcelLength = customValidations("parcelLength", parcelLength);
    let errorLengthParcelWidth = customValidations("parcelWidth", parcelWidth);
    let errorLengthParcelHeight = customValidations("parcelHeight", parcelHeight);
    let errorLengthParcelDistanceUnit = customValidations("parcelDistanceUnit", parcelDistanceUnit);
    let errorLengthParcelWeight = customValidations("parcelWeight", parcelWeight);
    let errorLengthParcelMassUnit = customValidations("parcelMassUnit", parcelMassUnit);

    if (
      errorLengthFromCountry !== 1 &&
      errorLengthFromCity !== 1 &&
      errorLengthFromZip !== 1 &&
      errorLengthToCountry !== 1 &&
      errorLengthToCity !== 1 &&
      errorLengthToZip !== 1 &&
      errorLengthParcelLength !== 1 &&
      errorLengthParcelWidth !== 1 &&
      errorLengthParcelHeight !== 1 &&
      errorLengthParcelDistanceUnit !== 1 &&
      errorLengthParcelWeight !== 1 &&
      errorLengthParcelMassUnit !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(
        errorLengthToZip,
        errorLengthParcelLength,
        errorLengthParcelWidth,
        errorLengthParcelHeight,
        errorLengthParcelDistanceUnit,
        errorLengthParcelWeight,
        errorLengthParcelMassUnit
      );

      setLoadIconValue(false);

      return;
    }

    setLoadIconValue(true);

    setResponseLoader(true);
    setParcelBoolean(false);
    setParcelRatesBoolean(false);
    const finalData = {
      addressFrom: {
        name: fromName,
        street1: fromStreet1Only,
        street2: fromStreet2,
        city: fromCity,
        state: fromState,
        zip: fromZip,
        country: fromCountry,
        // phone: addressFrom.phone,
        email: fromEmail,
      },
      addressTo: {
        name: toName,
        street1: toStreet1Only,
        street2: toStreet2,
        city: toCity,
        state: toState,
        zip: toZip,
        country: toCountry,
        // phone: addressTo.phone,
        email: toEmail,
      },
      parcels: [
        {
          weight: parcelWeight,
          length: parcelLength,
          width: parcelWidth,
          height: parcelHeight,
          distance_unit: parcelDistanceUnit.value,
          mass_unit: parcelMassUnit.value,
        },
      ],
      extra: {},
      async: false,
    };

    // Swal.fire("No shipment found", "please try again later", "warning");
    createShipment(finalData).then((response) => {
      console.log("parceelllll", response);
      console.log("parceelllll", response.rates);
      setResponseMsg(response.messages);
      setParcelRes(response.rates);
      setResponseLoader(false);

      console.log(response.rates.length);

      if (response.rates.length > 0) {
        setParcelBoolean(true);
      } else {
        setParcelRatesBoolean(true);
      }

      // swal("", "Proforma Invoice Added", "success");
    });
  };

  const handleParcel = () => {
    history.push({
      pathname: "/authentication/sign-in",
    });
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleChange = (name, value) => {
    if (name === "newMessage") {
      setNewMessage(value);
    } else {
      setNewShipping(value);
    }
  };

  const testFunc = async (val) => {
    console.log(val);
    setFromStreet1(val);

    const geocodeObj = await getAddressDetails(val.place_id);
    console.log(geocodeObj);
    const addressObject = geocodeObj && getAddressObject(geocodeObj.address_components);
    console.log("addressObject", addressObject);
    setAddressObj(addressObject);
    setFromStreet1Only(addressObject?.address);

    setFromCity(addressObject?.city);
    setFromCountry(addressObject?.country);
    setFromState(addressObject?.state);
    setFromZip(addressObject?.postal_code);
  };

  const testFunc2 = async (val) => {
    console.log(val);
    setToStreet1(val);

    const geocodeObj = await getAddressDetails(val.place_id);
    console.log(geocodeObj);
    const addressObject = geocodeObj && getAddressObject(geocodeObj.address_components);
    console.log("addressObject", addressObject);
    setAddressObj2(addressObject);
    setToStreet1Only(addressObject?.address);

    setToCity(addressObject?.city);
    setToCountry(addressObject?.country);
    setToState(addressObject?.state);
    setToZip(addressObject?.postal_code);
  };

  const addBooking = (bookData) => {
    console.log(bookData);
    history.push({
      pathname: "/shipper/book-ship",
      details: bookData,
    });
  };

  const handleSignIn = () => {
    history.push({
      pathname: "/authentication/sign-in",
    });
  };

  return (
    <BasicLayoutDoc>
      <AppBar elevation={10} className={classes.appbar}>
        <Toolbar>
          <div className={classes.navbar}>
            <Link to="/">
              <img
                className={classes.img}
                src={NewBitnauticLogo}
                width="100%"
                alt="Bitnautic Logo"
              />
            </Link>
          </div>
          <VuiTypography>
            <VuiButton
              color="info"
              variant="gradient"
              onClick={() => {
                handleSignIn();
              }}
            >
              Sign In
            </VuiButton>
          </VuiTypography>
        </Toolbar>
      </AppBar>
      <VuiBox sx={{ textAlign: "center", marginBottom: "3rem" }}>
        <VuiTypography color="text">FREIGHT THAT JUST WORKS</VuiTypography>
        <VuiTypography color="text">
          Compare, book, and manage your freight across the world’s top logistics providers, all on
          one platform.
        </VuiTypography>
      </VuiBox>

      <VuiBox sx={{ width: "80%", margin: "auto" }}>
        {/* <Grid container>
          <Grid item xs={12}>
            <Globe
              display={{ xs: "none", md: "block" }}
              position="absolute"
              top="10%"
              right={0}
              mt={{ xs: -12, lg: 1 }}
              mr={{ xs: 0, md: -10, lg: 10 }}
              canvasStyle={{ marginTop: "3rem" }}
            />
          </Grid>
        </Grid> */}

        <AppBar position="static" sx={{ background: "#d8dcf3", borderRadius: "1.25rem" }}>
          <Tabs
            variant="fullWidth"
            centered={true}
            orientation={tabsOrientation}
            value={selectedMainTab}
            onChange={handleSetMainTabValue}
            sx={{
              // "& button": { backgroundColor: "#F2F0FE" },
              // "& button.Mui-selected": { color: "#F2F0FE" },
              // "& button": {
              //   // tabTexTColor
              //   color: "#110F26 !important",
              // },

              "& button.Mui-selected": {
                // tabTexTColor
                color: "#F2F0FE !important",
              },

              "& span.MuiTabs-indicator": {
                // tabIndicatedColor
                backgroundColor: "#2D3B92",
                // color: "#110F26 !important",
              },
            }}
          >
            <Tab
              label="Book Parcel"
              sx={{ color: "#110F26 !important" }}

              // icon={<IoMdCube size="16px" color="white" fontWeight="bold" />}
            />
            <Tab
              label="Book Container"
              sx={{ color: "#110F26 !important" }}
              // icon={<IoDocument size="16px" color="white" fontWeight="bold" />}
            />
          </Tabs>
        </AppBar>

        <Card sx={{ overflow: "visible", marginTop: "1rem" }}>
          <TabPanel value={selectedMainTab} index={0}>
            <AppBar position="static" sx={{ background: "#d8dcf3", borderRadius: "1.25rem" }}>
              <Tabs
                variant="fullWidth"
                centered={true}
                orientation={tabsOrientation}
                value={selectedFreightosTab}
                onChange={handleSetFreightosTabValue}
                sx={{
                  // "& button": { backgroundColor: "#F2F0FE" },
                  // "& button.Mui-selected": { color: "#F2F0FE" },
                  // "& button": {
                  //   // tabTexTColor
                  //   color: "#110F26 !important",
                  // },

                  "& button.Mui-selected": {
                    // tabTexTColor
                    color: "#F2F0FE !important",
                  },

                  "& span.MuiTabs-indicator": {
                    // tabIndicatedColor
                    backgroundColor: "#2D3B92",
                    // color: "#110F26 !important",
                  },
                }}
              >
                <Tab
                  label="Sender"
                  sx={{ color: "#110F26 !important" }}
                  iconPosition="end"
                  icon={fromIcon}
                />
                <Tab
                  label="Receiver"
                  sx={{ color: "#110F26 !important" }}
                  iconPosition="end"
                  icon={toIcon}
                />
                <Tab
                  label="Load"
                  sx={{ color: "#110F26 !important" }}
                  iconPosition="end"
                  icon={loadIcon}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={selectedFreightosTab} index={0}>
              <Card sx={{ overflow: "visible" }}>
                <Grid container spacing={1}>
                  {/* <Grid item xs={12} sm={4}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Sender Name*
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter name"
                      value={fromName}
                      name="fromName"
                      error={errorMsg.fromName.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.fromName.errorMsg}
                    </VuiTypography>
                  </Grid> */}

                  <Grid item xs={12} sm={5}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Address*
                    </VuiTypography>

                    {/* <GooglePlacesAutocomplete
                      apiKey="AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI"
                      selectProps={{
                        isClearable: true,
                        value: fromStreet1,

                        onChange: (val) => {
                          testFunc(val);
                          // setNewAdd(val);
                          // setTestAdd(val);
                          // setNewAdd(val.value.description);
                        },
                      }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          {suggestions.map((suggestion) => {
                            return <div>{suggestion.description}</div>;
                          })}
                        </div>
                      )}
                    </GooglePlacesAutocomplete> */}

                    <VuiSelect
                      name="fromStreet1"
                      placeholder="Select address"
                      options={searchedAddress1}
                      value={fromStreet1}
                      error={errorMsg.fromStreet1.errorMsg.length > 0}
                      onInputChange={(e) => findAddressNew(e)}
                      onChange={(e) => testFunc(e)}
                      // onChange={(e) => handleChangeInput("street1", e)}
                      // onChange={(e) => setHomePort(e.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.fromStreet1.errorMsg}
                    </VuiTypography>
                  </Grid>

                  {/* <Grid item xs={12} sm={4}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Street(line 2)
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter address"
                      value={fromStreet2}
                      name="fromStreet2"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Company
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter company"
                      value={fromCompany}
                      name="fromCompany"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Country*
                    </VuiTypography>
                    {/* <VuiSelect
                      placeholder="Select Country"
                      options={[
                        { value: "india", label: "India" },
                        { value: "india", label: "India" },
                        { value: "india", label: "India" },
                      ]}
                    /> */}

                    <VuiInput
                      placeholder=""
                      value={fromCountry}
                      name="fromCountry"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.fromCountry.errorMsg.length > 0}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.fromCountry.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      City*
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter city"
                      value={fromCity}
                      name="fromCity"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.fromCity.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.fromCity.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      State*
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter state"
                      value={fromState}
                      name="fromState"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.fromState.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.fromState.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Zip*
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={fromZip}
                      name="fromZip"
                      error={errorMsg.fromZip.errorMsg.length > 0}
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.fromZip.errorMsg}
                    </VuiTypography>
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Email
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={fromEmail}
                      name="fromEmail"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Phone
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={fromPhone}
                      name="fromPhone"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3} mt={"1.5rem"}>
                    <VuiButton
                      variant="gradient"
                      color="info"
                      size="large"
                      fontSize="16px"
                      onClick={() => handleSenderNxt()}
                    >
                      Next
                    </VuiButton>
                  </Grid>
                </Grid>
              </Card>
            </TabPanel>
            <TabPanel value={selectedFreightosTab} index={1}>
              <Card sx={{ overflow: "visible" }}>
                <Grid container spacing={1}>
                  {/* <Grid item xs={12} sm={4}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Reciever Name*
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter name"
                      value={toName}
                      name="toName"
                      error={errorMsg.toName.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.toName.errorMsg}
                    </VuiTypography>
                  </Grid> */}

                  <Grid item xs={12} sm={5}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Address*
                    </VuiTypography>

                    {/* <GooglePlacesAutocomplete
                      apiKey="AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI"
                      selectProps={{
                        isClearable: true,
                        value: toStreet1,

                        onChange: (val) => {
                          testFunc2(val);
                          // setTestAdd(val);
                          // setNewAdd(val.value.description);
                        },
                      }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          {suggestions.map((suggestion) => {
                            return <div>{suggestion.description}</div>;
                          })}
                        </div>
                      )}
                    </GooglePlacesAutocomplete> */}

                    <VuiSelect
                      name="toStreet1"
                      placeholder="Select address"
                      options={searchedAddress1}
                      value={toStreet1}
                      error={errorMsg.toStreet1.errorMsg.length > 0}
                      onInputChange={(e) => findAddressNew(e)}
                      onChange={(e) => testFunc2(e)}
                      // onChange={(e) => handleChangeInput("street1", e)}
                      // onChange={(e) => setHomePort(e.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.toStreet1.errorMsg}
                    </VuiTypography>
                  </Grid>

                  {/* <Grid item xs={12} sm={4}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Street(line 2)
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter address"
                      value={toStreet2}
                      name="toStreet2"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Company
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter company"
                      value={toCompany}
                      name="toCompany"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Country*
                    </VuiTypography>
                    {/* <VuiSelect
                      placeholder="Select Current Port"
                      options={[
                        { value: "india", label: "India" },
                        { value: "india", label: "India" },
                        { value: "india", label: "India" },
                      ]}
                    /> */}

                    <VuiInput
                      placeholder=""
                      value={toCountry}
                      name="toCountry"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.toCountry.errorMsg.length > 0}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.toCountry.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      City*
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter city"
                      value={toCity}
                      name="toCity"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.toCity.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.toCity.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      State*
                    </VuiTypography>
                    <VuiInput
                      placeholder="Enter state"
                      value={toState}
                      name="toState"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.toState.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.toState.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Zip*
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={toZip}
                      name="toZip"
                      onChange={(e) => handleEdit(e.target.name, e.target.value)}
                      error={errorMsg.toZip.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.toZip.errorMsg}
                    </VuiTypography>
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Email
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={toEmail}
                      name="toEmail"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="white" fontWeight="regular">
                      Phone
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={toPhone}
                      name="toPhone"
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3} mt={"1.5rem"}>
                    <VuiButton
                      variant="gradient"
                      color="info"
                      size="large"
                      fontSize="16px"
                      onClick={() => handleReceiverNxt()}
                    >
                      Next
                    </VuiButton>
                  </Grid>
                </Grid>
              </Card>
            </TabPanel>
            <TabPanel value={selectedFreightosTab} index={2}>
              <Card sx={{ overflow: "visible" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={5}>
                    <VuiBox>
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        Dimensions
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiInput
                        sx={{ borderRadius: "15px 0px 0px 15px" }}
                        placeholder="Length:9"
                        type="number"
                        name="parcelLength"
                        value={parcelLength}
                        error={errorMsg.parcelLength.errorMsg.length > 0}
                        onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                      />
                      <VuiInput
                        sx={{ borderRadius: "0px" }}
                        placeholder="Width:9"
                        type="number"
                        name="parcelWidth"
                        value={parcelWidth}
                        error={errorMsg.parcelWidth.errorMsg.length > 0}
                        onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                      />
                      <VuiInput
                        sx={{ borderRadius: "0px 15px 15px 0px" }}
                        placeholder="Height:8"
                        type="number"
                        name="parcelHeight"
                        value={parcelHeight}
                        error={errorMsg.parcelHeight.errorMsg.length > 0}
                        onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                      />
                    </VuiBox>
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.parcelLength.errorMsg}
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Measurement (D)
                    </VuiTypography>

                    <VuiSelect
                      size="small"
                      placeholder="Select"
                      name="parcelDistanceUnit"
                      value={parcelDistanceUnit}
                      error={errorMsg.parcelDistanceUnit.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput("parcelDistanceUnit", e)}
                      options={[
                        { value: "in", label: "IN" },
                        { value: "cm", label: "CM" },
                        { value: "mm", label: "MM" },
                      ]}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.parcelDistanceUnit.errorMsg}
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Package Weight
                    </VuiTypography>
                    <VuiInput
                      placeholder="Upto 140 lb or 65kg"
                      type="number"
                      name="parcelWeight"
                      value={parcelWeight}
                      error={errorMsg.parcelWeight.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.parcelWeight.errorMsg}
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Measurement (PW)
                    </VuiTypography>

                    <VuiSelect
                      size="small"
                      placeholder="Select"
                      name="parcelMassUnit"
                      value={parcelMassUnit}
                      error={errorMsg.parcelMassUnit.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput("parcelMassUnit", e)}
                      options={[
                        { value: "lb", label: "Lb" },
                        { value: "kg", label: "Kg" },
                        { value: "oz", label: "Oz" },
                        { value: "gm", label: "Gm" },
                      ]}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.parcelMassUnit.errorMsg}
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={12} mt={"1rem"} sx={{ textAlign: "center" }}>
                    <VuiButton
                      variant="gradient"
                      color="info"
                      size="large"
                      onClick={() => handleConfirmedData()}
                    >
                      Search
                    </VuiButton>
                  </Grid>
                </Grid>
              </Card>
            </TabPanel>
          </TabPanel>

          <TabPanel value={selectedMainTab} index={1}>
            <VuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Current Port
                  </VuiTypography>

                  <VuiSelect
                    placeholder="Select Current Port"
                    options={searchedPorts}
                    value={currentPort}
                    onInputChange={(e) => findPort(e)}
                    onChange={(e) => {
                      setCurrentPort(e), setCurrentPortCode(e.key);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <VuiTypography variant="button" fontWeight="regular" color="text">
                    Destination Port
                  </VuiTypography>

                  <VuiSelect
                    placeholder="Select Destination Port"
                    options={searchedPorts}
                    value={destinationPort}
                    onInputChange={(e) => findPort(e)}
                    onChange={(e) => {
                      setDestinationPort(e), setDestinationPortCode(e.key);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Type
                  </VuiTypography>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "ship", label: "Ship" },
                      { value: "fcl", label: "FCL (Full Container Load)" },
                      { value: "lcl", label: "LCL (Less Container Load)" },
                    ]}
                    onChange={(e) => setType(e.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Ready To Load
                  </VuiTypography>
                  <VuiDatePicker
                    label="Enter Shippment Date"
                    input={{ placeholder: "Select a date" }}
                    options={{ minDate: new Date() }}
                    onChange={(e) => setReadyToLoad(e[0])}
                  />
                </Grid>
                {type === "lcl" ? (
                  <Grid container spacing={2} item xs={12} sm={4}>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        label={
                          <VuiTypography color="text" fontWeight="light" variant="button">
                            Corgo Weight (KG)
                          </VuiTypography>
                        }
                        placeholder="Enter Weight in KG"
                        type="number"
                        defaultValue={1}
                        value={cargoWeight}
                        onChange={(e) => {
                          setCargoWeight(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        label={
                          <VuiTypography color="text" fontWeight="light" variant="button">
                            Corgo Volume (CBM)
                          </VuiTypography>
                        }
                        placeholder="Enter Quantity"
                        type="number"
                        defaultValue={1}
                        value={cargoVolume}
                        onChange={(e) => setCargoVolume(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                ) : type === "fcl" ? (
                  <Grid item xs={12} sm={4}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Types of Container
                    </VuiTypography>
                    <VuiSelect
                      placeholder="Select Container Type"
                      options={[
                        { value: "20' Standard", label: "20' Standard" },
                        { value: "40' Standard", label: "40' Standard" },
                        { value: "40' High Cube", label: "40' High Cube" },
                        { value: "45' High Cube", label: "45' High Cube" },
                        { value: "20' Refrigerated", label: "20' Refrigerated" },
                        { value: "40' Refrigerated", label: "40' Refrigerated" },
                      ]}
                      onChange={(e) => setTypeOfContainer(e.value)}
                    />
                  </Grid>
                ) : (
                  <VuiBox></VuiBox>
                )}
                <Grid item xs={12} sm={3} mt={"1.5rem"} ml={"1.5rem"}>
                  <VuiButton
                    variant="gradient"
                    color="info"
                    size="large"
                    fontSize="16px"
                    onClick={handleSearch}
                  >
                    <Icon>search</Icon>&nbsp; Search
                  </VuiButton>
                </Grid>
              </Grid>
            </VuiBox>
          </TabPanel>
        </Card>

        <VuiBox sx={{ margin: "3rem", textAlign: "center" }}>
          <VuiTypography color="text" fontWeight="bold">
            Get real-time updates about your shipment from source to destination.
          </VuiTypography>
        </VuiBox>

        {parcelboolean === true ? (
          parcelRes.map((val, idx) => {
            return (
              <VuiBox>
                {isMobile ? (
                  <VuiBox>
                    <Card sx={{ marginTop: "1rem" }}>
                      <VuiBox>
                        <VuiBox>
                          <VuiBadge badgeContent={val.attributes[0]} container />
                          <VuiBadge badgeContent={val.attributes[1]} container />
                        </VuiBox>
                        <VuiBox>
                          <img src={val.provider_image_200} width="100px" alt="logo" />
                        </VuiBox>
                        <VuiBox>
                          <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                            {val.provider}
                          </VuiTypography>
                        </VuiBox>
                        <VuiBox>
                          <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                            {val.servicelevel.name}
                          </VuiTypography>
                        </VuiBox>
                        <VuiBox>
                          <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                            {val.estimated_days} days
                          </VuiTypography>
                        </VuiBox>
                        <VuiBox sx={{ display: "flex" }}>
                          <VuiBox sx={{ display: "flex", alignItems: "center" }}>
                            <VuiBox sx={{ marginRight: "0.5rem" }}>
                              <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                                ${val.amount_btnt_in_usd}
                              </VuiTypography>
                            </VuiBox>

                            <VuiBox>
                              <VuiButton
                                name="mobileView"
                                onClick={() => handleParcel()}
                                sx={{ padding: "10px" }}
                                variant="gradient"
                                color="info"
                              >
                                Buy with BTNT
                              </VuiButton>
                            </VuiBox>
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </Card>
                  </VuiBox>
                ) : (
                  <VuiBox>
                    <Card sx={{ marginTop: "1rem" }}>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiBadge badgeContent={val.attributes[0]} container />
                        <VuiBadge badgeContent={val.attributes[1]} container />
                      </VuiBox>
                      <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
                        <VuiBox sx={{ display: "flex" }}>
                          <VuiBox sx={{}}>
                            <img src={val.provider_image_200} width={40} alt="logo" />
                          </VuiBox>
                          <VuiBox sx={{ marginLeft: "1rem" }}>
                            <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                              {val.provider}
                              {"   "}
                              {val.servicelevel.name}
                            </VuiTypography>
                            <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                              Estimated delivery {val.estimated_days} days
                            </VuiTypography>
                          </VuiBox>
                        </VuiBox>

                        <VuiBox>
                          {/* <VuiBox
                            sx={{ display: "flex", marginBottom: "0.2rem", alignItems: "center" }}
                          >
                            <VuiBox>
                              <VuiTypography fontWeight="light" fontSize="0.9rem">
                                ${val.amount_usd}
                              </VuiTypography>
                            </VuiBox>
                            <VuiBox>
                              <VuiButton
                                onClick={() => handleDiaglog()}
                                sx={{ fontSize: "0.6rem" }}
                                variant="gradient"
                                color="info"
                              >
                                Buy with USD
                              </VuiButton>
                            </VuiBox>
                          </VuiBox> */}
                          <VuiBox sx={{ display: "flex", alignItems: "center" }}>
                            <VuiBox marginRight="1rem">
                              <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                                ${val.amount_btnt_in_usd}
                              </VuiTypography>
                            </VuiBox>
                            <VuiBox>
                              <VuiButton
                                name="fullView"
                                onClick={() => handleParcel()}
                                sx={{ fontSize: "0.6rem" }}
                                variant="gradient"
                                color="info"
                              >
                                Buy with BTNT
                              </VuiButton>
                            </VuiBox>
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </Card>
                  </VuiBox>
                )}
              </VuiBox>
            );
          })
        ) : responseLoader ? (
          <VuiBox sx={{ marginTop: "2rem", textAlign: "center" }}>
            <img src={NewLoader} alt="logo" />
            <VuiTypography>Please wait</VuiTypography>
          </VuiBox>
        ) : null}

        {/* {console.log("MSG", responseMsg)} */}

        {parcelRatesboolean === true ? (
          <>
            <Card sx={{ marginTop: "2rem" }}>
              <VuiTypography color="text" textAlign="center" fontSize="2rem">
                No shipment found
              </VuiTypography>
              {/* <VuiTypography textAlign="center">{responseMsg[2].text}</VuiTypography> */}
            </Card>
          </>
        ) : null}

        <VuiBox>
          {/* {console.log(featuredShipData.length, newShipData.length)} */}
          {featuredShipData.length < 1 && newShipData.length < 1 ? (
            <VuiBox></VuiBox>
          ) : (
            <VuiBox>
              <Card>
                <AppBar
                  position="static"
                  sx={{
                    background: "transparent",
                  }}
                >
                  <Tabs
                    variant="fullWidth"
                    centered={true}
                    orientation={tabsOrientation}
                    value={selectedTab}
                    onChange={handleSetTabValue}
                  >
                    <Tab
                      label="FEATURED"

                      // icon={<IoMdCube size="16px" color="white" fontWeight="bold" />}
                    />
                    <Tab
                      label="SHIPS"
                      // icon={<IoDocument size="16px" color="white" fontWeight="bold" />}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={selectedTab} index={0}>
                  {featuredShipData.length > 0 ? (
                    featuredShipData.map((val, idx) => {
                      // console.log(val, idx);
                      console.log(val);

                      return (
                        <Card sx={{ marginBottom: "2rem" }} key={idx}>
                          <Accordion elevation={0} sx={{ backgroundColor: "inherit" }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon color="white" />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <VuiBox>
                                <VuiTypography color="white" fontWeight="light" fontSize="1rem">
                                  {getDay(val.departureTime)}
                                </VuiTypography>
                                <VuiTypography color="white" fontWeight="light" fontSize="0.8rem">
                                  {val.currentPort} , POL
                                </VuiTypography>
                              </VuiBox>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TimelineItem
                                icon={<IoBoatOutline color="white" />}
                                title={
                                  <VuiBox>
                                    <VuiTypography
                                      color="white"
                                      fontWeight="light"
                                      fontSize="0.8rem"
                                    >
                                      {/* {location.name} , {codification} POL */}
                                    </VuiTypography>
                                    <VuiTypography
                                      color="white"
                                      fontWeight="light"
                                      fontSize="0.8rem"
                                    >
                                      {/* {location.facility.name} */}
                                    </VuiTypography>
                                  </VuiBox>
                                }
                                description={
                                  <VuiBox sx={{ display: "flex", flexWrap: "wrap" }}>
                                    <VuiBox>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {/* {transportation.vehicule.vehiculeName} */}
                                        {/* China India Express */}
                                      </VuiTypography>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {/* VGM Cut-off {getDay(cutOff.portCutoff.utc)} */}
                                      </VuiTypography>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {/* Port Cut-off {getDay(cutOff.vgm.utc)} */}
                                      </VuiTypography>
                                    </VuiBox>
                                    <VuiBox sx={{ marginLeft: "2rem" }}>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {" "}
                                        Local Voyage Ref. -{" "}
                                      </VuiTypography>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {" "}
                                        {/* Voyage Ref. {voyageReference} */}
                                      </VuiTypography>
                                    </VuiBox>
                                    <VuiTypography
                                      marginTop="1rem"
                                      color="white"
                                      fontWeight="light"
                                      fontSize="0.8rem"
                                    >
                                      {/* {getDay(arrivalDateLocal)} */}
                                    </VuiTypography>
                                  </VuiBox>
                                }
                                lastItem
                              />

                              <TimelineItem
                                icon={<MdCompareArrows color="white" />}
                                title={
                                  <VuiTypography
                                    marginTop="1rem"
                                    color="white"
                                    fontWeight="light"
                                    fontSize="0.8rem"
                                  >
                                    {/* {pointTo.location.name}, {pointTo.location.internalCode} */}
                                  </VuiTypography>
                                }
                                description={
                                  <VuiTypography color="white" fontWeight="light" fontSize="0.8rem">
                                    {/* {getDay(pointFrom1.departureDateLocal)} */}
                                  </VuiTypography>
                                }
                              />

                              <TimelineItem
                                icon={<GoPrimitiveDot color="white" />}
                                title={
                                  <VuiTypography
                                    marginTop="1rem"
                                    color="white"
                                    fontWeight="light"
                                    fontSize="0.8rem"
                                  >
                                    {/* {transportation1.meanOfTransport} */}
                                  </VuiTypography>
                                }
                                lastItem
                              />
                            </AccordionDetails>
                          </Accordion>

                          <AccordionSummary sx={{ marginTop: -2 }}>
                            <VuiBox
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <VuiBox>
                                <VuiTypography color="white" fontWeight="light" fontSize="1rem">
                                  {getDay(val.arrivalTime)}
                                </VuiTypography>
                                <VuiTypography color="white" fontWeight="light" fontSize="0.8rem">
                                  {val.destinationPort}
                                  {/* {pointTo1.location.internalCode} */}
                                </VuiTypography>
                              </VuiBox>
                              <VuiBox>
                                <VuiButton
                                  variant="gradient"
                                  color="info"
                                  onClick={() => {
                                    addBooking(val);
                                  }}
                                >
                                  Book Ship
                                </VuiButton>
                              </VuiBox>
                            </VuiBox>
                          </AccordionSummary>
                        </Card>
                      );
                    })
                  ) : (
                    <VuiBox>Nothing To Show</VuiBox>
                  )}
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  {newShipData.length > 0 ? (
                    newShipData.map((val, idx) => {
                      // console.log(val, idx);
                      // console.log(val);
                      // console.log(val.routingDetails);
                      let newData = val.routingDetails;

                      const newData0 = newData[0];
                      const newData1 = newData[1];
                      // console.log(newData0);
                      // console.log(newData1);

                      const { legTransitTime, pointFrom, pointTo, transportation } = newData0;
                      // console.log(legTransitTime, pointFrom, pointTo, transportation);
                      const {
                        legTransitTime: legTransitTime1,
                        pointFrom: pointFrom1,
                        pointTo: pointTo1,
                        transportation: transportation1,
                      } = newData1;
                      // console.log(legTransitTime1, pointFrom1, pointTo1, transportation1);
                      // console.log(pointFrom1.departureDateLocal);
                      // console.log(pointTo1.arrivalDateGmt);
                      // console.log(pointTo1.location.name);
                      // console.log(pointTo1.location.internalCode);
                      // console.log(transportation1.meanOfTransport);
                      const { voyage } = transportation;
                      const { voyageReference } = voyage;
                      // console.log(pointTo.location.name);
                      // console.log(pointTo.location.internalCode);
                      // console.log(voyageReference);
                      const { arrivalDateLocal } = pointTo;
                      // console.log(arrivalDateLocal);
                      // console.log(transportation.vehicule.vehiculeName);
                      // console.log(pointFrom.departureDateLocal);
                      const { location, cutOff } = pointFrom;
                      // console.log(cutOff);
                      // console.log(cutOff.portCutoff.utc);
                      // console.log(cutOff.vgm.utc);
                      // console.log(location);
                      // console.log(location.facility.name);
                      // console.log(location.locationCodifications[0].codification);
                      const [{ codification }] = location.locationCodifications;
                      // console.log(codification);
                      // console.log(location.name);
                      // const { pointFrom } = pointFrom;

                      return (
                        <Card sx={{ marginBottom: "2rem" }} key={idx}>
                          <Accordion elevation={0} sx={{ backgroundColor: "inherit" }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon color="white" />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <VuiBox>
                                <VuiTypography color="white" fontWeight="light" fontSize="1rem">
                                  {getDay(pointFrom.departureDateLocal)}
                                </VuiTypography>
                                <VuiTypography color="white" fontWeight="light" fontSize="0.8rem">
                                  {location.name} , {codification} POL
                                </VuiTypography>
                              </VuiBox>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TimelineItem
                                icon={<IoBoatOutline color="white" />}
                                title={
                                  <VuiBox>
                                    <VuiTypography
                                      color="white"
                                      fontWeight="light"
                                      fontSize="0.8rem"
                                    >
                                      {location.name} , {codification} POL
                                    </VuiTypography>
                                    <VuiTypography
                                      color="white"
                                      fontWeight="light"
                                      fontSize="0.8rem"
                                    >
                                      {location.facility.name}
                                    </VuiTypography>
                                  </VuiBox>
                                }
                                description={
                                  <VuiBox sx={{ display: "flex", flexWrap: "wrap" }}>
                                    <VuiBox>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {transportation.vehicule.vehiculeName}
                                        {/* China India Express */}
                                      </VuiTypography>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        VGM Cut-off {getDay(cutOff.portCutoff.utc)}
                                      </VuiTypography>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        Port Cut-off {getDay(cutOff.vgm.utc)}
                                      </VuiTypography>
                                    </VuiBox>
                                    <VuiBox sx={{ marginLeft: "2rem" }}>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {" "}
                                        Local Voyage Ref. -{" "}
                                      </VuiTypography>
                                      <VuiTypography
                                        color="white"
                                        fontWeight="light"
                                        fontSize="0.8rem"
                                      >
                                        {" "}
                                        Voyage Ref. {voyageReference}
                                      </VuiTypography>
                                    </VuiBox>
                                    <VuiTypography
                                      marginTop="1rem"
                                      color="white"
                                      fontWeight="light"
                                      fontSize="0.8rem"
                                    >
                                      {getDay(arrivalDateLocal)}
                                    </VuiTypography>
                                  </VuiBox>
                                }
                                lastItem
                              />

                              <TimelineItem
                                icon={<MdCompareArrows color="white" />}
                                title={
                                  <VuiTypography
                                    marginTop="1rem"
                                    color="white"
                                    fontWeight="light"
                                    fontSize="0.8rem"
                                  >
                                    {pointTo.location.name}, {pointTo.location.internalCode}
                                  </VuiTypography>
                                }
                                description={
                                  <VuiTypography color="white" fontWeight="light" fontSize="0.8rem">
                                    {getDay(pointFrom1.departureDateLocal)}
                                  </VuiTypography>
                                }
                              />

                              <TimelineItem
                                icon={<GoPrimitiveDot color="white" />}
                                title={
                                  <VuiTypography
                                    marginTop="1rem"
                                    color="white"
                                    fontWeight="light"
                                    fontSize="0.8rem"
                                  >
                                    {transportation1.meanOfTransport}
                                  </VuiTypography>
                                }
                                lastItem
                              />
                            </AccordionDetails>
                          </Accordion>

                          <AccordionSummary sx={{ marginTop: -2 }}>
                            <VuiBox
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <VuiBox>
                                <VuiTypography color="white" fontWeight="light" fontSize="1rem">
                                  {getDay(pointTo1.arrivalDateGmt)}
                                </VuiTypography>
                                <VuiTypography color="white" fontWeight="light" fontSize="0.8rem">
                                  {pointTo1.location.name}, &nbsp;
                                  {pointTo1.location.internalCode}
                                </VuiTypography>
                              </VuiBox>
                              <VuiBox>
                                <VuiButton variant="gradient" color="info">
                                  Book Ship
                                </VuiButton>
                              </VuiBox>
                            </VuiBox>
                          </AccordionSummary>
                        </Card>
                      );
                    })
                  ) : (
                    <VuiBox>Nothing To Show</VuiBox>
                  )}
                </TabPanel>
              </Card>
            </VuiBox>
          )}

          {responseLoader1 ? (
            <VuiBox sx={{ marginTop: "2rem", textAlign: "center" }}>
              <img src={NewLoader} alt="logo" />
              <VuiTypography color="text">Please wait</VuiTypography>
            </VuiBox>
          ) : null}

          {shipData === false ? (
            <VuiBox>
              <QuickRequestDetails />
            </VuiBox>
          ) : null}
        </VuiBox>
      </VuiBox>
    </BasicLayoutDoc>
  );
}
export default PublicSearch;
