const axios = require("axios");
import swal from "sweetalert";
import { baseUrl } from "../helpers/baseUrl";

export async function postBillOfLading(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/billOfLading/addBillOfLading`,
      data
    );
    console.log("res1>>>>>>>>>>>>>>>>>>>>>>", response);
    console.log("res2>>>>>>>>>>>>>>>>>>>>>>", response.data);
    return response.data;
  } catch (err) {
    console.log("res3>>>>>>>>>>>>>>>>>>>>>>", err.response);
    console.log("res4>>>>>>>>>>>>>>>>>>>>>>", err.response.data);
    return err.response.data;
  }
}

export async function addCompany(data) {
  var tokenStr = localStorage.getItem("jwtToken");

  const response = await axios.post(`${baseUrl}/serviceProvider/billOfLading/addCompany`, data);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getCompany() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/serviceProvider/billOfLading/getCompany`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getUserDetails(id) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/user/auth/getUser?id=${id}`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function updateInvoiceShipStatus(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  const response = await axios.post(
    `${baseUrl}/serviceProvider/ship/updateInvoiceShipStatus`,
    data
  );
  if (response) {
    return response;
  } else {
    swal("", response.message, "error");
  }
}
