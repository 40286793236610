import React from "react";
import { useState } from "react";

// formik components
//import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "./components/UserInfo";
import Address from "./components/Address";
import Socials from "./components/Socials";
import Profile from "./components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import SelectContainer from "./components/SelectContainer";
import StuffingSettings from "./components/StuffingSettings";
import { useForm } from "react-hook-form";
import { LoadCalculaterProvider } from "./loadCalculatorContext";

function getSteps() {
  return [
    "Equipment & Cargo Type",
    "Cargo Parameters",
    "Loading Type",
    "Container Type",
    "Parameters",
    "Results",
  ];
}

function getStepContent(stepIndex, values, handleChange, addRow, deleteRow) {
  switch (stepIndex) {
    case 0:
      return <UserInfo values={values} handleChange={handleChange} />;
    case 1:
      return (
        <Address
          values={values}
          handleChange={handleChange}
          addRow={addRow}
          deleteRow={deleteRow}
        />
      );
    case 2:
      return <Socials values={values} handleChange={handleChange} />;
    case 3:
      return (
        <SelectContainer
          values={values}
          handleChange={handleChange}
          addRow={addRow}
          deleteRow={deleteRow}
        />
      );
    case 4:
      return <StuffingSettings values={values} handleChange={handleChange} />;
    case 5:
      return <Profile values={values} handleChange={handleChange} />;
    default:
      return null;
  }
}

function LoadCalculator() {
  const [state, setState] = useState({
    equipment: "Container",
    cargoType: "Boxes",
    tableList: [
      {
        index: Math.random(),
        ttype: "",
        tname: "",
        tlength: "",
        twidth: "",
        theight: "",
        tweight: "",
        tqty: "",
        tcolor: "",
      },
    ],
    loadingType: "",
    pLength: "1000",
    pWidth: "1200",
    pHeight: "150",
    pPayload: "750",
    loadHeight: "2000",
    thickness: "50",
    containerSelection: "",
    containerType: [
      {
        index: Math.floor(Math.random() * 40) + 1,
        cType: "20dv",
        cLength: "5895",
        cWidth: "2350",
        cHeight: "2393",
        cTonnage: "28200",
      },
    ],
    containerTypeVehicle: [
      {
        index: Math.floor(Math.random() * 40) + 1,
        vType: "Truck",
        vLength: "5895",
        vWidth: "2350",
        vHeight: "2393",
        vTonnage: "28200",
      },
    ],
  });

  const {
    equipment,
    equipmentImg,
    cargoType,
    cargoImg,
    tableList,
    loadingType,
    pLength,
    pWidth,
    pHeight,
    pPayload,
    loadHeight,
    thickness,
    containerSelection,
    containerType,
    containerTypeVehicle,
  } = state;
  const values = {
    equipment,
    equipmentImg,
    cargoType,
    cargoImg,
    tableList,
    loadingType,
    pLength,
    pWidth,
    pHeight,
    pPayload,
    loadHeight,
    thickness,
    containerSelection,
    containerType,
    containerTypeVehicle,
  };

  const handleChange = (e, id) => {
    console.log(id);
    if (
      ["tname", "tlength", "twidth", "theight", "tweight", "tqty", "ttype"].includes(e.target.name)
    ) {
      console.log("error1>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", id, e.target.name);
      let tableLists = [...state.tableList];
      tableLists[id][e.target.name] = e.target.value;
      setState({ ...state, tableList: tableLists });
    } else if (["cType", "cLength", "cWidth", "cHeight", "cTonnage"].includes(e.target.name)) {
      console.log("error2>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", id, e.target.name);
      let containerTypes = [...state.containerType];
      containerTypes[id][e.target.name] = e.target.value;
      setState({ ...state, containerType: containerTypes });
    } else if (["vType", "vLength", "vWidth", "vHeight", "vTonnage"].includes(e.target.name)) {
      console.log("error3>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", id, e.target.name);
      let containerTypesVehicle = [...state.containerTypeVehicle];
      containerTypesVehicle[id][e.target.name] = e.target.value;
      setState({ ...state, containerTypeVehicle: containerTypesVehicle });
    } else {
      console.log("error4>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      const name = e.target.name;
      const value = e.target.value;
      setState({ ...state, [name]: value });
    }
  };

  const addRow = (e, optionsValue) => {
    console.log(optionsValue); // cargoOptions or containerOptionsValue

    if (["addCargo"].includes(e.target.name)) {
      console.log("GG1");

      setState((prevState) => ({
        ...prevState,
        tableList: [
          ...prevState.tableList,
          {
            index: Math.floor(Math.random() * 40) + 1,
            ttype: optionsValue,
            tname: "",
            tlength: "",
            twidth: "",
            theight: "",
            tweight: "",
            tqty: "",
            tcolor: "",
          },
        ],
      }));
    } else if (["addContainer"].includes(e.target.name)) {
      console.log("GG2");
      let defaultLength;
      let defaultWidth;
      let defaultHeight;
      let defaultTonnage;
      if (optionsValue === "20dv") {
        defaultLength = "5895";
        defaultWidth = "2350";
        defaultHeight = "2393";
        defaultTonnage = "28200";
      } else if (optionsValue === "40dv") {
        defaultLength = "12032";
        defaultWidth = "2350";
        defaultHeight = "2393";
        defaultTonnage = "28800";
      } else if (optionsValue === "40hq") {
        defaultLength = "12032";
        defaultWidth = "2350";
        defaultHeight = "2697";
        defaultTonnage = "28620";
      } else if (optionsValue === "45hq") {
        defaultLength = "13556";
        defaultWidth = "2353";
        defaultHeight = "2697";
        defaultTonnage = "27600";
      } else {
        console.log("error");
      }

      setState((prevState) => ({
        ...prevState,
        containerType: [
          ...prevState.containerType,
          {
            index: Math.floor(Math.random() * 40) + 1,
            cType: optionsValue,
            cLength: defaultLength,
            cWidth: defaultWidth,
            cHeight: defaultHeight,
            cTonnage: defaultTonnage,
          },
        ],
      }));
    } else if (["addVehicle"].includes(e.target.name)) {
      console.log("GG3");
      let defaultLength;
      let defaultWidth;
      let defaultHeight;
      let defaultTonnage;
      if (optionsValue === "Truck") {
        defaultLength = "5895";
        defaultWidth = "2350";
        defaultHeight = "2393";
        defaultTonnage = "28200";
      } else {
        console.log("GG");
      }

      setState((prevState) => ({
        ...prevState,
        containerTypeVehicle: [
          ...prevState.containerTypeVehicle,
          {
            index: Math.floor(Math.random() * 40) + 1,
            vType: optionsValue,
            vLength: defaultLength,
            vWidth: defaultWidth,
            vHeight: defaultHeight,
            vTonnage: defaultTonnage,
          },
        ],
      }));
    } else {
      console.log("GG");
    }
  };

  const deleteRow = (deleteValue, index) => {
    console.log(deleteValue, index);

    if (deleteValue === "cargo") {
      console.log(state.tableList);
      console.log("GG1");
      let newTableList = state.tableList;
      newTableList.splice(index, 1);
      console.log(newTableList);
      setState({ ...state, tableList: newTableList });
    } else if (deleteValue === "container") {
      console.log(state.containerType);
      console.log("GG2");
      let newContainerType = state.containerType;
      newContainerType.splice(index, 1);
      console.log(newContainerType);
      setState({ ...state, containerType: newContainerType });
    } else if (deleteValue === "truck") {
      console.log(state.containerTypeVehicle);
      console.log("GG3");
      let newContainerTypeVehicle = state.containerTypeVehicle;
      newContainerTypeVehicle.splice(index, 1);
      console.log(newContainerTypeVehicle);
      setState({ ...state, containerTypeVehicle: newContainerTypeVehicle });
    } else {
      console.log("GG");
    }

    // setState({
    //   taskList: state.taskList.filter((s, sindex) => index !== sindex),
    // });
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  //const { formId, formField } = form;
  //const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async () => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify("Saved Sucessfully"));

    //actions.setSubmitting(false);
    //actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLastStep) {
      submitForm();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={3} mb={20}>
        <Grid
          container
          display={"grid"}
          justifyItems={"center"}
          justifyContent="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={8} display="grid">
            <Stepper
              sx={{
                "& span.MuiStepConnector-line": {
                  // selectedTabColor
                  color: "#1e2762 !important",
                },

                "& svg.MuiSvgIcon-root": {
                  // selectedTabColor
                  background: "#FFFFFF !important",
                  fill: "#FFFFFF !important",
                  stroke: "#FFFFFF !important",
                  border: " 0.1875rem solid #FFFFFF !important",
                },

                "& svg.Mui-active": {
                  // selectedTabColor
                  background: "#1e2762 !important",
                  fill: "#1e2762 !important",
                  stroke: "#1e2762 !important",
                  border: " 0.1875rem solid #1e2762 !important",
                },

                "& svg.Mui-completed": {
                  // selectedTabColor
                  background: "#1e2762 !important",
                  fill: "#1e2762 !important",
                  stroke: "#1e2762 !important",
                  border: " 0.1875rem solid #1e2762 !important",
                },

                "& span.Mui-active": {
                  // selectedTabColor
                  color: "#181f4e !important",
                },

                "& span.Mui-completed": {
                  // selectedTabColor
                  color: "#181f4e !important",
                },
              }}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <form onSubmit={handleSubmit}>
              <Card>
                <VuiBox>
                  <VuiBox>
                    {getStepContent(activeStep, values, handleChange, addRow, deleteRow)}
                    <VuiBox mt={5} width="100%" display="flex" justifyContent="space-between">
                      {activeStep === 0 ? (
                        <VuiBox />
                      ) : (
                        <VuiButton
                          variant="gradient"
                          sx={{ minWidth: "100px" }}
                          color="light"
                          onClick={handleBack}
                        >
                          prev
                        </VuiButton>
                      )}
                      <VuiButton
                        variant="gradient"
                        type="submit"
                        sx={{ minWidth: "100px" }}
                        color="info"
                      >
                        {isLastStep ? "send" : "next"}
                      </VuiButton>
                    </VuiBox>
                  </VuiBox>
                </VuiBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LoadCalculator;
