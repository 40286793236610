// @mui material components
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { getRejectedLclContainerRequest } from "services/container";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

function RejectedLclRequest() {
  let history = useHistory();

  useEffect(async () => {
    await fetchRejectedLclDetails();
  }, []);

  const [availableShipData, setAvailableShipData] = useState("");

  const fetchRejectedLclDetails = () => {
    getRejectedLclContainerRequest().then((Response) => {
      console.log(Response);
      let newResponse = [];
      for (let item of Response) {
        newResponse.push({
          ...item,
          action: (
            <VuiButton variant="gradient" color="info" onClick={() => addShipDetail(item)}>
              BookShip
            </VuiButton>
          ),
        });
      }
      setAvailableShipData(newResponse);
    });
  };

  const addShipDetail = (data) => {
    console.log(data);
    history.push({
      pathname: "/Shipper/Book-Ship",
      details: data,
    });
  };

  const dataTableData = {
    columns: [
      { Header: "Shipper", accessor: "shipper" },
      { Header: "MmsiKey", accessor: "mmsiKey" },
      { Header: "InvoiceId", accessor: "invoiceId" },
      { Header: "ShipBookingStatus", accessor: "shipBookingStatus" },
    ],

    rows: availableShipData ? availableShipData : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              Rejected Ship Booking Request
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all rejected requests
            </VuiTypography>
          </VuiBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RejectedLclRequest;
