// @mui material components
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import VuiInput from "components/VuiInput";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import VuiButton from "components/VuiButton";
import Icon from "@mui/material/Icon";
import swal from "sweetalert";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";

import { getPorts } from "services/shipService";
import { searchShip, searchLcl, searchFcl, searchShipNew } from "services/logisticsService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineItem from "examples/Timeline/TimelineItem";
import { IoBoatOutline } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import ErrorImg from "./exclamation-mark.png";

import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import DataTable from "examples/Tables/DataTable";

import { useForm } from "react-hook-form";
import TabPanel from "./tab";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import LockIcon from "@mui/icons-material/Lock";
import ShipPic from "./favicon.png";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { getAddress, createShipment } from "services/parcelService";

import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { getAddressNew } from "services/parcelService";
import { getAddressDetails } from "services/parcelService";
import { validateAddress } from "services/parcelService";
import { createTransaction } from "services/parcelService";
import { postShipmentDetails } from "services/parcelService";
import { updateAddress } from "services/parcelService";
import { createBillingAddress } from "services/parcelService";
import { getShipmentDetails } from "services/parcelService";
import { initiateTransaction } from "services/parcelService";
import BitnauticLoader from "./Loader.gif";

function ParcelBillingDetails(props) {
  const buyData = props.location.data;
  const { id } = useParams();

  const { handleSubmit } = useForm();
  let history = useHistory();

  const [errorMsg, setErrorMsg] = useState({
    pickupCompany: { errorMsg: "" },
    pickupFullName: { errorMsg: "" },
    pickupEmail: { errorMsg: "" },
    pickupPhone: { errorMsg: "" },
    pickupStreetAddress: { errorMsg: "" },
    pickupCountry: { errorMsg: "" },
    pickupCity: { errorMsg: "" },
    pickupState: { errorMsg: "" },
    pickupZip: { errorMsg: "" },

    deliveryCompany: { errorMsg: "" },
    deliveryFullName: { errorMsg: "" },
    deliveryEmail: { errorMsg: "" },
    deliveryPhone: { errorMsg: "" },
    deliveryStreetAddress: { errorMsg: "" },
    deliveryCountry: { errorMsg: "" },
    deliveryCity: { errorMsg: "" },
    deliveryState: { errorMsg: "" },
    deliveryZip: { errorMsg: "" },

    paymentDetailsFullName: { errorMsg: "" },
    paymentDetailsCompany: { errorMsg: "" },
    paymentDetailsPhone: { errorMsg: "" },
    paymentDetailsEmail: { errorMsg: "" },
    paymentDetailsTax: { errorMsg: "" },
    paymentDetailsAddress: { errorMsg: "" },
    paymentDetailsCity: { errorMsg: "" },
    paymentDetailsState: { errorMsg: "" },
    paymentDetailsZip: { errorMsg: "" },
    paymentDetailsCountry: { errorMsg: "" },
    // fromZip: { errorMsg: "" },
    // toName: { errorMsg: "" },
    // toStreet1: { errorMsg: "" },
    // toCountry: { errorMsg: "" },
    // toCity: { errorMsg: "" },
    // toState: { errorMsg: "" },
    // toZip: { errorMsg: "" },
    // parcelLength: { errorMsg: "" },
    // parcelWidth: { errorMsg: "" },
    // parcelHeight: { errorMsg: "" },
    // parcelDistanceUnit: { errorMsg: "" },
    // parcelWeight: { errorMsg: "" },
    // parcelMassUnit: { errorMsg: "" },
  });

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [selectedMainTab, setSelectedMainTab] = useState(0);
  const [selectedFreightosTab, setSelectedFreightosTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const { values } = breakpoints;
  const [type, setType] = useState("");

  const [parcelRes, setParcelRes] = useState();
  const [parcelboolean, setParcelBoolean] = useState(false);

  const [loader, setLoader] = useState(false);

  const [pickupFullName, setPickupFullName] = useState("");
  const [pickupCompany, setPickupCompany] = useState("");
  const [pickupStreetAddress, setPickupStreetAddress] = useState("");
  const [pickupCity, setPickupCity] = useState("");
  const [pickupState, setPickupState] = useState("");
  const [pickupZip, setPickupZip] = useState("");
  const [pickupCountry, setPickupCountry] = useState("");
  const [pickupPhone, setPickupPhone] = useState("");
  const [pickupEmail, setPickupEmail] = useState("");
  const [pickupAddressId, setPickupAddressId] = useState("");
  const [pickupIconValue, setPickupIconValue] = useState(true);
  const [pickupFullData, setPickupFullData] = useState(false);

  const [deliveryFullName, setDeliveryFullName] = useState("");
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [deliveryStreetAddress, setDeliveryStreetAddress] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryState, setDeliveryState] = useState("");
  const [deliveryZip, setDeliveryZip] = useState("");
  const [deliveryCountry, setDeliveryCountry] = useState("");
  const [deliveryPhone, setDeliveryPhone] = useState("");
  const [deliveryEmail, setDeliveryEmail] = useState("");
  const [deliveryAddressId, setDeliveryAddressId] = useState("");
  const [deliveryIconValue, setDeliveryIconValue] = useState(true);
  const [deliveryFullData, setDeliveryFullData] = useState(false);

  const [paymentDetailsFullName, setPaymentDetailsFullName] = useState("");
  const [paymentDetailsCompany, setPaymentDetailsCompany] = useState("");
  const [paymentDetailsPhone, setPaymentDetailsPhone] = useState("");
  const [paymentDetailsEmail, setPaymentDetailsEmail] = useState("");
  const [paymentDetailsAddress, setPaymentDetailsAddress] = useState("");
  const [paymentDetailsTax, setPaymentDetailsTax] = useState("");
  const [paymentDetailsCity, setPaymentDetailsCity] = useState("");
  const [paymentDetailsState, setPaymentDetailsState] = useState("");
  const [paymentDetailsZip, setPaymentDetailsZip] = useState("");
  const [paymentDetailsCountry, setPaymentDetailsCountry] = useState("");
  const [paymentDetailsIconValue, setPaymentDetailsIconValue] = useState(true);
  const [paymentDetailsFullData, setPaymentDetailsFullData] = useState(false);

  const [orderId, setOrderId] = useState("");
  const [shipmentId, setShipmentId] = useState("");
  const [disabled, setDisabled] = useState(true);

  const [addressObj, setAddressObj] = useState("");
  const [addressObj2, setAddressObj2] = useState("");
  const [newAddOnly, setNewAddOnly] = useState("");
  const [geoAdd, setGeoAdd] = useState("");
  const [searchedAddress1, setSearchedAddress1] = useState([]);
  const [test, setTest] = useState("");

  const pickupIcon = pickupIconValue === true ? null : <img src={ErrorImg} width="15px" />;
  const deliveryIcon = deliveryIconValue === true ? null : <img src={ErrorImg} width="15px" />;
  const paymentDetailsIcon =
    paymentDetailsIconValue === true ? null : <img src={ErrorImg} width="15px" />;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetMainTabValue = (event, newValue) => setSelectedMainTab(newValue);

  const handleSetFreightosTabValue = (event, newValue) => setSelectedFreightosTab(newValue);

  const handleSetTabValue = (event, newValue) => setSelectedTab(newValue);

  useEffect(() => {
    fetchShipmentDetails();
  }, []);

  const fetchShipmentDetails = async () => {
    console.log("buyData", buyData);

    if (buyData !== undefined) {
      let resp = await postShipmentDetails(buyData);
      console.log("resp", resp);
    }

    let response = await getShipmentDetails(id);
    console.log(response);

    response.addressFromObj.name === "null"
      ? setPickupFullName("")
      : setPickupFullName(response.addressFromObj.name);

    response.addressFromObj.state === "null"
      ? setPickupState("")
      : setPickupState(response.addressFromObj.state);

    setPickupStreetAddress(response.addressFromObj.street1);
    setPickupCity(response.addressFromObj.city);
    setPickupCountry(response.addressFromObj.country);
    setPickupZip(response.addressFromObj.zip);
    setPickupAddressId(response.addressFromObj.id);

    response.addressToObj.name === "null"
      ? setDeliveryFullName("")
      : setDeliveryFullName(response.addressToObj.name);

    response.addressToObj.state === "null"
      ? setDeliveryState("")
      : setDeliveryState(response.addressToObj.state);

    setDeliveryStreetAddress(response.addressToObj.street1);
    setDeliveryCity(response.addressToObj.city);

    setDeliveryCountry(response.addressToObj.country);
    setDeliveryZip(response.addressToObj.zip);
    setDeliveryAddressId(response.addressToObj.id);

    setOrderId(response.orderID);
    setShipmentId(response.id);
  };

  const handlePickupSave = () => {
    // let errorLengthPickupCompany = customValidations("pickupCompany", pickupCompany);
    let errorLengthPickupEmail = customValidations("pickupEmail", pickupEmail);
    let errorLengthPickupPhone = customValidations("pickupPhone", pickupPhone);
    let errorLengthPickupFullName = customValidations("pickupFullName", pickupFullName);
    let errorLengthPickupStreetAddress = customValidations(
      "pickupStreetAddress",
      pickupStreetAddress
    );
    let errorLengthPickupCity = customValidations("pickupCity", pickupCity);
    // let errorLengthPickupState = customValidations("pickupState", pickupState);
    let errorLengthPickupZip = customValidations("pickupZip", pickupZip);
    let errorLengthPickupCountry = customValidations("pickupCountry", pickupCountry);

    if (
      // errorLengthPickupCompany !== 1 &&
      errorLengthPickupFullName !== 1 &&
      errorLengthPickupEmail !== 1 &&
      errorLengthPickupPhone !== 1 &&
      errorLengthPickupStreetAddress !== 1 &&
      errorLengthPickupCity !== 1 &&
      // errorLengthPickupState !== 1 &&
      errorLengthPickupZip !== 1 &&
      errorLengthPickupCountry !== 1
    ) {
      console.log("all ok");
    } else {
      console.log("not ok", errorLengthPickupEmail);
      setPickupIconValue(false);
      return;
    }

    setLoader(true);

    let validPickupAddress = {
      address: {
        street1: pickupStreetAddress,
        city: pickupCity,
        zip: pickupZip,
        country: pickupCountry,
      },
    };

    validateAddress(validPickupAddress).then((res) => {
      console.log("validatedResponse", res);
      console.log("boolean", res.is_valid);
      console.log("this", res.messages);

      if (res.is_valid === true) {
        console.log("true");

        const getUpdateAddressResp = async () => {
          let finalAddressToUpdate = {
            addressID: pickupAddressId,
            address: {
              companyName: pickupCompany,
              name: pickupFullName,
              email: pickupEmail,
              phoneNumber: pickupPhone,
              street1: pickupStreetAddress,
              city: pickupCity,
              state: pickupState,
              zip: pickupZip,
              country: pickupCountry,
            },
          };

          let updateAddResp = await updateAddress(finalAddressToUpdate);

          console.log("updated", updateAddResp);
          setPickupIconValue(true);
          // setPickupFullData(true);

          setLoader(false);

          Swal.fire("", `Verified`, "success").then(() => {
            setSelectedFreightosTab((prevState) => prevState + 1);
          });
        };
        getUpdateAddressResp();
      } else {
        setLoader(false);
        Swal.fire("", `${res.messages[0].text}`, "warning");
      }
    });
  };

  const handleDeliverySave = () => {
    // let errorLengthDeliveryCompany = customValidations("deliveryCompany", deliveryCompany);
    let errorLengthDeliveryFullName = customValidations("deliveryFullName", deliveryFullName);
    let errorLengthDeliveryEmail = customValidations("deliveryEmail", deliveryEmail);
    let errorLengthDeliveryPhone = customValidations("deliveryPhone", deliveryPhone);
    let errorLengthDeliveryStreetAddress = customValidations(
      "deliveryStreetAddress",
      deliveryStreetAddress
    );
    let errorLengthDeliveryCity = customValidations("deliveryCity", deliveryCity);
    // let errorLengthDeliveryState = customValidations("deliveryState", deliveryState);
    let errorLengthDeliveryZip = customValidations("deliveryZip", deliveryZip);
    let errorLengthDeliveryCountry = customValidations("deliveryCountry", deliveryCountry);

    if (
      // errorLengthDeliveryCompany !== 1 &&
      errorLengthDeliveryFullName !== 1 &&
      errorLengthDeliveryEmail !== 1 &&
      errorLengthDeliveryPhone !== 1 &&
      errorLengthDeliveryStreetAddress !== 1 &&
      errorLengthDeliveryCity !== 1 &&
      // errorLengthDeliveryState !== 1 &&
      errorLengthDeliveryZip !== 1 &&
      errorLengthDeliveryCountry !== 1
    ) {
      console.log("all ok");
    } else {
      console.log("not ok", errorLengthDeliveryEmail);
      setDeliveryIconValue(false);
      return;
    }

    setLoader(true);

    let validDeliveryAddress = {
      address: {
        street1: deliveryStreetAddress,
        city: deliveryCity,
        zip: deliveryZip,
        country: deliveryCountry,
      },
    };

    validateAddress(validDeliveryAddress).then((res) => {
      console.log("validatedResponse", res);
      console.log("boolean", res.is_valid);
      console.log("this", res.messages);

      if (res.is_valid === true) {
        console.log("true");

        const getUpdateAddressResp = async () => {
          let finalAddressToUpdate = {
            addressID: deliveryAddressId,
            address: {
              companyName: deliveryCompany,
              name: deliveryFullName,
              email: deliveryEmail,
              phoneNumber: deliveryPhone,
              street1: deliveryStreetAddress,
              city: deliveryCity,
              state: deliveryState,
              zip: deliveryZip,
              country: deliveryCountry,
            },
          };

          let updateAddResp = await updateAddress(finalAddressToUpdate);

          console.log("updated", updateAddResp);
          setDeliveryIconValue(true);
          // setDeliveryFullData(true);
          setLoader(false);
          Swal.fire("", `Verified`, "success").then(() => {
            setSelectedFreightosTab((prevState) => prevState + 1);
          });
        };
        getUpdateAddressResp();
      } else {
        setLoader(false);
        Swal.fire("", `${res.messages[0].text}`, "warning");
      }
    });
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.parcelPickupValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    console.log(value, "address");

    if (name === "pickupCompany") {
      setPickupCompany(value);
    } else if (name === "pickupFullName") {
      setPickupFullName(value);
    } else if (name === "pickupEmail") {
      setPickupEmail(value);
    } else if (name === "pickupPhone") {
      setPickupPhone(value);
    } else if (name === "pickupStreetAddress") {
      setPickupStreetAddress(value);
    } else if (name === "pickupState") {
      setPickupState(value);
    } else if (name === "deliveryCompany") {
      setDeliveryCompany(value);
    } else if (name === "deliveryFullName") {
      setDeliveryFullName(value);
    } else if (name === "deliveryEmail") {
      setDeliveryEmail(value);
    } else if (name === "deliveryPhone") {
      setDeliveryPhone(value);
    } else if (name === "deliveryStreetAddress") {
      setDeliveryStreetAddress(value);
    } else if (name === "deliveryState") {
      setDeliveryState(value);
    } else if (name === "paymentDetailsFullName") {
      setPaymentDetailsFullName(value);
    } else if (name === "paymentDetailsCompany") {
      setPaymentDetailsCompany(value);
    } else if (name === "paymentDetailsPhone") {
      setPaymentDetailsPhone(value);
    } else if (name === "paymentDetailsEmail") {
      setPaymentDetailsEmail(value);
    } else if (name === "paymentDetailsAddress") {
      setPaymentDetailsAddress(value);
    } else if (name === "paymentDetailsTax") {
      setPaymentDetailsTax(value);
    } else if (name === "paymentDetailsCity") {
      setPaymentDetailsCity(value);
    } else if (name === "paymentDetailsState") {
      setPaymentDetailsState(value);
    } else if (name === "paymentDetailsZip") {
      setPaymentDetailsZip(value);
    } else if (name === "paymentDetailsCountry") {
      setPaymentDetailsCountry(value);
    }
  };

  const handlePaymentDetailsSave = () => {
    // let errorLengthPaymentDetailsCompany = customValidations(
    //   "paymentDetailsCompany",
    //   paymentDetailsCompany
    // );
    let errorLengthPaymentDetailsFullName = customValidations(
      "paymentDetailsFullName",
      paymentDetailsFullName
    );

    let errorLengthPaymentDetailsPhone = customValidations(
      "paymentDetailsPhone",
      paymentDetailsPhone
    );
    // let errorLengthPaymentDetailsTax = customValidations("paymentDetailsTax", paymentDetailsTax);
    let errorLengthPaymentDetailsEmail = customValidations(
      "paymentDetailsEmail",
      paymentDetailsEmail
    );
    let errorLengthPaymentDetailsAddress = customValidations(
      "paymentDetailsAddress",
      paymentDetailsAddress
    );
    let errorLengthPaymentDetailsCity = customValidations("paymentDetailsCity", paymentDetailsCity);
    // let errorLengthPaymentDetailsState = customValidations(
    //   "paymentDetailsState",
    //   paymentDetailsState
    // );
    let errorLengthPaymentDetailsZip = customValidations("paymentDetailsZip", paymentDetailsZip);

    let errorLengthPaymentDetailsCountry = customValidations(
      "paymentDetailsCountry",
      paymentDetailsCountry
    );

    if (
      // errorLengthPaymentDetailsCompany !== 1 &&
      errorLengthPaymentDetailsFullName !== 1 &&
      errorLengthPaymentDetailsEmail !== 1 &&
      // errorLengthPaymentDetailsTax !== 1 &&
      errorLengthPaymentDetailsPhone !== 1 &&
      errorLengthPaymentDetailsAddress !== 1 &&
      errorLengthPaymentDetailsCity !== 1 &&
      // errorLengthPaymentDetailsState !== 1 &&
      errorLengthPaymentDetailsZip !== 1 &&
      errorLengthPaymentDetailsCountry !== 1
    ) {
      console.log("all ok");
    } else {
      console.log("not ok");
      setPaymentDetailsIconValue(false);
      return;
    }

    setLoader(true);

    let validatePaymentDetails = {
      address: {
        street1: paymentDetailsAddress,
        city: paymentDetailsCity,
        state: paymentDetailsState,
        zip: paymentDetailsZip,
        country: paymentDetailsCountry,
      },
    };

    let validatedPaymentDetails = {
      orderID: orderId,
      address: {
        name: paymentDetailsFullName,
        phoneNumber: paymentDetailsPhone,
        email: paymentDetailsEmail,
        companyName: paymentDetailsCompany,
        taxID: paymentDetailsTax,
        street1: paymentDetailsAddress,
        city: paymentDetailsCity,
        state: paymentDetailsState,
        zip: paymentDetailsZip,
        country: paymentDetailsCountry,
      },
    };

    validateAddress(validatePaymentDetails).then((res) => {
      console.log("validatedResponse", res);
      console.log("boolean", res.is_valid);
      console.log("this", res.messages);

      if (res.is_valid === true) {
        console.log("true");
        setPaymentDetailsIconValue(true);
        setLoader(false);
        setDisabled(false);

        Swal.fire("", `Details Verified`, "success");
        createBillingAddress(validatedPaymentDetails).then((response) => {
          console.log("createPaymentDetailsCall", response);
        });
      } else {
        setLoader(false);
        Swal.fire("", `${res.messages[0].text}`, "warning");
      }
    });
  };

  const handleAutoAddress = (name) => {
    if (name === "sender") {
      setPaymentDetailsFullName(pickupFullName);
      setPaymentDetailsCompany(pickupCompany);
      setPaymentDetailsPhone(pickupPhone);
      setPaymentDetailsEmail(pickupEmail);
      setPaymentDetailsAddress(pickupStreetAddress);
      setPaymentDetailsCity(pickupCity);
      setPaymentDetailsState(pickupState);
      setPaymentDetailsZip(pickupZip);
      setPaymentDetailsCountry(pickupZip);
      setPaymentDetailsCountry(pickupCountry);
    } else {
      setPaymentDetailsFullName(deliveryFullName);
      setPaymentDetailsCompany(deliveryCompany);
      setPaymentDetailsPhone(deliveryPhone);
      setPaymentDetailsEmail(deliveryEmail);
      setPaymentDetailsAddress(deliveryStreetAddress);
      setPaymentDetailsCity(deliveryCity);
      setPaymentDetailsState(deliveryState);
      setPaymentDetailsZip(deliveryZip);
      setPaymentDetailsCountry(deliveryZip);
      setPaymentDetailsCountry(deliveryCountry);
    }
  };

  const handlePaymentDetailsProceed = () => {
    // initiateTransaction(orderId).then((res) => {
    //   console.log("initiateTimerCall", res);
    // });

    history.push({
      pathname: `/shipper/verify-parcel-details/${shipmentId}`,
    });
  };

  // useEffect(() => {
  //   disabledValue();
  // }, [disabled]);

  // const disabledValue = () => {
  //   if (pickupFullData === true && deliveryFullData === true && paymentDetailsFullData === true) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        {/* <Grid container>
          <Grid item xs={12}>
            <Globe
              display={{ xs: "none", md: "block" }}
              position="absolute"
              top="10%"
              right={0}
              mt={{ xs: -12, lg: 1 }}
              mr={{ xs: 0, md: -10, lg: 10 }}
              canvasStyle={{ marginTop: "3rem" }}
            />
          </Grid>
        </Grid> */}

        <Card>
          <VuiBox p={3} pl={0} lineHeight={1} sx={{ textAlign: "center" }}>
            <VuiTypography variant="h5" fontWeight="medium" color="text">
              Your shipment is almost ready to go!
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              Fill in the required information below so the logistics provider can process your
              shipment.
            </VuiTypography>
          </VuiBox>
        </Card>

        <AppBar position="static" sx={{ background: "#d8dcf3", borderRadius: "1.25rem" }}>
          <Tabs
            variant="fullWidth"
            centered={true}
            orientation={tabsOrientation}
            value={selectedMainTab}
            onChange={handleSetMainTabValue}
            sx={{
              // "& button": { backgroundColor: "#F2F0FE" },
              // "& button.Mui-selected": { color: "#F2F0FE" },
              // "& button": {
              //   // tabTexTColor
              //   color: "#110F26 !important",
              // },

              "& button.Mui-selected": {
                // tabTexTColor
                color: "#F2F0FE !important",
              },

              "& span.MuiTabs-indicator": {
                // tabIndicatedColor
                backgroundColor: "#2D3B92",
                // color: "#110F26 !important",
              },
            }}
          >
            <Tab
              label="Contact Address"
              sx={{ color: "#110F26 !important" }}

              // icon={<IoMdCube size="16px" color="white" fontWeight="bold" />}
            />
            <Tab
              label="Billing Address"
              iconPosition="end"
              icon={paymentDetailsIcon}
              sx={{ color: "#110F26 !important" }}
            />
          </Tabs>
        </AppBar>

        <Card sx={{ overflow: "visible", marginTop: "1rem" }}>
          <TabPanel value={selectedMainTab} index={0}>
            <AppBar position="static" sx={{ background: "#d8dcf3", borderRadius: "1.25rem" }}>
              <Tabs
                variant="fullWidth"
                centered={true}
                orientation={tabsOrientation}
                value={selectedFreightosTab}
                onChange={handleSetFreightosTabValue}
                sx={{
                  // "& button": { backgroundColor: "#F2F0FE" },
                  // "& button.Mui-selected": { color: "#F2F0FE" },
                  // "& button": {
                  //   // tabTexTColor
                  //   color: "#110F26 !important",
                  // },

                  "& button.Mui-selected": {
                    // tabTexTColor
                    color: "#F2F0FE !important",
                  },

                  "& span.MuiTabs-indicator": {
                    // tabIndicatedColor
                    backgroundColor: "#2D3B92",
                    // color: "#110F26 !important",
                  },
                }}
              >
                <Tab
                  label="Pickup"
                  iconPosition="end"
                  icon={pickupIcon}
                  sx={{ color: "#110F26 !important" }}
                />
                <Tab
                  label="Delivery"
                  iconPosition="end"
                  icon={deliveryIcon}
                  sx={{ color: "#110F26 !important" }}
                />
                {/* <Tab label="Load" iconPosition="end" icon={loadIcon} /> */}
                {/* <Tab
                  label="Goods"
                  // icon={<IoDocument size="16px" color="white" fontWeight="bold" />}
                /> */}
              </Tabs>
            </AppBar>
            <TabPanel value={selectedFreightosTab} index={0}>
              <Card sx={{ overflow: "visible" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Company Name
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={pickupCompany}
                      name="pickupCompany"
                      error={errorMsg.pickupCompany.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupCompany.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Name*
                    </VuiTypography>

                    <VuiInput
                      placeholder=""
                      value={pickupFullName}
                      name="pickupFullName"
                      error={errorMsg.pickupFullName.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />

                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupFullName.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Email*
                    </VuiTypography>
                    <VuiInput
                      type="email"
                      placeholder=""
                      value={pickupEmail}
                      name="pickupEmail"
                      error={errorMsg.pickupEmail.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupEmail.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Phone*
                    </VuiTypography>
                    <VuiInput
                      type="number"
                      placeholder=""
                      value={pickupPhone}
                      name="pickupPhone"
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.name,
                          (e.target.value = e.target.value.slice(0, 15))
                        )
                      }
                      error={errorMsg.pickupPhone.errorMsg.length > 0}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupPhone.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Street Address
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={pickupStreetAddress}
                      name="pickupStreetAddress"
                      error={errorMsg.pickupStreetAddress.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupStreetAddress.errorMsg}
                    </VuiTypography>

                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          // checked={newMessage}
                          // onChange={(e) => handleChange("newMessage", e.target.checked)}
                          name="newMessage"
                        />
                      }
                      label={
                        <VuiTypography fontWeight="light" fontSize="0.8rem">
                          Same as
                        </VuiTypography>
                      }
                    /> */}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      City
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={pickupCity}
                      name="pickupCity"
                      error={errorMsg.pickupCity.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupCity.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      State
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={pickupState}
                      name="pickupState"
                      error={errorMsg.pickupState.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupState.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Country/Region
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={pickupCountry}
                      name="pickupCountry"
                      error={errorMsg.pickupCountry.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupCountry.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Zip code
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={pickupZip}
                      name="pickupZip"
                      error={errorMsg.pickupZip.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.pickupZip.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} mt={"1.5rem"}>
                    <VuiBox sx={{ textAlign: "center" }}>
                      {loader ? (
                        <VuiBox>
                          <img src={BitnauticLoader} width={"40"} alt="Loading" />
                        </VuiBox>
                      ) : (
                        <VuiButton
                          variant="gradient"
                          color="info"
                          size="large"
                          onClick={() => handlePickupSave()}
                        >
                          Save
                        </VuiButton>
                      )}
                    </VuiBox>
                  </Grid>
                </Grid>
              </Card>
            </TabPanel>
            <TabPanel value={selectedFreightosTab} index={1}>
              <Card sx={{ overflow: "visible" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Company Name
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryCompany}
                      name="deliveryCompany"
                      error={errorMsg.deliveryCompany.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryCompany.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Name*
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryFullName}
                      name="deliveryFullName"
                      error={errorMsg.deliveryFullName.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />

                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryFullName.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Email*
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryEmail}
                      name="deliveryEmail"
                      error={errorMsg.deliveryEmail.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryEmail.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Phone*
                    </VuiTypography>
                    <VuiInput
                      type="number"
                      placeholder=""
                      value={deliveryPhone}
                      name="deliveryPhone"
                      error={errorMsg.deliveryPhone.errorMsg.length > 0}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.name,
                          (e.target.value = e.target.value.slice(0, 15))
                        )
                      }
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryPhone.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Street Address
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryStreetAddress}
                      name="deliveryStreetAddress"
                      error={errorMsg.deliveryStreetAddress.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryStreetAddress.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      City
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryCity}
                      name="deliveryCity"
                      error={errorMsg.deliveryCity.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryCity.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      State
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryState}
                      name="deliveryState"
                      error={errorMsg.deliveryState.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryState.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Country/Region
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryCountry}
                      name="deliveryCountry"
                      error={errorMsg.deliveryCountry.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryCountry.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Zip code
                    </VuiTypography>
                    <VuiInput
                      placeholder=""
                      value={deliveryZip}
                      name="deliveryZip"
                      error={errorMsg.deliveryZip.errorMsg.length > 0}
                      onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.deliveryZip.errorMsg}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={12} mt={"1.5rem"}>
                    <VuiBox sx={{ textAlign: "center" }}>
                      {loader ? (
                        <VuiBox>
                          <img src={BitnauticLoader} width={"40"} alt="Loading" />
                        </VuiBox>
                      ) : (
                        <VuiButton
                          variant="gradient"
                          color="info"
                          size="large"
                          onClick={() => handleDeliverySave()}
                        >
                          Save
                        </VuiButton>
                      )}
                    </VuiBox>
                  </Grid>
                </Grid>
              </Card>
            </TabPanel>
          </TabPanel>

          <TabPanel value={selectedMainTab} index={1}>
            <Card sx={{ overflow: "visible" }}>
              <VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                <VuiButton
                  name="sender"
                  color="info"
                  variant="text"
                  onClick={(e) => handleAutoAddress(e.target.name)}
                >
                  Same as sender address
                </VuiButton>
                <VuiButton
                  name="reciever"
                  color="info"
                  variant="text"
                  onClick={(e) => handleAutoAddress(e.target.name)}
                >
                  Same as reciever address
                </VuiButton>
              </VuiBox>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Name*
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsFullName}
                    name="paymentDetailsFullName"
                    error={errorMsg.paymentDetailsFullName.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsFullName.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Company Name
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsCompany}
                    name="paymentDetailsCompany"
                    error={errorMsg.paymentDetailsCompany.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />

                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsCompany.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Phone*
                  </VuiTypography>
                  <VuiInput
                    type="number"
                    placeholder=""
                    value={paymentDetailsPhone}
                    name="paymentDetailsPhone"
                    error={errorMsg.paymentDetailsPhone.errorMsg.length > 0}
                    onChange={(e) =>
                      handleChangeInput(
                        e.target.name,
                        (e.target.value = e.target.value.slice(0, 15))
                      )
                    }
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsPhone.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Email*
                  </VuiTypography>

                  <VuiInput
                    placeholder=""
                    value={paymentDetailsEmail}
                    name="paymentDetailsEmail"
                    error={errorMsg.paymentDetailsEmail.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsEmail.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Vat/Tax ID
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsTax}
                    name="paymentDetailsTax"
                    error={errorMsg.paymentDetailsTax.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsTax.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Company Billing Address*
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsAddress}
                    name="paymentDetailsAddress"
                    error={errorMsg.paymentDetailsAddress.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsAddress.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    City*
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsCity}
                    name="paymentDetailsCity"
                    error={errorMsg.paymentDetailsCity.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsCity.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    State/Region
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsState}
                    name="paymentDetailsState"
                    error={errorMsg.paymentDetailsState.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsState.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Zip code
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsZip}
                    name="paymentDetailsZip"
                    error={errorMsg.paymentDetailsZip.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsZip.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Country
                  </VuiTypography>
                  <VuiInput
                    placeholder=""
                    value={paymentDetailsCountry}
                    name="paymentDetailsCountry"
                    error={errorMsg.paymentDetailsCountry.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.paymentDetailsCountry.errorMsg}
                  </VuiTypography>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  mt={"1.5rem"}
                  direction="row"
                  justifyContent="space-between"
                >
                  <VuiBox>
                    <VuiButton
                      variant="gradient"
                      color="info"
                      onClick={() => handlePaymentDetailsSave()}
                    >
                      Save
                    </VuiButton>
                  </VuiBox>

                  <VuiBox>
                    {loader ? <img src={BitnauticLoader} width={"40"} alt="Loading" /> : null}
                  </VuiBox>

                  <VuiBox>
                    <VuiButton
                      variant="gradient"
                      color="info"
                      disabled={disabled}
                      onClick={() => handlePaymentDetailsProceed()}
                    >
                      Confirm Details
                    </VuiButton>
                  </VuiBox>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
        </Card>
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ParcelBillingDetails;
