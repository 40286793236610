const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "../helpers/baseUrl";

export async function searchShip(data) {
  const response = await axios.post(`${baseUrl}/shipper/ship/searchShip`, data);
  console.log("response 1 >>>>>>>>>>>>>>>>>", response);
  if (response) {
    console.log("response 2 >>>>>>>>>>>>>>>>>", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function searchFcl(data) {
  const response = await axios.post(`${baseUrl}/shipper/container/searchfcl`, data);
  console.log("response 1 >>>>>>>>>>>>>>>>>", response);
  if (response) {
    console.log("response 2 >>>>>>>>>>>>>>>>>", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function searchLcl(data) {
  const response = await axios.post(`${baseUrl}/shipper/container/searchlcl`, data);
  console.log("response 1 >>>>>>>>>>>>>>>>>", response);
  if (response) {
    console.log("response 2 >>>>>>>>>>>>>>>>>", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function searchShipNew(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  console.log("delayeeeeeeed");
  try {
    const response = await axios.post(`${baseUrl}/shipper/ship/searchThirdPartiesShips`, data);
    console.log("response 1 >>>>>>>>>>>>>>>>>", response);
    return response.data;
  } catch (err) {
    console.log(err);
    let newResponse = [];
    return newResponse;
  }
  // const response = await axios.post(`${baseUrl}/shipper/ship/searchThirdPartiesShips`, data);
  // console.log("response 1 >>>>>>>>>>>>>>>>>", response);
  // if (response) {
  //   console.log("response 2 >>>>>>>>>>>>>>>>>", response);
  //   return response.data;
  // } else {
  //   swal("", response.message, "error");
  // }
}
