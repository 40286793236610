import React from "react";
import { useState, useEffect } from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import container from "./container.png";
import truck from "./truck.png";
import boxes from "./boxes.png";
import bigBags from "./bigBags.png";
import sacks from "./sacks.png";
import barrel from "./barrel.png";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import FormGroup from "@mui/material/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import VuiSelect from "components/VuiSelect";

function SelectContainer(props) {
  const { values, handleChange, addRow, deleteRow } = props;
  const { containerType, containerTypeVehicle } = values;
  const [containerValue, setContainerValue] = useState();
  const [containerBox, setContainerBox] = useState(false);
  const [equipImgSrc, setEquipImgSrc] = useState("");

  console.log(containerType);

  const typeHandler = (e) => {
    const value = e.target.value;
    console.log(value);
    if (value === "Manual") {
      setContainerBox(true);
    } else {
      setContainerBox(false);
    }
  };

  const equipImgHandler = () => {
    if (values.equipment === "Container") {
      setEquipImgSrc(container);
    }
    if (values.equipment === "Truck") {
      setEquipImgSrc(truck);
    }
  };

  useEffect(() => {
    equipImgHandler();
  }, []);

  return (
    <VuiBox>
      <VuiBox textAlign="center">
        <VuiTypography color="text" fontWeight="light" fontSize="1.3rem">
          Container list
        </VuiTypography>
      </VuiBox>
      <Grid container marginTop={"3rem"} textAlign="center">
        <Grid container item xs={12} marginBottom="3rem">
          <Grid item xs={7}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              20'dv, 40'dv and 40'hq are used as container selection is made automatically.
              Container unit number calculation by container types is made automatically.
            </VuiTypography>
            <input
              style={{ height: "15px", width: "15px" }}
              name="containerSelection"
              type="radio"
              value="Auto"
              onChange={handleChange}
              checked={values.containerSelection === "Auto"}
              onClick={(e) => typeHandler(e)}
            ></input>
          </Grid>
          <Grid item xs={5}>
            <img src={equipImgSrc} alt="GG" width={"50%"} />
          </Grid>
        </Grid>

        <Grid container item xs={12} marginBottom="2rem">
          <Grid item xs={7}>
            <VuiTypography color="text" fontWeight="light" fontSize="1rem">
              Check this mode to enter container quantity and select container type manually from
              offered.
            </VuiTypography>
            <input
              style={{ height: "15px", width: "15px" }}
              name="containerSelection"
              type="radio"
              value="Manual"
              onChange={handleChange}
              checked={values.containerSelection === "Manual"}
              onClick={(e) => typeHandler(e)}
            ></input>
          </Grid>
          <Grid item xs={5}>
            <img src={equipImgSrc} alt="GG" width={"50%"} />
          </Grid>
        </Grid>

        {containerBox && values.equipment === "Container" ? (
          <VuiBox>
            <Grid container item xs={12} marginBottom="2rem">
              <Grid item xs={12} marginBottom="2rem">
                <VuiTypography color="text" fontWeight="light" fontSize="1.1rem">
                  Select container types and press "Add container" to make a list for stuffing.
                </VuiTypography>
              </Grid>
              <Grid item xs={12} marginBottom="3rem">
                <VuiTypography color="text" fontWeight="light" fontSize="1.1rem">
                  The total number of containers can not be more than 20 units.
                </VuiTypography>
              </Grid>
              <Grid container item display={"grid"} justifyContent={"center"}>
                <Grid item xs={12} display={"grid"} gridTemplateColumns={"150px 150px 150px"}>
                  <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                    Container Type
                  </VuiTypography>
                  <VuiSelect
                    color="info"
                    options={[
                      { value: "20dv", label: "20dv" },
                      { value: "40dv", label: "40dv" },
                      { value: "40hq", label: "40hq" },
                      { value: "45hq", label: "45hq" },
                    ]}
                    onChange={(e) => setContainerValue(e.value)}
                  />
                  <VuiButton
                    name="addContainer"
                    variant="outlined"
                    color="info"
                    onClick={(e) => addRow(e, containerValue)}
                  >
                    Add container
                  </VuiButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent={"center"}
              textAlign={"center"}
              marginBottom="1rem"
            >
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Container Type
                </VuiTypography>
                {/*   <VuiInput name="name" defaultValue={values.name} onChange={handleChange} /> */}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Length
                </VuiTypography>
                {/*   <VuiInput name="length" defaultValue={values.length} onChange={handleChange} />*/}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Width
                </VuiTypography>
                {/*   <VuiInput name="width" defaultValue={values.width} onChange={handleChange} />*/}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Height
                </VuiTypography>
                {/*     <VuiInput name="height" defaultValue={values.height} onChange={handleChange} />*/}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Tonnage
                </VuiTypography>
                {/*    <VuiInput name="weight" defaultValue={values.weight} onChange={handleChange} />*/}
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent={"center"} textAlign={"center"}>
              {containerType.map((val, idx) => {
                let type = `name-${idx}`;
                let length = `length-${idx}`;
                let width = `width-${idx}`;
                let height = `height-${idx}`;
                let tonnage = `tonnage-${idx}`;
                console.log(val.cType);
                let conType = val.cType;

                return (
                  <Grid container key={val.index} marginBottom="0.3rem">
                    <Grid item xs={1}>
                      {idx === 0 ? (
                        <VuiBox></VuiBox>
                      ) : (
                        <VuiBox color="white" fontWeight="light">
                          <FontAwesomeIcon
                            icon={faXmark}
                            cursor="pointer"
                            onClick={() => deleteRow("container", idx)}
                          />
                        </VuiBox>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <VuiInput
                        readOnly
                        name="cType"
                        value={conType}
                        id={type}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <VuiInput
                        readOnly
                        name="cLength"
                        defaultValue={val.cLength}
                        id={length}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <VuiInput
                        readOnly
                        defaultValue={val.cWidth}
                        name="cWidth"
                        data-id={idx}
                        id={width}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <VuiInput
                        readOnly
                        defaultValue={val.cHeight}
                        name="cHeight"
                        data-id={idx}
                        id={height}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <VuiInput
                        type="number"
                        name="cTonnage"
                        defaultValue={val.cTonnage}
                        data-id={idx}
                        id={tonnage}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </VuiBox>
        ) : containerBox && values.equipment === "Truck" ? (
          // <VuiBox></VuiBox>
          <VuiBox>
            <Grid container item xs={12}>
              <Grid item xs={12} marginBottom="20px">
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  Press "Add Transport" to add new vehicle.
                </VuiTypography>
              </Grid>
              <Grid item xs={12} marginBottom="20px">
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  The total number of containers can not be more than 20 units.
                </VuiTypography>
              </Grid>
              <Grid container item display={"grid"} justifyContent={"center"}>
                <Grid item xs={12}>
                  {/* <VuiTypography variant="h6" color="white" fontWeight="light">
                    Container Type
                  </VuiTypography>
                  <VuiSelect
                    color="info"
                    options={[
                      { value: "20dv", label: "20dv" },
                      { value: "40dv", label: "40dv" },
                      { value: "40hq", label: "40hq" },
                      { value: "45hq", label: "45hq" },
                    ]}
                    onChange={(e) => setContainerValue(e.value)}
                  /> */}
                  <VuiButton
                    name="addVehicle"
                    variant="gradient"
                    color="info"
                    onClick={(e) => addRow(e, values.equipment)}
                  >
                    Add Transport
                  </VuiButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent={"center"}
              textAlign={"center"}
              marginTop={"20px"}
            >
              <Grid item xs={1}>
                {/* <img src={boxes} alt="GG" width={38} height={35} /> */}
                {/* <VuiInput /> */}
              </Grid>
              <Grid item xs={3}>
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  Container Type
                </VuiTypography>
                {/*   <VuiInput name="name" defaultValue={values.name} onChange={handleChange} /> */}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  Length
                </VuiTypography>
                {/*   <VuiInput name="length" defaultValue={values.length} onChange={handleChange} />*/}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  Width
                </VuiTypography>
                {/*   <VuiInput name="width" defaultValue={values.width} onChange={handleChange} />*/}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  Height
                </VuiTypography>
                {/*     <VuiInput name="height" defaultValue={values.height} onChange={handleChange} />*/}
              </Grid>
              <Grid item xs={2}>
                <VuiTypography variant="h6" color="text" fontWeight="light">
                  Tonnage
                </VuiTypography>
                {/*    <VuiInput name="weight" defaultValue={values.weight} onChange={handleChange} />*/}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent={"center"}
              textAlign={"center"}
              marginTop={"20px"}
            >
              {containerTypeVehicle.map((val, idx) => {
                let type = `name-${idx}`;
                let length = `length-${idx}`;
                let width = `width-${idx}`;
                let height = `height-${idx}`;
                let tonnage = `tonnage-${idx}`;
                console.log(val.vType);
                let conType = val.vType;

                return (
                  <Grid container key={val.index} marginBottom="5px">
                    <Grid item xs={1}>
                      {idx === 0 ? (
                        <VuiBox></VuiBox>
                      ) : (
                        <VuiBox color="white" fontWeight="light">
                          <FontAwesomeIcon
                            icon={faXmark}
                            cursor="pointer"
                            onClick={() => deleteRow("truck", idx)}
                          />
                        </VuiBox>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <VuiInput
                        name="vType"
                        value={conType}
                        id={type}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <VuiInput
                        type="number"
                        name="vLength"
                        defaultValue={val.vLength}
                        id={length}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <VuiInput
                        type="number"
                        defaultValue={val.vWidth}
                        name="vWidth"
                        data-id={idx}
                        id={width}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <VuiInput
                        type="number"
                        defaultValue={val.vHeight}
                        name="vHeight"
                        data-id={idx}
                        id={height}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <VuiInput
                        type="number"
                        name="vTonnage"
                        defaultValue={val.vTonnage}
                        data-id={idx}
                        id={tonnage}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </VuiBox>
        ) : (
          <VuiBox></VuiBox>
        )}
      </Grid>
    </VuiBox>
  );
}

export default SelectContainer;
