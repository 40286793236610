const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function getAvailableFclContainer() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/getAllActiveUnbookedFclContainers`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function postBookFclContainer(bookingDetail) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.post(
      `${baseUrl}/shipper/container/bookFclContainer`,
      bookingDetail
    );
    console.log("res1>>>>>>>>>>>>>>>>>>>>>>", response);
    console.log("res2>>>>>>>>>>>>>>>>>>>>>>", response.data);
    return response.data;
  } catch (err) {
    console.log("res3>>>>>>>>>>>>>>>>>>>>>>", err.response);
    console.log("res4>>>>>>>>>>>>>>>>>>>>>>", err.response.data);
    return err.response.data;
  }
}

export async function getAvailableLclContainer() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/getAllActiveUnbookedLclContainers`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function postBookLclContainer(bookingDetail) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/shipper/container/bookLclContainer`,
      bookingDetail
    );
    console.log("res1>>>>>>>>>>>>>>>>>>>>>>", response);
    console.log("res2>>>>>>>>>>>>>>>>>>>>>>", response.data);
    return response.data;
  } catch (err) {
    console.log("res3>>>>>>>>>>>>>>>>>>>>>>", err.response);
    console.log("res4>>>>>>>>>>>>>>>>>>>>>>", err.response.data);
    return err.response.data;
  }
}

export async function getBookedFclContainer() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/getBookedFCLContainersWithApprovedPaymentByShipperID?shipperID=undefined`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getBookedLclContainer() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/getBookedLCLContainersWithApprovedPaymentByShipperID?shipperID=undefined`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getBookedFclContainerWithPaymentPending() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/getBookedFCLContainersWithPendingPaymentByShipperID`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getBookedLclContainerWithPaymentPending() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/getBookedLCLContainersWithPendingPaymentByShipperID`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getRejectedFclContainerRequest() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/rejectedBookingFclContainerRequest?shipperID=undefined`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getRejectedLclContainerRequest() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/container/rejectedBookingLclContainerRequest`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}
