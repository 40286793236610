import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { quickRequestForm } from "services/userService";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Card from "@mui/material/Card";
import VuiDatePicker from "components/VuiDatePicker";

// NewUser page components
//import FormField from "layouts/pages/users/new-user/components/FormField";
import FormField from "layouts/pages/account/components/FormField";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";

function QuickRequestDetails() {
  const { handleSubmit } = useForm();
  const [commodityName, setCommodityName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [budget, setBudget] = useState("");

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "commodityName") {
      setCommodityName(value);
    }

    if (name === "name") {
      setName(value);
    }

    if (name === "description") {
      setDescription(value);
    }
    if (name === "phone") {
      setPhone(value);
    }

    if (name === "email") {
      setEmail(value);
    }
    if (name === "budget") {
      setBudget(value);
    }
  };

  const submitData = () => {
    let requestData = {
      commodityName: commodityName,
      name: name,
      phone: phone,
      email: email,
      description: description,
      budget: budget,
    };
    quickRequestForm(requestData).then((response) => {
      console.log(response, ">>>>>>>>>>>>>>>>>");
    });
  };

  return (
    <VuiBox>
      <Card sx={{ height: "100%" }}>
        <VuiBox lineHeight={0} textAlign="center" marginBottom="2rem">
          <VuiTypography variant="lg" color="text" fontWeight="bold">
            No Ships are currently available
          </VuiTypography>
          <br />
          <VuiTypography variant="lg" fontWeight="light" color="text">
            But please fill this fields to get individual offer.
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={3}>
          <form onSubmit={handleSubmit(submitData)}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  Full Name
                </VuiTypography>
                <VuiInput placeholder="eg. Michael Loris" />

                {/* <FormField
                  required
                  name="name"
                  label="Full Name"
                  placeholder="Full Name"
                  value={name}
                  onChange={handleInputChange}
                /> */}
              </Grid>
              <Grid item xs={12} sm={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  Contact No
                </VuiTypography>
                <VuiInput placeholder="eg. 9833544987" />
                {/* <FormField
                  required
                  type="number"
                  name="phone"
                  label="Contact No"
                  placeholder="Contact No"
                  value={phone}
                  onChange={handleInputChange}
                /> */}
              </Grid>

              <Grid item xs={12} sm={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  Email ID
                </VuiTypography>
                <VuiInput placeholder="eg. vision@dashboard.com" />
                {/* <FormField
                  required
                  type="email"
                  name="email"
                  label="Email ID"
                  placeholder="Email ID"
                  value={email}
                  onChange={handleInputChange}
                /> */}
              </Grid>
              <Grid item xs={12} sm={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  Commodity Name
                </VuiTypography>
                <VuiInput placeholder="eg. Container" />
                {/* <FormField
                  required
                  name="commodityName"
                  label="Commodity Name"
                  placeholder="Commodity Name"
                  value={commodityName}
                  onChange={handleInputChange}
                /> */}
                {/* <VuiTypography variant="caption" color="white" fontWeight="bold">
                Date
              </VuiTypography>
              <VuiDatePicker
                label="Enter Shippment Date"
                input={{ placeholder: "Select a date" }}
                options={{ minDate: new Date() }}
              /> */}
              </Grid>
              <Grid item xs={12} sm={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  Budget
                </VuiTypography>
                <VuiInput placeholder="Enter budget" />
                {/* <FormField
                  requiredtype="number"
                  name="budget"
                  label="Budget"
                  placeholder="Budget"
                  value={budget}
                  onChange={handleInputChange}
                /> */}
              </Grid>

              <Grid item xs={12} sm={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  Description
                </VuiTypography>
                <VuiInput placeholder="Type here..." multiline rows={2} />
                {/* <FormField
                  required
                  name="description"
                  label="Description"
                  placeholder="Type here..."
                  multiline
                  rows={2}
                  value={description}
                  onChange={handleInputChange}
                /> */}
              </Grid>

              <Grid item xs={12} sm={5} sx={{ textAlign: "center" }}>
                <VuiButton type="submit" variant="gradient" color="info" size="large">
                  Submit
                </VuiButton>
              </Grid>
            </Grid>
          </form>
        </VuiBox>
      </Card>
    </VuiBox>
  );
}

export default QuickRequestDetails;
