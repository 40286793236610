import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import mainLoader from "assets/images/mainLoader.gif";
const Loader = () => {
  return (
    <>
      <div
        style={{
          color: "#21126d",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        spacing={2}
        direction="row"
      >
        <div>
          <img src={mainLoader} alt="Loading" />
          {/* <CircularProgress color="inherit" /> */}
        </div>
      </div>
    </>
  );
};

export default Loader;
