import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { supportRequestForm } from "services/userService";
import Swal from "sweetalert2";

function SupportRequest() {
  const [errorMsg, setErrorMsg] = useState({
    firstName: { errorMsg: "" },
    lastName: { errorMsg: "" },
    company: { errorMsg: "" },
    emailAddress: { errorMsg: "" },
    description: { errorMsg: "" },
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [description, setDescription] = useState("");

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.supportRequest[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "company") {
      setCompany(value);
    } else if (name === "emailAddress") {
      setEmailAddress(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const handleSubmit = async () => {
    let errorLengthFirstName = customValidations("firstName", firstName);
    let errorLengthLastName = customValidations("lastName", lastName);
    let errorLengthCompany = customValidations("company", company);
    let errorLengthEmailAddress = customValidations("emailAddress", emailAddress);
    let errorLengthDescription = customValidations("description", description);

    if (
      errorLengthFirstName !== 1 &&
      errorLengthLastName !== 1 &&
      errorLengthCompany !== 1 &&
      errorLengthEmailAddress !== 1 &&
      errorLengthDescription !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log(
        errorLengthFirstName,
        errorLengthLastName,
        errorLengthCompany,
        errorLengthEmailAddress,
        errorLengthDescription
      );
      return;
    }

    const supportRequestData = {
      firstName: firstName,
      lastName: lastName,
      companyName: company,
      email: emailAddress,
      message: description,
    };

    const resp = await supportRequestForm(supportRequestData);

    console.log("requestForm", resp);

    if (resp.success === true) {
      Swal.fire("Request sent successfully", "", "success");
    } else {
      Swal.fire("Something went wrong", "Please try again later", "error");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Card>
            <VuiBox lineHeight={0} display="flex" flexDirection="column">
              <VuiTypography variant="lg" color="text" fontWeight="bold">
                Support Ticket
              </VuiTypography>
              <VuiTypography variant="button" fontWeight="regular" color="text">
                Mandatory informations
              </VuiTypography>
            </VuiBox>
            <VuiBox mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" fontWeight="regular" color="text">
                    First Name
                  </VuiTypography>
                  <VuiInput
                    placeholder="eg. Michael"
                    name="firstName"
                    value={firstName}
                    error={errorMsg.firstName.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.firstName.errorMsg}
                  </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" fontWeight="regular" color="text">
                    Last Name
                  </VuiTypography>
                  <VuiInput
                    placeholder="eg. Prior"
                    name="lastName"
                    value={lastName}
                    error={errorMsg.lastName.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.lastName.errorMsg}
                  </VuiTypography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" fontWeight="regular" color="text">
                    Company
                  </VuiTypography>
                  <VuiInput
                    placeholder="eg. BitNautic"
                    name="company"
                    value={company}
                    error={errorMsg.company.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.company.errorMsg}
                  </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VuiTypography variant="button" fontWeight="regular" color="text">
                    Email Address
                  </VuiTypography>
                  <VuiInput
                    placeholder="eg. vision@dashboard.com"
                    name="emailAddress"
                    value={emailAddress}
                    error={errorMsg.emailAddress.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.emailAddress.errorMsg}
                  </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VuiInput
                    placeholder="Type here..."
                    multiline
                    rows={5}
                    name="description"
                    value={description}
                    error={errorMsg.description.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.description.errorMsg}
                  </VuiTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <VuiButton
                    variant="gradient"
                    color="info"
                    size="large"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </VuiButton>
                </Grid>
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default SupportRequest;
