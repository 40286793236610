const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function createShip(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  console.log("data", data);

  const response = await axios.post(`${baseUrl}/serviceProvider/ship/addShip`, data);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getAvailableShips() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/shipper/ship/getAllActiveUnbookedShips`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function postBookShip(bookingDetail) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>test");
  try {
    const response = await axios.post(`${baseUrl}/shipper/ship/bookShip`, bookingDetail);
    console.log("testing????????????????????????????>>>>>", response);
    return {
      data: response.data,
      error: false,
    };
  } catch (err) {
    console.log(err.response);
    return {
      data: err.response.data,
      //msg: err.response.data.msg,
      // statusCode: err.response.status,
      error: true,
    };
  }
}

export async function getBookedShips() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/ship/getBookedShipsWithApprovedPaymentByShipperID`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getBookedShipWithPaymentPending() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/ship/getBookedShipsWithPendingPaymentByShipperID`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getRejectedBookingRequest() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/shipper/ship/rejectedBookingRequest`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getPorts(ports) {
  const response = await axios.get(`${baseUrl}/ports/getPorts?q=${ports}&limit=10`);
  if (response) {
    console.log("portData>>>>>>>>>>>>>>>>>>>>>", response.data);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}
