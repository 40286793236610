import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";
import themeLight from "assets/themeLight";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "routes";

// Vision UI Dashboard PRO React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
import GuardedRoute from "./utils/GuardedRoute";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import userVerify from "layouts/authentication/verify";
import SignIn from "layouts/authentication/sign-in/cover";
import { logoutUser } from "./services/userService";
import jwt_decode from "jwt-decode";
import setAuthToken from "utils/setAuthToken";
import isEmpty from "utils/isEmpty";
import BookShip from "layouts/pages/ship/book-ship";
import BookFclContainer from "layouts/pages/container/book-fcl-container";
import BookLclContainer from "layouts/pages/container/book-lcl-container";
import BookingDetail from "layouts/pages/ship/booking-detail";
import BookedFclDetails from "layouts/pages/container/booked-fcl-details";
import BookedLclDetails from "layouts/pages/container/booked-lcl-details";
import QuickRequestDetails from "layouts/pages/container/quick-request";
import Loader from "layouts/loader/loader";

import ProfilePage from "layouts/pages/profilePage";
import ParcelBillingDetails from "layouts/pages/freight/parcelBillingDetails";
import ParcelPaymentDetails from "layouts/pages/freight/parcelPaymentDetails";
import PublicSearch from "layouts/authentication/publicSearch/publicSearch";
import NewBitnauticLogo from "./assets/images/bitnautic-white-log.png";
import ForgotPassword from "layouts/authentication/forgotPassword";
import ResetPassword from "layouts/authentication/resetPassword";
import VerifyParcelDetails from "layouts/pages/freight/verifyParcelDetails";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, themeMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [themeSetter, setThemeSetter] = useState(theme);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);
      setIsAuthenticated(true);
      // Check for expired token
      // setInterval(() => {
      //   const currentTime = Date.now() / 1000;
      //   if (decoded.iat < currentTime || isEmpty(localStorage.getItem("jwtToken"))) {
      //     setIsAuthenticated(false);
      //     localStorage.removeItem("jwtToken");
      //     setAuthToken(false);
      //     // Redirect to login
      //     // window.location = "/authentication/sign-in";
      //   }
      // }, 600000);
    }
    setTimeout(() => {
      setLoading(true);
    }, 1500);
  }, [isAuthenticated]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <GuardedRoute
            exact
            path={route.route}
            component={route.component}
            key={route.key}
            auth={isAuthenticated}
          />
        );
      }

      return null;
    });

  useEffect(() => {
    if (themeMode === false) {
      setThemeSetter(themeLight);
    } else {
      setThemeSetter(theme);
    }
  }, [themeMode]);

  console.log(themeMode);

  return (
    <>
      {loading === true ? (
        <ThemeProvider theme={themeSetter}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brandName={<img src={NewBitnauticLogo} alt="logo" width="100%" />}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          <Switch>
            {isAuthenticated ? (
              getRoutes(routes)
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}
            {isAuthenticated ? (
              ""
            ) : (
              <Route
                path={"/authentication/sign-up"}
                component={SignUpIllustration}
                key={"register"}
              />
            )}
            {isAuthenticated ? (
              ""
            ) : (
              <Route
                path={"/authentication/verify-user/:token"}
                component={userVerify}
                key={"verify"}
              />
            )}

            {isAuthenticated ? (
              <Route path={"/shipper/book-ship"} component={BookShip} key={"book-ship"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/parcel-billing-details/:id"}
                component={ParcelBillingDetails}
                key={"parcel-billing-details"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/parcel-payment-details/:id"}
                component={ParcelPaymentDetails}
                key={"parcel-payment-details"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/booking-detail"}
                component={BookingDetail}
                key={"booking-detail"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/quick-request"}
                component={QuickRequestDetails}
                key={"quick-request-details"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/booking-fcl-detail"}
                component={BookedFclDetails}
                key={"booking-fcl-detail"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/booking-lcl-detail"}
                component={BookedLclDetails}
                key={"booking-lcl-detail"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/book-fcl-container"}
                component={BookFclContainer}
                key={"book-fcl-container"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/book-lcl-container"}
                component={BookLclContainer}
                key={"book-lcl-container"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/shipper/profile"} component={ProfilePage} key={"profile"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/shipper/profile"} component={ProfilePage} key={"profile"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/shipper/verify-parcel-details/:id"}
                component={VerifyParcelDetails}
                key={"verify-parcel-details"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {/* <Route path={"/shipper/profile"} component={ProfilePage} key={"profile"} /> */}
            {/* <Route path={"/shipper/loader"} component={Loader} key={"loader"} /> */}
            <Route path={"/public-search"} component={PublicSearch} key={"public-search"} />
            <Route path={"/forgot-password"} component={ForgotPassword} key={"forgot-password"} />
            <Route path={"/reset-password/:id"} component={ResetPassword} key={"reset-password"} />

            {isAuthenticated ? (
              <Redirect from="*" to="/shipper" />
            ) : (
              <Redirect from="*" to="/public-search" />
            )}
          </Switch>
        </ThemeProvider>
      ) : (
        <Loader />
      )}
    </>
  );
}
