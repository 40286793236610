// @mui material components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import { useHistory } from "react-router-dom";
import { postBookLclContainer } from "services/container";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import Footer from "examples/Footer";
import Globe from "examples/Globe";

function BookLclContainer(props) {
  let bookLclContainerDetails = props.location.details;

  const { values } = breakpoints;

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear();
    return date;
  };

  let getTime = (time) => {
    var d = new Date(time);
    var time = d.getHours() + " : " + d.getMinutes();
    return time;
  };

  const dateWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    return time;
  };

  const bookLclContainerHandler = (containerKey, containerOwner) => {
    let bookingDetail = {
      ShipperID: "needToWorkOn",
      serviceProviderID: containerOwner,
      containerKey: containerKey,
      status: "Booked",
    };
    postBookLclContainer(bookingDetail).then((response) => {
      if (response.msg.success === true) {
        swal(response.msg, "Thanks", "success").then(() => {});
      } else {
        swal(response.msg, "Thanks", "warning");
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Grid container>
          <Grid item xs={12} lg={10}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="text"
              >
                Book Lcl Container
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Globe
              display={{ xs: "none", md: "block" }}
              position="absolute"
              top="10%"
              right={0}
              mt={{ xs: -12, lg: 1 }}
              mr={{ xs: 0, md: -10, lg: 10 }}
              canvasStyle={{ marginTop: "3rem" }}
            />
          </Grid>
        </Grid>
        <Card sx={{ overflow: "visible", zIndex: 10 }}>
          <VuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Name : {bookLclContainerDetails.name}
                </VuiTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  ContainerKey : {bookLclContainerDetails.containerKey}
                </VuiTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Price : {bookLclContainerDetails.lclPrice}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Arrival Time : {getTime(bookLclContainerDetails.arrivalTime)}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Departure Time : {getTime(bookLclContainerDetails.departureTime)}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Current Port : {bookLclContainerDetails.currentPort}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Destination Port : {bookLclContainerDetails.destinationPort}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Year of Build : {getDate(bookLclContainerDetails.yearBuild)}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Ship MMSIKey : {bookLclContainerDetails.shipMmsiKey}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Category : {bookLclContainerDetails.category}
                </VuiTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Capacity : {bookLclContainerDetails.capacity}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Width : {bookLclContainerDetails.width}
                </VuiTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Length : {bookLclContainerDetails.length}
                </VuiTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Height : {bookLclContainerDetails.height}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <VuiTypography
                  fontSize="0.75rem"
                  color="text"
                  fontWeight="bold"
                  paddingBottom="0.3rem"
                >
                  Ready to Load : {getDate(bookLclContainerDetails.readyToLoad)}
                </VuiTypography>
              </Grid>
            </Grid>
            <CardActions>
              <VuiButton
                variant="gradient"
                color="info"
                onClick={() =>
                  bookLclContainerHandler(
                    bookLclContainerDetails.containerKey,
                    bookLclContainerDetails.containerOwner
                  )
                }
              >
                SendBookLclContainerRequest
              </VuiButton>
            </CardActions>
          </VuiBox>
        </Card>
      </VuiBox>
    </DashboardLayout>
  );
}

export default BookLclContainer;
