// @mui material components
import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import VuiInput from "components/VuiInput";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import VuiButton from "components/VuiButton";
import Icon from "@mui/material/Icon";

import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";

import { getPorts } from "services/shipService";
import { searchShip, searchLcl, searchFcl, searchShipNew } from "services/logisticsService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineItem from "examples/Timeline/TimelineItem";
import { IoBoatOutline } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { BsClockHistory } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";

import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import DataTable from "examples/Tables/DataTable";

import { useForm } from "react-hook-form";
import TabPanel from "./tab";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import ShipPic from "./favicon.png";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { getAddress, createShipment } from "services/parcelService";

import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { getAddressNew } from "services/parcelService";
import { getAddressDetails } from "services/parcelService";
import { validateAddress } from "services/parcelService";
import { createTransaction } from "services/parcelService";
import QRCode from "react-qr-code";
import logo1 from "./logo1.png";
import logo2 from "./logo2.png";
import logo3 from "./logo3.png";
import clockTimer from "./clockTimer.png";
import Countdown from "react-countdown";
import { initiateTransaction } from "services/parcelService";
import { getPaymentAddress } from "services/parcelService";
import { checkPaymentBTNT } from "services/parcelService";
import NewLoader from "./Loader.gif";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { getShipmentDetails } from "services/parcelService";
import BtntLogo from "./favicon.png";
import CopyToClipboard from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { setOpenConfigurator } from "context";

function ParcelPaymentDetails() {
  const { id } = useParams();

  console.log("id", id);

  const shipmentId = id;
  const { handleSubmit } = useForm();
  let history = useHistory();
  const [paymentAddressValue, setPaymentAddressValue] = useState("");
  const [qrAmountBtnt, setQrAmountBtnt] = useState("");
  const [amountBtnt1, setAmountBtnt1] = useState("");
  const [amountBtnt, setAmountBtnt] = useState("Processing");
  const [amountUsd, setAmountUsd] = useState("Processing");
  const [date, setDate] = useState("Processing");
  const [status, setStatus] = useState("Status");
  const [wallet, setWallet] = useState("");
  const [loader, setLoader] = useState();
  const [exitCaller, setExitCaller] = useState("");
  const [timerrr, setTimerrr] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [retry, setRetry] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copy, setCopy] = useState("copy");
  const [open, setOpen] = useState(false);
  const [shipmentID, setShipmentID] = useState(0);

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return date;
  };

  // const timerExpire = () => {
  //   Swal.fire("", "Payment failed", "error").then(() => {
  //     history.replace("/shipper");
  //   });
  // };

  const children = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
      <>
        <VuiTypography>
          {minutes}:{seconds}
        </VuiTypography>
      </>
    );
  };

  const initialTimer = localStorage.getItem("timer") ?? 600;
  const timeoutId = useRef(null);
  const [timer, setTimer] = useState(initialTimer);

  const countTimer = useCallback(() => {
    if (timer <= 0 || status === "Booked") {
      localStorage.removeItem("timer");
    } else {
      setTimer(timer - 1);
      localStorage.setItem("timer", timer);
    }
  }, [timer]);

  useEffect(() => {
    timeoutId.current = window.setTimeout(countTimer, 1000);
    // cleanup function
    return () => window.clearTimeout(timeoutId.current);
  }, [timer, countTimer]);

  console.log("timmmmme", timer);

  if (timer === 0) {
    history.replace("/shipper");
    localStorage.removeItem("timer");
    Swal.fire("", "Payment failed", "error");

    // Swal.fire("", "Payment failed", "error").then(() => {
    //   history.replace("/shipper");
    // });

    // Swal.fire({
    //   position: "center",
    //   icon: "error",
    //   title: "Payment Failed",
    //   showConfirmButton: false,
    // }).then(() => {
    //   window.location.replace("/shipper");
    // });
  }

  useEffect(() => {
    fetchCheckBtntData();
  }, []);

  const fetchCheckBtntData = async () => {
    const resp1 = await getShipmentDetails(shipmentId);
    console.log("resp1-getShipmentDetails", resp1);

    console.log("resp1-getShipmentDetails", resp1.order.transaction);

    if (resp1.order.transaction !== null) {
      setTimerrr(false);
      history.push(`/shipper`);
      localStorage.removeItem("timer");
    }

    setShipmentID(resp1.id);
    setOrderId(resp1.orderID);
    setQrAmountBtnt(resp1.order.amountBtnt);
    const resp2 = await initiateTransaction(resp1.orderID);
    setTimerrr(true);
    console.log("resp2-initiateTransaction", resp2);
    const resp3 = await getPaymentAddress();
    console.log("resp3-getPaymentAddress", resp3);
    setPaymentAddressValue(resp3);
  };

  const handleCheckPayment = () => {
    setLoader(true);
    let checkPaymentBtnt = {
      orderID: orderId,
      shipmentID: shipmentID,
      reciverAddress: wallet,
    };

    checkPaymentBTNT(checkPaymentBtnt).then((resp) => {
      console.log("resp", resp);

      if (resp === "Add a valid Wallet Address") {
        setLoader(false);
        Swal.fire("", "Add a valid Wallet Address", "warning");
      } else if (resp === "No Transactions Found or Invalid address!") {
        setLoader(false);
        Swal.fire("", "No Transactions Found or Invalid address!", "warning");
      } else {
        if (resp?.shipment?.status === "Booked") {
          setLoader(false);
          setTimerrr(false);
          setAmountUsd(resp?.amountUsd);
          setAmountBtnt(resp?.amountBtnt);
          setDate(getDate(resp?.bookingDate));
          setStatus(resp?.shipment.status);
          Swal.fire("", "Payment Successful", "success");
        } else {
          setRetry(true);
          setLoader(false);
          setAmountUsd(resp?.amountUsd);
          setAmountBtnt(resp?.amountBtnt);
          setDate(getDate(resp?.bookingDate));
          setStatus(resp?.shipment.status);
        }
      }
    });
  };

  const handleCopyIcon = (text) => {
    navigator.clipboard.writeText(text);
    setCopy("Copied");
  };

  const handleCopyButton = (text) => {
    navigator.clipboard.writeText(text);
    setCopy("Copied");
    setOpen(true);
  };

  const handleClose = () => {
    setCopy("Copy");
    setOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        {/* <Grid container>
          <Grid item xs={12}>
            <Globe
              display={{ xs: "none", md: "block" }}
              position="absolute"
              top="10%"
              right={0}
              mt={{ xs: -12, lg: 1 }}
              mr={{ xs: 0, md: -10, lg: 10 }}
              canvasStyle={{ marginTop: "3rem" }}
            />
          </Grid>
        </Grid> */}
        <Card>
          <VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
            {timerrr === true ? (
              <>
                <VuiBox>
                  <VuiBox>
                    <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                      Please do not refresh or leave the page
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CountdownCircleTimer
                      size={80}
                      strokeWidth={5}
                      duration={timer}
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      colorsTime={[10, 6, 3, 0]}
                    >
                      {children}
                    </CountdownCircleTimer>
                  </VuiBox>
                </VuiBox>
              </>
            ) : (
              <VuiBox sx={{ height: 110 }}></VuiBox>
            )}
          </VuiBox>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <VuiBox sx={{ textAlign: "center" }}>
                <VuiBox>
                  <img src={logo1} width="80" alt="logo1" />
                </VuiBox>
                <Card>
                  <VuiBox sx={{ marginBottom: "3.5rem" }}>
                    <VuiTypography fontWeight="bold" color="text">
                      Invoice
                    </VuiTypography>
                    <VuiTypography fontWeight="light" fontSize="1rem" color="text">
                      Scan the QR code and pay BTNT. You can also copy the contract address and
                      paste in your wallet if scanning is not available.
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ display: "flex", justifyContent: "center" }}>
                    <VuiTypography color="text">{qrAmountBtnt}</VuiTypography>
                    <VuiTypography color="text" fontWeight="bold">
                      &nbsp; BTNT
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox marginBottom="0.5rem">
                    <QRCode size={150} value={paymentAddressValue} />
                  </VuiBox>
                  <VuiBox>
                    <VuiTypography fontWeight="regular" fontSize="1rem" color="text">
                      OR
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox
                    sx={{
                      marginBottom: "0.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <VuiBox sx={{ width: "90%" }}>
                      <VuiTypography fontWeight="light" fontSize="1rem" color="text">
                        {paymentAddressValue}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox sx={{ cursor: "pointer" }}>
                      <Tooltip title={copy} onClose={() => handleClose()}>
                        <IconButton onClick={() => handleCopyIcon(paymentAddressValue)}>
                          <MdOutlineContentCopy />
                        </IconButton>
                      </Tooltip>
                    </VuiBox>

                    {/* <VuiInput  multiline value={paymentAddressValue} /> */}
                  </VuiBox>
                  <Tooltip title={copy} open={open} onClose={() => handleClose()}>
                    <VuiButton
                      color="info"
                      variant="gradient"
                      onClick={() => handleCopyButton(paymentAddressValue)}
                    >
                      {/* {copied === true ? "Copied" : "Copy QrCode"} */}Copy QrCode
                    </VuiButton>
                  </Tooltip>
                </Card>
              </VuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <VuiBox sx={{ textAlign: "center" }}>
                <VuiBox>
                  <VuiBox>
                    <img src={logo2} width="80" alt="logo2" />
                  </VuiBox>

                  <Card>
                    <VuiBox sx={{ marginBottom: "3.5rem" }}>
                      <VuiTypography fontWeight="bold" color="text">
                        Address
                      </VuiTypography>
                      <VuiTypography fontWeight="light" fontSize="1rem" color="text">
                        Enter your wallet address here for us to verify your payment.
                      </VuiTypography>
                    </VuiBox>
                    <VuiInput
                      sx={{ marginBottom: "0.5rem" }}
                      placeholder="eg. 0xF6fBBAa12146118c747DC2BbD619D8221B0f7XyZ"
                      value={wallet}
                      onChange={(e) => setWallet(e.target.value)}
                    />
                    <VuiButton color="info" variant="gradient" onClick={() => handleCheckPayment()}>
                      {retry ? "Retry" : "Confirm"}
                    </VuiButton>
                  </Card>

                  {/* <VuiBox sx={{ textAlign: "center" }}>
                  <VuiTypography fontWeight="bold" color="text">
                    Address
                  </VuiTypography>
                  <VuiTypography fontWeight="light" fontSize="1rem" color="text">
                    Enter your wallet address here for us to verify your payment
                  </VuiTypography>
                </VuiBox> */}

                  {/* <VuiTypography
                  marginBottom="3rem"
                  fontWeight="regular"
                  fontSize="0.9rem"
                  color="text"
                >
                  Enter your wallet address here for us to verify your payment
                </VuiTypography> */}
                </VuiBox>

                <VuiBox sx={{ marginTop: "1.5rem" }}>
                  {loader === true ? (
                    <VuiBox>
                      <img src={NewLoader} alt="Loading" />
                    </VuiBox>
                  ) : null}
                </VuiBox>
              </VuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <VuiBox>
                <VuiBox sx={{ textAlign: "center" }}>
                  <img src={logo3} width="80" alt="logo2" />
                </VuiBox>
                <Card sx={{ height: "510px" }}>
                  <VuiBox sx={{ marginBottom: "3.5rem", textAlign: "center" }}>
                    <VuiTypography fontWeight="bold" color="text">
                      Payment
                    </VuiTypography>
                    <VuiTypography fontWeight="light" fontSize="1rem" color="text">
                      Check your booking status
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox>
                    <VuiBox sx={{ marginBottom: "1.5rem", textAlign: "center" }}>
                      <VuiTypography color="text">{status}</VuiTypography>
                    </VuiBox>

                    <VuiBox sx={{ display: "flex", justifyContent: "center" }}>
                      <VuiTypography color="text">{amountBtnt}</VuiTypography>
                      <VuiTypography color="text" fontWeight="bold">
                        &nbsp; BTNT
                      </VuiTypography>
                    </VuiBox>
                    <Divider />
                    <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
                      <VuiBox>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                          Date
                        </VuiTypography>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                          Amount USD
                        </VuiTypography>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                          Amount BTNT
                        </VuiTypography>

                        {/* <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                        Network Cost
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                        Total
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                        Amount Paid
                      </VuiTypography> */}
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                          {date}
                        </VuiTypography>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                          {amountUsd}
                        </VuiTypography>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                          {amountBtnt}
                        </VuiTypography>

                        {/* <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                        0.000029 BTC
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                        0.013104 BTC
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                        0.013104 BTC
                      </VuiTypography> */}
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                </Card>
              </VuiBox>
            </Grid>
          </Grid>
          {/* <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
            

            

          
          </VuiBox> */}
        </Card>
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ParcelPaymentDetails;
