import React from "react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { getSearchedOrders } from "services/parcelService";
import NewLoader from "./Loader.gif";

const RecentSearchedOrders = () => {
  let history = useHistory();
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [searchedOrderBoolean, setSearchedOrderBoolean] = useState(false);
  const [responseLoader, setResponseLoader] = useState(false);

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return date;
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    setResponseLoader(true);

    setSearchedOrderBoolean(false);

    getSearchedOrders().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>>> searchedOrders", Response);

      if (Response === "No shipment found!") {
        setSearchedOrders([]);
        return;
      } else {
        setResponseLoader(false);
      }

      let newResponse = [];
      for (let item of Response) {
        newResponse.push({
          ...item,
          action1: (
            <VuiButton variant="gradient" color="info" onClick={() => searchAgain(item)}>
              Search Again
            </VuiButton>
          ),
        });
      }
      console.log("parcelOrders", newResponse);
      setSearchedOrders(newResponse);
    });
  };

  const searchAgain = (item) => {
    // history.push("/shipper");

    const pick = `${item.addressFrom.street1}, ${item.addressFrom.city}`;
    const dest = `${item.addressTo.street1}, ${item.addressTo.city}`;
    history.push(`/shipper/?pickup=${pick}&destination=${dest}`);
    // window.location.href = `http://localhost:3000/#/shipper/?pickup=${pick}&destination=${dest}`;
  };

  const parcelOrderTable = {
    columns: [
      {
        Header: "Date",
        accessor: "queryDate",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {getDate(cell.row.values.queryDate)}
            </VuiTypography>
          </>
        ),
      },

      {
        Header: "Sender",
        accessor: "addressFrom",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressFrom.street1}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressFrom.city},
              {cell.row.values.addressFrom.state === "null"
                ? ""
                : `${cell.row.values.addressFrom.state},`}
              {cell.row.values.addressFrom.zip}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressFrom.country}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Receiver",
        accessor: "addressTo",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressTo.street1}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressTo.city},
              {cell.row.values.addressTo.state === "null"
                ? ""
                : `${cell.row.values.addressTo.state},`}
              {cell.row.values.addressTo.zip}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressTo.country}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Parcel",
        accessor: "parcels",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.parcels[0].weight}&nbsp;{cell.row.values.parcels[0].mass_unit}&nbsp;
              {cell.row.values.parcels[0].length}
              &times;
              {cell.row.values.parcels[0].width}
              &times;
              {cell.row.values.parcels[0].height}&nbsp;&nbsp;
              {cell.row.values.parcels[0].distance_unit}
            </VuiTypography>
          </>
        ),
      },

      {
        Header: "Action",
        accessor: "action1",
        align: "center",
        // Cell: (cell) => {
        //   return (
        //     <VuiButton
        //       variant="gradient"
        //       color="info"
        //       size="medium"
        //       onClick={() => buy(cell.row.original)}
        //     >
        //       Buy
        //     </VuiButton>
        //   );
        // },
      },
    ],

    rows: searchedOrders ? searchedOrders : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        {responseLoader ? (
          <VuiBox sx={{ marginTop: "10rem", textAlign: "center" }}>
            <img src={NewLoader} alt="logo" />
            <VuiTypography color="text">Please wait</VuiTypography>
          </VuiBox>
        ) : (
          <Card>
            <VuiBox p={3} pl={0} lineHeight={1}>
              <VuiTypography variant="h5" fontWeight="medium" color="text">
                Recently Searched Orders
              </VuiTypography>
              <VuiTypography variant="button" fontWeight="regular" color="text">
                List of recent searches.
              </VuiTypography>
            </VuiBox>
            <DataTable table={parcelOrderTable} canSearch />
          </Card>
        )}

        {searchedOrderBoolean === true ? (
          <VuiBox sx={{ textAlign: "center" }}>
            <VuiTypography color="text" fontWeight="bold">
              No shipment found
            </VuiTypography>
          </VuiBox>
        ) : null}
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default RecentSearchedOrders;
