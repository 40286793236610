import React from "react";
import { useState, useEffect } from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import container from "./container.png";
import truck from "./truck.png";
import boxes from "./boxes.png";
import bigBags from "./bigBags.png";
import sacks from "./sacks.png";
import barrel from "./barrel.png";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import FormGroup from "@mui/material/FormGroup";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function Profile(props) {
  const { values, handleChange } = props;
  const { tableList, containerType } = values;

  console.log(containerType);

  let jsonObject = containerType.map(JSON.stringify);

  console.log(jsonObject);

  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

  console.log(uniqueArray);

  const arrayCounter = (arrayName) => {
    let counter = 0;
    for (let list of arrayName) {
      counter++;
    }
    return counter;
  };

  const containerTypeCount = (arrayName, cName) => {
    let counter = 0;
    for (let item of arrayName) {
      if (item.cType === cName) counter += 1;
    }
    return counter;
  };

  // useEffect(() => {
  //   arrayCounter(tableList);
  //   arrayCounter(containerType);
  // }, []);

  console.log(
    arrayCounter(tableList),
    arrayCounter(containerType),
    containerTypeCount(containerType, "40dv")
  );

  // let counter = 0;
  // for (let obj of containerType) {
  //   counter++;
  // }
  // console.log(counter);

  return (
    <VuiBox>
      <VuiTypography variant="SubTitle1" color="text" fontWeight="light">
        Results
      </VuiTypography>

      <Grid container xs={12} marginTop={"20px"} border="1px solid" borderColor={"white"}>
        <Grid container item xs={6} justifyContent={"center"}>
          <VuiTypography color="text" fontWeight="light">
            Containers
          </VuiTypography>
        </Grid>
        <Grid container item xs={6} justifyContent={"center"}>
          <VuiTypography color="text" fontWeight="light">
            Cargo Packing
          </VuiTypography>
        </Grid>
      </Grid>
      <Grid container xs={12} border="1px solid" borderColor={"white"}>
        <Grid container item xs={6} justifyContent={"center"}>
          <Grid item xs={12}>
            <VuiTypography color="text" fontWeight="light">
              All Containers
            </VuiTypography>
          </Grid>
          <Grid item xs={12}>
            {uniqueArray.map((val, idx) => (
              <VuiTypography key={idx} color="text" fontWeight="light">
                {val.cType}: {containerTypeCount(containerType, val.cType)}
              </VuiTypography>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={6} justifyContent={"center"}>
          <Grid item xs={12}>
            <VuiTypography color="text" fontWeight="light">
              Total: {arrayCounter(tableList)} packages
            </VuiTypography>
          </Grid>
          <Grid item xs={12}>
            <VuiTypography color="text" fontWeight="light">
              Packed: {arrayCounter(tableList)} packages (100%)
            </VuiTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} border="1px solid" borderColor={"white"}>
        <Grid container item xs={6} justifyContent={"center"}>
          <Grid item xs={12}>
            <VuiTypography>Container Image</VuiTypography>
          </Grid>
        </Grid>
        <Grid container item xs={6} justifyContent={"center"}>
          <Grid item xs={12}>
            <VuiTypography color="text">content content</VuiTypography>
          </Grid>
          <Grid item xs={12}>
            <VuiTypography color="text">content content</VuiTypography>
          </Grid>
          <Grid item xs={12}>
            <VuiTypography color="text">content content</VuiTypography>
          </Grid>
          <Grid item xs={12}>
            <VuiTypography color="text">content content</VuiTypography>
          </Grid>
          <Grid item xs={12}>
            <VuiTypography color="text">content content</VuiTypography>
          </Grid>
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Profile;
