import React from "react";
// @mui material components
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
import Footer from "examples/Footer";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MapBoxVessel from "./components/map";
import SearchBoxVessel from "./components/search";

function VesselTracking() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <SearchBoxVessel />
          </Grid>
          <Grid item xs={12} lg={6}>
            <VuiBox>
              <MapBoxVessel />
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default VesselTracking;
