import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  appbar: {
    position: "relative",
    backgroundColor: "#002966",
    marginBottom: "3rem",
  },

  navbar: {
    flexGrow: 1,
  },

  img: {
    width: "120px",
    objectFit: "contain",
  },
}));

export default useStyles;
