// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import container from "./container.png";
import truck from "./truck.png";
import boxes from "./boxes.png";
import bigBags from "./bigBags.png";
import sacks from "./sacks.png";
import barrel from "./barrel.png";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import FormGroup from "@mui/material/FormGroup";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Typography } from "@mui/material";

function StuffingSettings(props) {
  const { values, handleChange } = props;
  const { tableList } = values;
  return (
    <VuiBox>
      <VuiBox textAlign="center" marginBottom="3rem">
        <VuiTypography color="text" fontWeight="light" fontSize="1.3rem" marginBottom="3.2rem">
          Space settings of cargo in container
        </VuiTypography>

        <VuiTypography color="text" fontWeight="light" fontSize="1rem">
          Select position(s) of load acceptable for the transportation of that cargo type. Putting
          limits on positions of load could adversely affect effective stuffing.
        </VuiTypography>
      </VuiBox>

      {tableList.map((val, idx) => {
        return (
          <Grid container>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Cargo: {val.tname}
                </VuiTypography>
                <VuiTypography
                  color="white"
                  fontWeight="light"
                  marginBottom="1.5rem"
                  fontSize="1rem"
                >
                  Qty: {val.tqty}
                </VuiTypography>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Weight: {val.tweight} kg.
                </VuiTypography>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Length: {val.tlength} mm.
                </VuiTypography>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Width: {val.twidth} mm.
                </VuiTypography>
                <VuiTypography color="text" fontWeight="light" fontSize="1rem">
                  Height: {val.theight} mm.
                </VuiTypography>
              </Grid>
              <Grid container item xs={8} justifyContent="space-around" textAlign={"center"}>
                <Grid item>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                    Height
                  </VuiTypography>
                  <VuiBox
                    color="white"
                    border="1px solid"
                    borderColor="white"
                    shadow="lg"
                    opacity={1}
                    height="6rem"
                    width="6rem"
                  />
                  <input
                    style={{ height: "15px", width: "15px" }}
                    name="height"
                    type="checkbox"
                    defaultChecked

                    // onClick={handleClick}
                  />
                </Grid>
                <Grid item>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                    Length
                  </VuiTypography>
                  <VuiBox
                    color="white"
                    border="1px solid"
                    borderColor="white"
                    shadow="lg"
                    opacity={1}
                    height="6rem"
                    width="6rem"
                  ></VuiBox>
                  <input
                    style={{ height: "15px", width: "15px" }}
                    name="length"
                    type="checkbox"
                    defaultChecked

                    // onClick={handleClick}
                  ></input>
                </Grid>
                <Grid item>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                    Width
                  </VuiTypography>
                  <VuiBox
                    color="white"
                    border="1px solid"
                    borderColor="white"
                    shadow="lg"
                    opacity={1}
                    height="6rem"
                    width="6rem"
                  ></VuiBox>
                  <input
                    style={{ height: "15px", width: "15px" }}
                    name="width"
                    type="checkbox"
                    defaultChecked

                    // onClick={handleClick}
                  ></input>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </VuiBox>
  );
}

export default StuffingSettings;
