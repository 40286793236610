import React from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
// import Link from "@mui/material/Link";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { getOrders } from "services/parcelService";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import LockIcon from "@mui/icons-material/Lock";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { FaAngleDown } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { orderRefund } from "services/parcelService";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import NewLoader from "./Loader.gif";

const ParcelOrderList = () => {
  const history = useHistory();
  const [parcelOrders, setParcelOrders] = useState([]);

  const [fromName, setFromName] = useState("");
  const [fromCompanyName, setFromCompanyName] = useState("");
  const [fromCountry, setFromCountry] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [fromState, setFromState] = useState("");
  const [fromStreet1, setFromStreet1] = useState("");
  const [fromTaxID, setFromTaxID] = useState("");
  const [fromZip, setFromZip] = useState("");
  const [fromPhone, setFromPhone] = useState("");

  const [toName, setToName] = useState("");
  const [toCompanyName, setToCompanyName] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [toCity, setToCity] = useState("");
  const [toState, setToState] = useState("");
  const [toStreet1, setToStreet1] = useState("");
  const [toTaxID, setToTaxID] = useState("");
  const [toZip, setToZip] = useState("");
  const [toPhone, setToPhone] = useState("");

  const [parcelHeight, setParcelHeight] = useState("");
  const [parcelLength, setParcelLength] = useState("");
  const [parcelWidth, setParcelWidth] = useState("");
  const [parcelDistanceUnit, setParcelDistanceUnit] = useState("");
  const [parcelWeight, setParcelWeight] = useState("");
  const [parcelMassUnit, setParcelMassUnit] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogBox, setDialogBox] = useState(false);

  const [shipmentID, setShipmentID] = useState("");
  const [transaction, setTransaction] = useState("null");
  const [refundStatus, setRefundStatus] = useState(false);

  const [orderListBoolean, setOrderListBoolean] = useState(false);
  const [responseLoader, setResponseLoader] = useState(false);

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return date;
  };

  useEffect(() => {
    fetchOrders();
  }, [refundStatus]);

  const fetchOrders = () => {
    setResponseLoader(true);

    setOrderListBoolean(false);

    getOrders().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>>> parcelCompletedOrderlist", Response);

      if (Response === "NO order") {
        setParcelOrders([]);
        return;
      } else {
        setResponseLoader(false);
      }

      let newResponse = [];
      for (let item of Response) {
        newResponse.push({
          ...item,
          action1: (
            <ButtonGroup sx={{ width: "9rem" }}>
              <VuiButton fullWidth variant="gradient" color="info" onClick={() => buy(item)}>
                {/* {item.status === "Booked" ? "Download" : "Buy"} */}
                {item.status === "Booked"
                  ? "Download"
                  : item.status === "Refund"
                  ? "Refund"
                  : "Buy"}
              </VuiButton>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <VuiButton
                      {...bindTrigger(popupState)}
                      variant="gradient"
                      sx={{ height: "40px" }}
                      iconOnly
                      color="info"
                    >
                      <FaAngleDown />
                    </VuiButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          boxShadow: "0px 1px 10px",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      {...bindMenu(popupState)}
                      // onClose={(e) => handleClose(e)}
                    >
                      <MenuItem
                        // component={Link}
                        // to="/addvideo"
                        // onClick={(e) => handleClose(e)}
                        onClick={() => handleRefundLabel(popupState.close(), item)}
                      >
                        {!item.order.transaction ? "View" : "Refund Label"}
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </ButtonGroup>
          ),
        });
      }
      console.log("parcelOrdersCompleted", newResponse);
      setParcelOrders(newResponse);
    });
  };

  const buy = (item) => {
    console.log("buy", item);

    const pick = `${item.addressFrom.street1}, ${item.addressFrom.city}`;
    const dest = `${item.addressTo.street1}, ${item.addressTo.city}`;

    if (item.status === "Booked") {
      // history.push(`${item.order.transaction.label_url}`);
      window.location.href = `${item.order.transaction.label_url}`;
    } else {
      history.push(`/shipper/?pickup=${pick}&destination=${dest}`);
    }
  };

  const handleRefundLabel = (popupState, item) => {
    console.log("IDDD", item);
    setTransaction("null");

    setFromName(item.addressFrom.name);
    setFromCompanyName(item.addressFrom.companyName);
    setFromEmail(item.addressFrom.email);
    setFromCountry(item.addressFrom.country);
    setFromCity(item.addressFrom.city);
    setFromState(item.addressFrom.state);
    setFromStreet1(item.addressFrom.street1);
    setFromTaxID(item.addressFrom.taxID);
    setFromZip(item.addressFrom.zip);
    setFromPhone(item.addressFrom.phoneNumber);

    setToName(item.addressTo.name);
    setToCompanyName(item.addressTo.companyName);
    setToEmail(item.addressTo.email);
    setToCountry(item.addressTo.country);
    setToCity(item.addressTo.city);
    setToState(item.addressTo.state);
    setToStreet1(item.addressTo.street1);
    setToTaxID(item.addressTo.taxID);
    setToZip(item.addressTo.zip);
    setToPhone(item.addressTo.phoneNumber);

    setParcelLength(item.parcels[0].length);
    setParcelWidth(item.parcels[0].width);
    setParcelHeight(item.parcels[0].height);
    setParcelDistanceUnit(item.parcels[0].distance_unit);
    setParcelWeight(item.parcels[0].weight);
    setParcelMassUnit(item.parcels[0].mass_unit);

    if (!item.order.transaction) {
      console.log("transactionNull");
    } else {
      console.log("transactionValue");
      setShipmentID(item.id);
      setTransaction(item.order.transaction.object_id);
    }

    setDialogBox(true);
  };

  const handleDialogBox = () => {
    setDialogBox(false);
  };

  const handleDialogClose = () => {
    setDialogBox(false);
  };

  const handleRefund = async () => {
    let refundData = {
      shipmentID,
      transaction,
    };
    setDialogBox(false);
    Swal.fire("Refund process initiated", "Thank you", "success");
    const refundResponse = await orderRefund(refundData);

    console.log("refundRes", refundResponse);
    setRefundStatus(true);
  };

  const parcelOrderTable = {
    columns: [
      {
        Header: "Date",
        accessor: "queryDate",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {getDate(cell.row.values.queryDate)}
            </VuiTypography>
          </>
        ),
      },

      {
        Header: "Sender",
        accessor: "addressFrom",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressFrom?.street1}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressFrom?.city},
              {cell.row.values.addressFrom?.state === "null"
                ? ""
                : `${cell.row.values.addressFrom?.state},`}
              {cell.row.values.addressFrom?.zip}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressFrom?.country}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Receiver",
        accessor: "addressTo",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressTo?.street1}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressTo?.city},
              {cell.row.values.addressTo?.state === "null"
                ? ""
                : `${cell.row.values.addressTo?.state},`}
              {cell.row.values.addressTo?.zip}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.addressTo?.country}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Parcel",
        accessor: "parcels",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="p">
              {cell.row.values.parcels[0].weight}&nbsp;{cell.row.values.parcels[0].mass_unit}&nbsp;
              {cell.row.values.parcels[0].length}
              &times;
              {cell.row.values.parcels[0].width}
              &times;
              {cell.row.values.parcels[0].height}&nbsp;&nbsp;
              {cell.row.values.parcels[0].distance_unit}
            </VuiTypography>
          </>
        ),
      },

      // {
      //   Header: "Provider Logo",
      //   accessor: "orderProvider",
      //   align: "center",
      //   Cell: (cell) => (
      //     <>
      //       <img src={cell.row.values.order.orderProvider.provider_image_200} width="60px" alt="" />
      //     </>
      //   ),
      // },

      {
        Header: "Provider",
        accessor: "order",
        align: "center",
        Cell: (cell) => (
          <VuiTypography variant="sm" color="text">
            {cell.row.values.order.orderProvider.provider} parcel
          </VuiTypography>
        ),
      },

      {
        Header: "Booking Status",
        accessor: "status",
        align: "center",
      },

      {
        Header: "Status",
        accessor: "action1",
        align: "center",
        // Cell: (cell) => {
        //   return (
        //     <VuiButton
        //       variant="gradient"
        //       color="info"
        //       size="medium"
        //       onClick={() => buy(cell.row.original)}
        //     >
        //       Buy
        //     </VuiButton>
        //   );
        // },
      },
    ],

    rows: parcelOrders ? parcelOrders : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <VuiBox sx={{ border: "1px solid red", display: "flex", justifyContent: "flex-end" }}>
        <ButtonGroup>
          <VuiButton variant="gradient" color="info">
            Download
          </VuiButton>
          <VuiButton
            onClick={handleClick}
            variant="gradient"
            sx={{ height: "40px" }}
            iconOnly
            color="info"
          >
            <FaAngleDown />
          </VuiButton>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              // component={Link}
              // to="/addvideo"
              onClick={handleClose}
            >
              Refund Label
            </MenuItem>
          </Menu>
        </ButtonGroup>
      </VuiBox> */}
      <VuiBox pt={6} pb={3}>
        {responseLoader ? (
          <VuiBox sx={{ marginTop: "10rem", textAlign: "center" }}>
            <img src={NewLoader} alt="logo" />
            <VuiTypography color="text">Please wait</VuiTypography>
          </VuiBox>
        ) : (
          <Card>
            <VuiBox p={3} pl={0} lineHeight={1}>
              <VuiTypography variant="h5" fontWeight="medium" color="text">
                Available Orders
              </VuiTypography>
              <VuiTypography variant="button" fontWeight="regular" color="text">
                List of all orders.
              </VuiTypography>
            </VuiBox>
            <DataTable table={parcelOrderTable} canSearch />
          </Card>
        )}

        <Dialog
          sx={{ "& .MuiDialog-paper": { background: "rgb(242, 240, 254)" } }}
          fullWidth={true}
          maxWidth="md"
          open={dialogBox}
          onClose={() => handleDialogClose()}
        >
          {transaction !== "null" ? (
            <VuiBox>
              <VuiBox sx={{ marginTop: "3rem", backgroundColor: "#fde9e8" }}>
                <DialogTitle>
                  <VuiBox sx={{ display: "flex", alignItems: "center" }}>
                    <FiAlertTriangle size={30} color="red" style={{ marginRight: "1rem" }} />
                    <VuiTypography color="text" fontWeight="bold" fontSize="1.5rem">
                      Are you sure you want to refund this shipping label?
                    </VuiTypography>
                  </VuiBox>
                </DialogTitle>
                <DialogContent dividers>
                  <VuiBox sx={{ marginBottom: "1rem" }}>
                    <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                      Take into consideration the following important information:
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ marginLeft: "2rem", marginBottom: "1rem" }}>
                    <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                      1. Refunds typically take
                      <VuiTypography
                        display="inline-block"
                        color="text"
                        fontWeight="bold"
                        fontSize="1rem"
                      >
                        &nbsp; 14 days &nbsp;
                      </VuiTypography>
                      to credit into your account.&nbsp;
                      <VuiTypography
                        color="text"
                        fontWeight="regular"
                        fontSize="1rem"
                        sx={{ textDecoration: "underline", display: "inline" }}
                      >
                        <Link>Read more about refunds</Link>
                      </VuiTypography>
                    </VuiTypography>
                    <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                      2. You will immediately be unable to use this shipping label for postage.
                    </VuiTypography>
                    <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                      3. This action cannot be undone, but you can create a new label.
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox>
                    <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                      Note also that USPS labels that have not been used for 30 days since creation
                      are automatically refunded.
                    </VuiTypography>
                  </VuiBox>
                </DialogContent>
              </VuiBox>
              <DialogActions>
                <VuiBox sx={{ display: "flex" }}>
                  <VuiBox sx={{ marginRight: "0.3rem" }}>
                    <VuiButton variant="gradient" color="info" onClick={() => handleDialogBox()}>
                      Cancel
                    </VuiButton>
                  </VuiBox>
                  <VuiBox>
                    <VuiButton variant="gradient" color="error" onClick={() => handleRefund()}>
                      Yes,Request Refund
                    </VuiButton>
                  </VuiBox>
                </VuiBox>
              </DialogActions>
            </VuiBox>
          ) : (
            <VuiBox>
              <DialogTitle sx={{ textAlign: "center" }}>
                <VuiTypography color="text" fontWeight="bold" fontSize="1rem">
                  Order Details
                </VuiTypography>
              </DialogTitle>
              <DialogContent dividers>
                <VuiBox>
                  <VuiBox sx={{ marginBottom: "1rem" }}>
                    <VuiBox>
                      <VuiTypography color="text" fontWeight="bold" fontSize="1rem">
                        Sender
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromName}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromStreet1},
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromCity},
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromState === "null" ? "" : `${fromState},`}
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromZip},
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromCountry}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromEmail},
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {fromPhone}
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                  <Divider />
                  <VuiBox sx={{ marginBottom: "1rem" }}>
                    <VuiBox>
                      <VuiTypography color="text" fontWeight="bold" fontSize="1rem">
                        Recipient
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toName}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toStreet1},
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toCity},
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toState === "null" ? "" : `${toState},`}
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toZip},
                      </VuiTypography>
                      &nbsp;
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toCountry}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toEmail},
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        {toPhone}
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                </VuiBox>
                <Divider />
                <VuiBox>
                  <VuiBox>
                    <VuiTypography color="text" fontWeight="bold" fontSize="1rem">
                      Load
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ width: "30%", display: "flex", justifyContent: "space-between" }}>
                    <VuiBox>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        Length:{parcelLength}
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        Width:{parcelWidth}
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        Height:{parcelHeight}
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        Distance unit:{parcelDistanceUnit}
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        Weight:{parcelWeight}
                      </VuiTypography>
                      <VuiTypography color="text" fontWeight="regular" fontSize="1rem">
                        Mass unit:{parcelMassUnit}
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                </VuiBox>
              </DialogContent>
              <DialogActions>
                <VuiBox sx={{ display: "flex" }}>
                  <VuiBox sx={{ marginRight: "0.3rem" }}>
                    <VuiButton variant="gradient" color="info" onClick={() => handleDialogBox()}>
                      Cancel
                    </VuiButton>
                  </VuiBox>
                </VuiBox>
              </DialogActions>
            </VuiBox>
          )}
        </Dialog>

        {orderListBoolean === true ? (
          <VuiBox sx={{ textAlign: "center" }}>
            <VuiTypography color="text" fontWeight="bold">
              No orders found
            </VuiTypography>
          </VuiBox>
        ) : null}
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default ParcelOrderList;
