// @mui material components
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { getAvailableLclContainer } from "services/container";

// Data
import dataTableData from "layouts/pages/container/data/dataTableData";

function AvailableLCLContainer() {
  let history = useHistory();

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return date;
  };

  let getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + " : " + d.getUTCMinutes();
    return time;
  };

  const dateWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();
    return time;
  };

  useEffect(async () => {
    await fetchAvailableLclContainerDetails();
  }, []);

  const [availableLclContainerData, setavailableLclContainerData] = useState("");

  const fetchAvailableLclContainerDetails = () => {
    getAvailableLclContainer().then((Response) => {
      console.log(Response);
      let newResponse = [];
      for (let item of Response) {
        newResponse.push({
          ...item,
          // departureTime: dateWithTime(item.departureTime),
          // arrivalTime: dateWithTime(item.arrivalTime),
          readyToLoad: getDate(item.readyToLoad),
          action: (
            <VuiButton variant="gradient" color="info" onClick={() => addLclContainerDetail(item)}>
              BookLclContainer
            </VuiButton>
          ),
        });
      }
      setavailableLclContainerData(newResponse);
    });
  };

  const addLclContainerDetail = (data) => {
    console.log(data);
    history.push({
      pathname: "/shipper/book-lcl-container",
      details: data,
    });
  };

  const dataTableData = {
    columns: [
      { Header: "Name", accessor: "name", align: "center" },
      { Header: "Current Port", accessor: "currentPort", align: "center" },
      { Header: "Destination Port", accessor: "destinationPort", align: "center" },
      { Header: "Read to Load", accessor: "readyToLoad", align: "center" },
      {
        Header: "Departure Time",
        accessor: "departureTime",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {getDate(cell.row.values.departureTime)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.departureTime)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Arrival Time",
        accessor: "arrivalTime",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {getDate(cell.row.values.arrivalTime)}
            </VuiTypography>
            <VuiTypography variant="sm" color="text" component="p">
              {getTime(cell.row.values.arrivalTime)}
            </VuiTypography>
          </>
        ),
      },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: availableLclContainerData ? availableLclContainerData : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="text">
              Available LCL Containers
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all available Lcl Containers.
            </VuiTypography>
          </VuiBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AvailableLCLContainer;
