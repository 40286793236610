import React from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// NewUser page components
import FormField from "layouts/pages/account/components/FormField";
//import FormField from "layouts/pages/users/new-user/components/FormField";
import container from "./container.png";
import truck from "./truck.png";
import boxes from "./boxes.png";
import bigBags from "./bigBags.png";
import sacks from "./sacks.png";
import barrel from "./barrel.png";

import { FormLabel, Radio, RadioGroup } from "@mui/material";
import VuiSwitch from "components/VuiSwitch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useContext } from "react";
import { LoadCalculatorContext } from "../../loadCalculatorContext";
import { width } from "@mui/system";
//import FormControlLabel from "assets/theme/components/form/formControlLabel";

function UserInfo(props) {
  // const { formField, values, errors, touched } = formData;
  // const { firstName, lastName, company, email, password, repeatPassword } = formField;
  // const {
  //  firstName: firstNameV,
  //  lastName: lastNameV,
  //  company: companyV,
  //  email: emailV,
  //  password: passwordV,
  //  repeatPassword: repeatPasswordV,
  // } = values;
  // const [containerValue, setContainerValue] = useState(false);
  //const [truckValue, setTruckValue] = useState(false);
  //const [packValue, setPackValue] = useState(false);

  const { values, handleChange } = props;

  //const handleClick = (e) => {
  // const target = e.target;
  // const name = target.name;
  // console.log(target.value);
  // if (name === "truck") {
  // setTruckValue(true);
  // setContainerValue(false);
  //   setEquipment("truck");
  //  } else {
  // setTruckValue(false);
  // setContainerValue(true);
  //   setEquipment("container");
  // }
  // };
  //console.log(equipment);

  return (
    <VuiBox>
      <VuiBox>
        <VuiBox textAlign="center">
          <VuiTypography variant="SubTitle1" color="text" fontWeight="light" fontSize="1.5rem">
            Select Equipment
          </VuiTypography>
        </VuiBox>
        <Grid container marginTop={"2rem"} textAlign="center">
          <Grid item xs={6}>
            <VuiBox>
              <label style={{ cursor: "pointer" }}>
                <img src={container} alt="GG" width={"80%"} />
                <VuiTypography
                  display="inline-block"
                  color="text"
                  fontWeight="light"
                  fontSize="1.2rem"
                >
                  Container
                </VuiTypography>
                <input
                  style={{ height: "15px", width: "15px", marginLeft: "8px" }}
                  type="radio"
                  name="equipment"
                  value="Container"
                  onChange={handleChange}
                  checked={values.equipment === "Container"}
                ></input>
              </label>
            </VuiBox>
          </Grid>
          <Grid item xs={6}>
            <VuiBox>
              <label style={{ cursor: "pointer" }}>
                <img src={truck} alt="GG" width={"80%"} />
                <VuiTypography
                  color="text"
                  marginLeft={"35px"}
                  display={"inline-block"}
                  fontWeight="light"
                  fontSize="1.2rem"
                >
                  Truck
                </VuiTypography>
                <input
                  style={{ height: "15px", width: "15px", marginLeft: "8px" }}
                  name="equipment"
                  type="radio"
                  value="Truck"
                  onChange={handleChange}
                  checked={values.equipment === "Truck"}

                  //  value={"truck"}
                  //   onClick={handleClick}
                />
              </label>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>

      <VuiBox mt={3} textAlign="center">
        <VuiTypography variant="SubTitle1" color="text" fontWeight="light" fontSize="1.5rem">
          Select Packing Type
        </VuiTypography>
        <Grid container marginTop={"3rem"} textAlign="center">
          <Grid item xs={3}>
            <VuiBox>
              <label style={{ cursor: "pointer" }}>
                <img src={boxes} alt="GG" height={"90rem"} />
                <VuiTypography display="block" color="text" fontWeight="light" fontSize="1rem">
                  Boxes
                </VuiTypography>
                <input
                  style={{ height: "15px", width: "15px" }}
                  name="cargoType"
                  type="radio"
                  value="Boxes"
                  onChange={handleChange}
                  checked={values.cargoType === "Boxes"}
                  // onClick={handleClick}
                ></input>
              </label>
            </VuiBox>
          </Grid>
          <Grid item xs={3}>
            <VuiBox color="white" fontWeight="light" fontSize="default">
              <label>
                <img src={bigBags} alt="GG" height={"90rem"} />
                <VuiTypography display="block" color="text" fontSize="1rem" fontWeight="light">
                  BigBags
                </VuiTypography>
                <input
                  style={{ height: "15px", width: "15px" }}
                  name="cargoType"
                  type="radio"
                  value="BigBags"
                  onChange={handleChange}
                  checked={values.cargoType === "BigBags"}
                  // onClick={handleClick}
                ></input>
              </label>
            </VuiBox>
          </Grid>
          <Grid item xs={3}>
            <VuiBox color="white" fontWeight="light" fontSize="default">
              <label>
                <img src={barrel} alt="GG" height={"90rem"} />
                <VuiTypography display="block" color="text" fontSize="1rem" fontWeight="light">
                  Barrel
                </VuiTypography>
                <input
                  style={{ height: "15px", width: "15px" }}
                  name="cargoType"
                  type="radio"
                  value="Barrel"
                  onChange={handleChange}
                  checked={values.cargoType === "Barrel"}
                  // onClick={handleClick}
                ></input>
              </label>
            </VuiBox>
          </Grid>
          <Grid item xs={3}>
            <VuiBox color="white" fontWeight="light" fontSize="1rem">
              <label>
                <img src={sacks} alt="GG" height={"90rem"} />
                <VuiTypography display="block" color="text" fontSize="1rem" fontWeight="light">
                  Sacks
                </VuiTypography>
                <input
                  style={{ height: "15px", width: "15px" }}
                  name="cargoType"
                  type="radio"
                  value="Sacks"
                  onChange={handleChange}
                  checked={values.cargoType === "Sacks"}
                  // onClick={handleClick}
                ></input>
              </label>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

// typechecking props for UserInfo
//UserInfo.propTypes = {
// formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
//};

export default UserInfo;
