// prop-type is a library for typechecking of props
import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import container from "./container.png";
import truck from "./truck.png";
import boxes from "./boxes.png";
import bigBags from "./bigBags.png";
import sacks from "./sacks.png";
import barrel from "./barrel.png";
import pellets from "./pellets.png";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

// NewUser page components

import FormField from "layouts/pages/users/new-user/components/FormField";
import VuiInput from "components/VuiInput";

function Socials(props) {
  const { values, handleChange } = props;
  const [imgSrc, setImgSrc] = useState("");
  const [equipImgSrc, setEquipImgSrc] = useState("");
  const [pelletBox, setPelletbox] = useState(false);

  const pelletBoxHandler = (e) => {
    const value = e.target.value;
    console.log(value);
    if (value === "Pelletize") {
      setPelletbox(true);
    } else {
      setPelletbox(false);
    }
  };

  const imgHandler = () => {
    if (values.cargoType === "Boxes") {
      setImgSrc(boxes);
    }
    if (values.cargoType === "BigBags") {
      setImgSrc(bigBags);
    }
    if (values.cargoType === "Sacks") {
      setImgSrc(sacks);
    }
    if (values.cargoType === "Barrel") {
      setImgSrc(barrel);
    }
  };

  const equipImgHandler = () => {
    if (values.equipment === "Container") {
      setEquipImgSrc(container);
    }
    if (values.equipment === "Truck") {
      setEquipImgSrc(truck);
    }
  };

  useEffect(() => {
    imgHandler();
    equipImgHandler();
  }, []);

  //const { formField, values, errors, touched } = formData;
  //const { twitter, facebook, instagram } = formField;
  //const { twitter: twitterV, facebook: facebookV, instagram: instagramV } = values;
  //<img src={pellets} alt="GG" width={107} height={100} />;
  return (
    <VuiBox>
      <VuiBox textAlign="center">
        <VuiTypography color="text" fontWeight="light" fontSize="1.3rem">
          Select Equipment for cargo loading
        </VuiTypography>
      </VuiBox>
      <Grid container marginTop={"3rem"} marginBottom="3rem" textAlign="center">
        <Grid item xs={5}>
          <img src={imgSrc} alt="GG" width={"60%"} />

          <VuiTypography color="text" fontWeight="light" fontSize="1.1rem">
            {values.cargoType}
          </VuiTypography>
        </Grid>
        <Grid item xs={2}>
          <VuiTypography color="text" fontWeight="light" fontSize="2rem" marginTop="2rem">
            <FontAwesomeIcon icon={faAngleRight} />
          </VuiTypography>
        </Grid>
        <Grid item xs={5}>
          <img src={equipImgSrc} alt="GG" width={"60%"} />
          <VuiTypography color="text" fontWeight="light" fontSize="1.1rem">
            {values.equipment}
          </VuiTypography>
        </Grid>
      </Grid>
      <VuiBox textAlign="center" marginBottom="3rem">
        <VuiTypography display="inline-block" color="text" fontWeight="light" fontSize="1.3rem">
          Cargo stuffing directly into {values.equipment}
        </VuiTypography>
        <input
          style={{ height: "15px", width: "15px", marginLeft: "8px" }}
          name="loadingType"
          type="radio"
          value="Direct"
          onChange={handleChange}
          checked={values.loadingType === "Direct"}
          onClick={(e) => pelletBoxHandler(e)}
        ></input>
      </VuiBox>
      <Grid container textAlign="center" marginBottom={"3rem"}>
        <Grid item xs={4}>
          <img src={imgSrc} alt="GG" width={"60%"} />
          <VuiTypography color="text" fontWeight="light" fontSize="1.1rem">
            {values.cargoType}
          </VuiTypography>
        </Grid>
        <Grid item xs={1}>
          <VuiTypography color="text" fontWeight="light" fontSize="2rem" marginTop="2rem">
            <FontAwesomeIcon icon={faAngleRight} />
          </VuiTypography>
        </Grid>
        <Grid item xs={2} marginTop="2rem">
          <img src={pellets} alt="GG" width={"80%"} />;
        </Grid>
        <Grid item xs={1}>
          <VuiTypography color="text" fontWeight="light" fontSize="2rem" marginTop="2rem">
            <FontAwesomeIcon icon={faAngleRight} />
          </VuiTypography>
        </Grid>
        <Grid item xs={4}>
          <img src={equipImgSrc} alt="GG" width={"60%"} />
          <VuiBox color="text" fontWeight="light" fontSize="1.1rem">
            {values.equipment}
          </VuiBox>
        </Grid>
      </Grid>
      <VuiBox textAlign="center">
        <VuiTypography color="text" fontWeight="light" fontSize="1.3rem" display="inline-block">
          Pre-shipment cargo pelletizing
        </VuiTypography>
        <input
          style={{ height: "15px", width: "15px", marginLeft: "8px" }}
          name="loadingType"
          type="radio"
          value="Pelletize"
          checked={values.loadingType === "Pelletize"}
          onChange={handleChange}
          onClick={(e) => pelletBoxHandler(e)}
        ></input>
      </VuiBox>
      {pelletBox ? (
        <VuiBox>
          <VuiBox marginTop="50px">
            <VuiTypography color="text" fontWeight="light" variant="SubTitle1">
              Enter Pellets Parameters
            </VuiTypography>
          </VuiBox>
          <Grid
            container
            justifyContent={"center"}
            textAlign={"center"}
            alignItems={"center"}
            marginTop={"20px"}
          >
            <Grid container item xs={5}>
              <VuiBox>
                <img src={pellets} alt="GG" width={173} height={100} />
              </VuiBox>
            </Grid>
            <Grid container item xs={6} spacing={1}>
              <Grid container item xs={12}>
                <Grid item xs={5}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    Length
                  </VuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <VuiInput
                    type="number"
                    name="pLength"
                    defaultValue={values.pLength}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    mm
                  </VuiTypography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={5}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    Width
                  </VuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <VuiInput
                    type="number"
                    name="pWidth"
                    defaultValue={values.pWidth}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    mm
                  </VuiTypography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={5}>
                  <VuiTypography type="number" variant="h6" color="text" fontWeight="light">
                    Height
                  </VuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <VuiInput name="pHeight" defaultValue={values.pHeight} onChange={handleChange} />
                </Grid>
                <Grid item xs={3}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    mm
                  </VuiTypography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={5}>
                  <VuiTypography type="number" variant="h6" color="text" fontWeight="light">
                    Payload
                  </VuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <VuiInput
                    type="number"
                    name="pPayload"
                    defaultValue={values.pPayload}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    kg
                  </VuiTypography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={5}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    Thickness of plate
                  </VuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <VuiInput
                    type="number"
                    name="thickness"
                    defaultValue={values.thickness}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    mm
                  </VuiTypography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={5}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    Max loading height level
                  </VuiTypography>
                </Grid>
                <Grid item xs={4}>
                  <VuiInput
                    type="number"
                    name="loadHeight"
                    defaultValue={values.loadHeight}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <VuiTypography variant="h6" color="text" fontWeight="light">
                    mm
                  </VuiTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
      ) : (
        <VuiBox />
      )}
    </VuiBox>
  );
}

// typechecking props for Socials
//Socials.propTypes = {
// formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
//};

export default Socials;
