const axios = require("axios");
import swal from "sweetalert";
import Swal from "sweetalert2";

import { baseUrl } from "./../helpers/baseUrl";

export async function createShipment(data) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.post(`${baseUrl}/shipper/search/shipment/create`, data);
  if (response) {
    console.log(response, "parcelress");
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getAddress(address) {
  const response = await axios.get(
    `${baseUrl}/serviceProvider/common/google-autocomplate?input=${address}`
  );
  if (response) {
    console.log("parcelAdd>>>>>>>>>>>>>>>>>>>>>", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getAddressNew(addressNew) {
  console.log(addressNew);
  const response = await axios.get(
    `${baseUrl}/serviceProvider/common/google-address?address=${addressNew}`
    // `https://maps.googleapis.com/maps/api/geocode/json?address=${addressNew}&sensor=true&key=AIzaSyCJEJubkhvlr29Mstr8rI-P1Aiumvem95A`
  );
  if (response) {
    console.log("parcelAddress>>>>>>>>>>>>>>>>>>>>>", response);
    return response.data.results;
  } else {
    swal("", response.message, "error");
  }
}

export async function getAddressDetails(placeId) {
  const response = await axios.get(
    `${baseUrl}/serviceProvider/common/google-address?place_id=${placeId}`
  );
  if (response) {
    console.log("parcelAddDetails>>>>>>>>>>>>>>>>>>>>>", response);
    return response.data.result;
  } else {
    swal("", response.message, "error");
  }
}

export async function getOrders() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(`${baseUrl}/shipper/shipment/order/list`);
    console.log("orderedList", response);
    return response.data;
  } catch (err) {
    console.log("orderList err", err.response);
    return err.response.data;
  }
}

export async function getSearchedOrders() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(`${baseUrl}/shipper/shipment/list`);
    console.log("recentSearches", response);
    return response.data;
  } catch (err) {
    console.log("recentSearchesError", err.response);
    return err.response.data;
  }
}

export async function validateAddress(validAddress) {
  console.log(validAddress);
  // var tokenStr = localStorage.getItem("jwtToken");
  // axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.post(
      `${baseUrl}/shipper/search/shipment/address/validate`,
      validAddress
    );
    console.log("AddressValid", response);

    return response.data.validation_results;
  } catch (err) {
    console.log("AddressInvalid", err.response);
    return err.response;
  }

  // const response = await axios.post(
  //   `${baseUrl}/shipper/search/shipment/address/validate`,
  //   validAddress
  // );
  // if (response) {
  //   console.log("Addressvalid>>>>>>>>>>>>>>>>>>>>>", response);

  //   // if (!Object.keys(response.data.validation_results).length) {
  //   //   Object.assign(response.data.validation_results, { is_valid: true });
  //   // }

  //   // return response.data.validation_results;

  //   return response;
  // } else {
  //   console.log("AddressInvalid>>>>>>>>>>>>>>>>>>>>>", response);
  // }
}

export async function createTransaction(transactionData) {
  // var tokenStr = "ShippoToken shippo_live_89256cb93b0b80e8c2a864106e079bbf50ae91d8";
  // axios.defaults.headers.common["authorization"] = tokenStr;
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.post(
    `${baseUrl}/shipper/shipment/create/transaction`,
    transactionData
  );
  if (response) {
    console.log("createTransactionData", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function postShipmentDetails(buyData) {
  console.log("buyData", buyData);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.post(`${baseUrl}/shipper/shipment/details`, buyData);
  if (response) {
    console.log("shipmentDetailsData", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getShipmentDetails(id) {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(`${baseUrl}/shipper/shipment/details?shipmentID=${id}`);
    console.log("shipmentIdDetailsData", response);
    return response.data;
  } catch (err) {
    console.log("getShipmentDetailsError", err.response);
  }

  // const response = await axios.get(`${baseUrl}/shipper/shipment/details`, shipmentId);
  // if (response) {
  //   console.log("shipmentIdDetailsData", response);
  //   return response.data;
  // } else {
  //   swal("", response.message, "error");
  // }
}

export async function updateAddress(address) {
  console.log("updateAddress", address);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.post(`${baseUrl}/shipper/shipment/address/update`, address);
  if (response) {
    console.log("updatedAddressData", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function createBillingAddress(address) {
  console.log("createBillingAddress", address);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.post(`${baseUrl}/shipper/shipment/address/billing/create`, address);
  if (response) {
    console.log("createBillingAddress", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function initiateTransaction(orderId) {
  console.log("InitiatedTransaction", orderId);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/shipper/shipment/initiate/transaction?orderID=${orderId}`
  );
  if (response) {
    console.log("updatedAddressData", response);

    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getPaymentAddress() {
  console.log("getPaymentAddress");
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/shipper/shipment/payment/address`);
  if (response) {
    console.log("paymentAddress", response);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function checkPaymentBTNT(checkPayment) {
  console.log("checkBTNT", checkPayment);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/shipper/shipment/check/transaction/btnt`,
      checkPayment
    );
    console.log("checkBTNT", response);
    return response.data;
  } catch (err) {
    console.log("errorpayment", err.response);
    return err.response.data;
  }
}

export async function orderRefund(refundData) {
  console.log("orderRefund", refundData);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}/shipper/shipment/refund/transaction`, refundData);
    console.log("orderRefund", response);
    return response.data;
  } catch (err) {
    console.log("errorRefund", err.response);
    return err.response.data;
  }
}

export async function shipmentDetailsUpdate(updatedShipmentData) {
  console.log("shipmentDetailsUpdate", updatedShipmentData);
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/shipper/shipment/details/update`,
      updatedShipmentData
    );
    console.log("shipmentDetailsUpdate", response);
    return response.data;
  } catch (err) {
    console.log("errorShipmentDetailsUpdate", err.response);
    return err.response.data;
  }
}
