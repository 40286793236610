import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import Picture from "./searates-logo.png";
// import { style } from "@mui/material/node_modules/@mui/system";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: "20px",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    flexDirection: "row",
    textAlign: "right",
    fontSize: "14px",
    display: "flex",
  },
  borderView: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  border: {
    border: "2px solid #e0e0e0",
  },
});

// Create Document Component
const MyDocument = (props) => {
  console.log(">>>>>>>>>>>>>>>>>> PDF Data", props.value.logo[0]);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.image}>
          {props.value.logo ? (
            <Image
              allowDangerousPath
              src={props.value.logo[0]}
              style={{ width: "100px", height: "30px" }}
            />
          ) : (
            <Text>No logo</Text>
          )}
          <Text style={styles.title}>Bill of Lading</Text>
        </View>
        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>Shipper :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Shipper's Reference1 :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Shipper's Reference2 :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "12px", color: "#424242" }}>Carrier's Reference :</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                  Reference Reference Reference
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "12px", color: "#424242" }}>
                  Unique Consignment Reference :
                </Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                  Reference Reference Reference
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>Consignee :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>Carrier Name :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>
                Notify Party (If not Consignee) :
              </Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>Additional Notify Party :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Pre-Carriage By :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Place of Receipt :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>Additional Information :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "22%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Transport :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>

              <View style={{ width: "24%", marginLeft: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Voyage No :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Place of Delivery :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Port of Loading :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Port of Discharge :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "10px" }}>
          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "10px", fontWeight: "bold", color: "white" }}>
                Marks & Numbers
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "10px", fontWeight: "bold", color: "white" }}>
                Kind & No of Packages
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "10px", fontWeight: "bold", color: "white" }}>
                Description of Goods
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "10px", fontWeight: "bold", color: "white" }}>
                Net Weight (Kg)
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "10px", fontWeight: "bold", color: "white" }}>
                Gross Weight (Kg)
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "10px", fontWeight: "bold", color: "white" }}>
                Measurements (m³)
              </Text>
            </View>
          </View>
        </View>

        <View style={{ width: "100%" }}>
          {[1, 2].map(() => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderBottom: "1px solid black",
                }}
              >
                <View style={{ width: "16.66%", marginRight: "1%" }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                      Content
                    </Text>
                  </View>
                </View>

                <View style={{ width: "16.66%", marginRight: "1%" }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                      Content
                    </Text>
                  </View>
                </View>

                <View style={{ width: "16.66%", marginRight: "1%" }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                      Content
                    </Text>
                  </View>
                </View>

                <View style={{ width: "16.66%", marginRight: "1%" }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                      Content
                    </Text>
                  </View>
                </View>

                <View style={{ width: "16.66%", marginRight: "1%" }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                      Content
                    </Text>
                  </View>
                </View>

                <View style={{ width: "16.66%", marginRight: "1%" }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                      Content
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "10px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "12px", color: "#424242" }}>Additional information :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>Content</Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Place of Issue :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "12px", color: "#424242" }}>Date :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                    Reference Reference Reference
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "12px", color: "#424242" }}>Signatory Company :</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                  Reference Reference Reference
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "12px", color: "#424242" }}>
                  Name of Authorized Signatory :
                </Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                  Reference Reference Reference
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "12px", color: "#424242" }}>Signature :</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "10px", color: "#616161", padding: "5px" }}>
                  Reference Reference Reference
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
