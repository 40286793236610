const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export async function getAllUsers() {
  try {
    const response = await axios.get("/api/users");
    console.log("response  ", response);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function createUser(data) {
  try {
    const response = await axios.post(`${baseUrl}/user/auth/register`, data);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function verifyUser(token) {
  try {
    const response = await axios.get(`${baseUrl}/user/auth/verify/${token}`);
    return response.data;
  } catch (e) {
    return e.response;
  }
}

// export async function loginUser(token) {
//     try{
//         const response = await axios.get(`${baseUrl}/user/auth/verify/${token}`);
//         return response.data;
//     }
//     catch(e){
//         return e.response;
//     }
// }

export async function login(userData) {
  console.log("login called", userData);
  try {
    const res = await axios.post(`${baseUrl}/user/auth/login`, userData);
    const { token } = res.data;

    localStorage.setItem("jwtToken", token);
    //Set token on Header request
    setAuthToken(token);
    const decodedUser = jwt_decode(token);
    console.log("decode use", decodedUser);
    return res;
  } catch (err) {
    if (err.message === "Network Error") {
      swal("Server is not responding", "Try again!", "error");
    }
    if (err.response.status === 500) {
      swal("Something went wrong", "try again!", "error");
    }
    if (err.response) {
      const errors = [];
      if (err.response && err.response.data.msg.includes("User")) {
        errors.push(["User", err.response.data.msg]);
      }
      if (err.response && err.response.data.msg.includes("password")) {
        errors.push(["password", err.response.data.msg]);
      }
      if (err.response && err.response.data.msg.includes("verify")) {
        swal("Please verify your email address first", "Thanks!", "error");
      }
    }
    return err.response;
  }
}

export async function setCurrentUser(decodedUser) {
  return {
    type: SET_CURRENT_USER,
    payload: decodedUser,
  };
}

export async function logoutUser() {
  //Remove Token from Local Storage
  localStorage.removeItem("jwtToken");

  //Remove Token from Request
  setAuthToken(false);
}

export async function resetPassword(passData) {
  console.log("passData", passData);
  try {
    const response = await axios.post(`${baseUrl}/user/auth/change-password`, passData);
    console.log("password", response);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function changePassword(passData) {
  console.log("passData", passData);
  try {
    const response = await axios.post(`${baseUrl}/admin/users/update-password`, passData);
    console.log("password", response);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function quickRequestForm(quickRequestData) {
  try {
    const response = await axios.post(`${baseUrl}/shipper/quickRequest`, quickRequestData);
    console.log("quickRequest", response);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function supportRequestForm(supportRequestData) {
  try {
    const response = await axios.post(`${baseUrl}/user/contact`, supportRequestData);
    console.log("support", response);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function upgradeToMerchant() {
  var tokenStr = localStorage.getItem("jwtToken");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.post(`${baseUrl}/shipper/shipment/upgrade/merchant`);
  if (response) {
    console.log(response, "upgradeToMerchant");
    return response;
  } else {
    swal("", response.message, "error");
  }
}

export async function googleLogin(userData) {
  console.log("googleLogin called", userData);
  try {
    // var tokenStr = localStorage.getItem("jwtToken");
    // axios.defaults.headers.common["authorization"] = tokenStr;
    const res = await axios.post(`${baseUrl}/user/auth/google`, userData);
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);

    return res;
  } catch (err) {
    return err.response;
  }
}

export async function forgotPassword(email) {
  console.log("forgotPasswordData", email);
  try {
    const res = await axios.post(`${baseUrl}/user/auth/forget-password`, email);
    console.log("forgotPasswordRes", res);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
}
