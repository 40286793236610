import React from "react";
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";
// @mui material components
import { useState, useCallback } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";

let Map = () => {
  return <GoogleMap defaultZoom={10} defaultCenter={{ lat: 45.421532, lng: -75.697189 }} />;
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

function MapBoxContainer() {
  return (
    <VuiBox sx={{ height: "100vh" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </VuiBox>
  );
}

export default MapBoxContainer;
