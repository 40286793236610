import React from "react";
// @mui material components
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";

import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import VuiInput from "components/VuiInput";
import VuiBadge from "components/VuiBadge";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import VuiButton from "components/VuiButton";
import Icon from "@mui/material/Icon";
import swal from "sweetalert";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "layouts/loader/loader";

import { getPorts } from "services/shipService";
import { searchShip, searchLcl, searchFcl, searchShipNew } from "services/logisticsService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineItem from "examples/Timeline/TimelineItem";
import { IoBoatOutline } from "react-icons/io5";
import { MdCompareArrows } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { IoMdWarning } from "react-icons/io";
import ErrorImg from "./exclamation-mark.png";

import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import DataTable from "examples/Tables/DataTable";
import QuickRequestDetails from "../container/quick-request";

import { useForm } from "react-hook-form";
import TabPanel from "./tab";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import LockIcon from "@mui/icons-material/Lock";
import ShipPic from "./favicon.png";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { getAddress, createShipment } from "services/parcelService";

import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { getAddressNew } from "services/parcelService";
import { getAddressDetails } from "services/parcelService";
import { validateAddress } from "services/parcelService";
import { createTransaction } from "services/parcelService";
import NewLoader from "./Loader.gif";
import { isMobile, isBrowser } from "react-device-detect";
import "./tab.css";
import { useVisionUIController } from "context";
import colors from "assets/theme/base/colors";
import colorsNew from "assets/themeLight/base/colors";
import { getShipmentDetails } from "services/parcelService";
import { shipmentDetailsUpdate } from "services/parcelService";

const VerifyParcelDetails = () => {
  let history = useHistory();
  const { id } = useParams();

  const [controller] = useVisionUIController();
  const { themeMode } = controller;
  const palette = themeMode === true ? colors : colorsNew;
  console.log("theme", themeMode);
  const { dark, white, text, inputColors, gradients, transparent, grey, info, muiTab } = palette;

  const color = themeMode === true ? "#F2F0FE" : "#110F26";

  const [loading, setLoading] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [selectedMainTab, setSelectedMainTab] = useState(0);
  const [selectedFreightosTab, setSelectedFreightosTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const { values } = breakpoints;

  const [parcelRes, setParcelRes] = useState();
  const [parcelboolean, setParcelBoolean] = useState(false);
  const [parcelRatesboolean, setParcelRatesBoolean] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [responseLoader, setResponseLoader] = useState(false);
  const [responseLoader1, setResponseLoader1] = useState(false);

  const [fromName, setFromName] = useState("");
  const [fromStreet1, setFromStreet1] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [fromState, setFromState] = useState("");
  const [fromZip, setFromZip] = useState("");
  const [fromCountry, setFromCountry] = useState("");
  const [fromPhone, setFromPhone] = useState("");
  const [fromEmail, setFromEmail] = useState("");

  const [toName, setToName] = useState("");
  const [toStreet1, setToStreet1] = useState("");
  const [toCity, setToCity] = useState("");
  const [toState, setToState] = useState("");
  const [toZip, setToZip] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [toPhone, setToPhone] = useState("");
  const [toEmail, setToEmail] = useState("");

  const [parcelLength, setParcelLength] = useState("");
  const [parcelWidth, setParcelWidth] = useState("");
  const [parcelHeight, setParcelHeight] = useState("");
  const [parcelWeight, setParcelWeight] = useState("");
  const [parcelDistanceUnit, setParcelDistanceUnit] = useState({ value: "in", label: "IN" });
  const [parcelMassUnit, setParcelMassUnit] = useState({ value: "lb", label: "LB" });

  const [shipmentId, setShipmentId] = useState("");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    getUpdatedShipmentDetails();
  }, []);

  // useEffect(() => {
  //   handleConfirmedData();
  // }, []);

  const getUpdatedShipmentDetails = async () => {
    const updatedDetails = await getShipmentDetails(id);
    console.log("updated", updatedDetails);

    setShipmentId(updatedDetails.id);
    setOrderId(updatedDetails.orderID);

    // setFromCity(updatedDetails.addressFromObj.city);
    // setFromCountry(updatedDetails.addressFromObj.country);
    // setFromEmail(updatedDetails.addressFromObj.email);
    // setFromName(updatedDetails.addressFromObj.name);
    // setFromPhone(updatedDetails.addressFromObj.phoneNumber);
    // setFromStreet1(updatedDetails.addressFromObj.street1);
    // setFromZip(updatedDetails.addressFromObj.zip);
    // updatedDetails.addressFromObj.state === "null"
    //   ? setFromState("")
    //   : setFromState(updatedDetails.addressFromObj.state);

    // setToCity(updatedDetails.addressToObj.city);
    // setToCountry(updatedDetails.addressToObj.country);
    // setToEmail(updatedDetails.addressToObj.email);
    // setToName(updatedDetails.addressToObj.name);
    // setToPhone(updatedDetails.addressToObj.phoneNumber);
    // setToStreet1(updatedDetails.addressToObj.street1);
    // setToZip(updatedDetails.addressToObj.zip);
    // updatedDetails.addressToObj.state === "null"
    //   ? setToState("")
    //   : setToState(updatedDetails.addressFromObj.state);

    // setParcelHeight(updatedDetails.parcels[0].height);
    // setParcelLength(updatedDetails.parcels[0].length);
    // setParcelWidth(updatedDetails.parcels[0].width);
    // setParcelWeight(updatedDetails.parcels[0].weight);
    // setParcelDistanceUnit(updatedDetails.parcels[0].distance_unit);
    // setParcelMassUnit(updatedDetails.parcels[0].mass_unit);

    // const finalData = {
    //   addressFrom: {
    //     name: updatedDetails.addressFromObj.name,
    //     street1: updatedDetails.addressFromObj.street1,
    //     city: updatedDetails.addressFromObj.city,
    //     state:
    //       updatedDetails.addressFromObj.state === "null" ? "" : updatedDetails.addressFromObj.state,
    //     zip: updatedDetails.addressFromObj.zip,
    //     country: updatedDetails.addressFromObj.country,
    //     phone: updatedDetails.addressFromObj.phoneNumber,
    //     email: updatedDetails.addressFromObj.email,
    //   },
    //   addressTo: {
    //     name: updatedDetails.addressToObj.name,
    //     street1: updatedDetails.addressToObj.street1,
    //     city: updatedDetails.addressToObj.city,
    //     state:
    //       updatedDetails.addressToObj.state === "null" ? "" : updatedDetails.addressFromObj.state,
    //     zip: updatedDetails.addressToObj.zip,
    //     country: updatedDetails.addressToObj.country,
    //     phone: updatedDetails.addressToObj.phoneNumber,
    //     email: updatedDetails.addressToObj.email,
    //   },
    //   parcels: [
    //     {
    //       weight: updatedDetails.parcels[0].weight,
    //       length: updatedDetails.parcels[0].length,
    //       width: updatedDetails.parcels[0].width,
    //       height: updatedDetails.parcels[0].height,
    //       distance_unit: updatedDetails.parcels[0].distance_unit,
    //       mass_unit: updatedDetails.parcels[0].mass_unit,
    //     },
    //   ],
    //   extra: {},
    //   async: false,
    // };

    // createShipment(finalData).then((response) => {
    //   console.log("FullData", response);

    //   console.log("parceelllllRatesData", response.rates);
    //   setShipmentId(response.shipmentID);
    //   setResponseMsg(response.messages);
    //   setParcelRes(response.rates);
    //   setResponseLoader(false);

    //   console.log(response.rates.length);

    //   if (response.rates.length > 0) {
    //     setParcelBoolean(true);
    //   } else {
    //     setParcelRatesBoolean(true);
    //   }
    // });

    const handleConfirmedData = () => {
      setResponseLoader(true);
      setParcelBoolean(false);
      setParcelRatesBoolean(false);
      console.log(responseLoader, "loader");
      const finalData = {
        addressFrom: {
          name: updatedDetails.addressFromObj.name,
          street1: updatedDetails.addressFromObj.street1,
          city: updatedDetails.addressFromObj.city,
          state:
            updatedDetails.addressFromObj.state === "null"
              ? ""
              : updatedDetails.addressFromObj.state,
          zip: updatedDetails.addressFromObj.zip,
          country: updatedDetails.addressFromObj.country,
          phone: updatedDetails.addressFromObj.phoneNumber,
          email: updatedDetails.addressFromObj.email,
        },
        addressTo: {
          name: updatedDetails.addressToObj.name,
          street1: updatedDetails.addressToObj.street1,
          city: updatedDetails.addressToObj.city,
          state:
            updatedDetails.addressToObj.state === "null" ? "" : updatedDetails.addressFromObj.state,
          zip: updatedDetails.addressToObj.zip,
          country: updatedDetails.addressToObj.country,
          phone: updatedDetails.addressToObj.phoneNumber,
          email: updatedDetails.addressToObj.email,
        },
        parcels: [
          {
            weight: updatedDetails.parcels[0].weight,
            length: updatedDetails.parcels[0].length,
            width: updatedDetails.parcels[0].width,
            height: updatedDetails.parcels[0].height,
            distance_unit: updatedDetails.parcels[0].distance_unit,
            mass_unit: updatedDetails.parcels[0].mass_unit,
          },
        ],
        extra: {},
        async: false,
      };

      console.log(finalData);

      createShipment(finalData).then((response) => {
        console.log("FullData", response);

        console.log("parceelllllRatesData", response.rates);
        // setShipmentId(response.shipmentID);
        setResponseMsg(response.messages);
        setParcelRes(response.rates);
        setResponseLoader(false);

        console.log(response.rates.length);

        if (response.rates.length > 0) {
          setParcelBoolean(true);
        } else {
          setParcelRatesBoolean(true);
        }
      });
    };

    handleConfirmedData();
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);
    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetMainTabValue = (event, newValue) => setSelectedMainTab(newValue);

  const handleSetFreightosTabValue = (event, newValue) => setSelectedFreightosTab(newValue);

  const handleSetTabValue = (event, newValue) => setSelectedTab(newValue);

  const handleSenderNxt = () => {
    setSelectedFreightosTab((prevState) => prevState + 1);
  };

  const handleReceiverNxt = () => {
    setSelectedFreightosTab((prevState) => prevState + 1);
  };

  // const handleConfirmedData = () => {
  //   setResponseLoader(true);
  //   setParcelBoolean(false);
  //   setParcelRatesBoolean(false);
  //   console.log(responseLoader, "loader");
  //   const finalData = {
  //     addressFrom: {
  //       name: updatedDetails.addressFromObj.name,
  //       street1: updatedDetails.addressFromObj.street1,
  //       city: updatedDetails.addressFromObj.city,
  //       state:
  //         updatedDetails.addressFromObj.state === "null" ? "" : updatedDetails.addressFromObj.state,
  //       zip: updatedDetails.addressFromObj.zip,
  //       country: updatedDetails.addressFromObj.country,
  //       phone: updatedDetails.addressFromObj.phoneNumber,
  //       email: updatedDetails.addressFromObj.email,
  //     },
  //     addressTo: {
  //       name: updatedDetails.addressToObj.name,
  //       street1: updatedDetails.addressToObj.street1,
  //       city: updatedDetails.addressToObj.city,
  //       state:
  //         updatedDetails.addressToObj.state === "null" ? "" : updatedDetails.addressFromObj.state,
  //       zip: updatedDetails.addressToObj.zip,
  //       country: updatedDetails.addressToObj.country,
  //       phone: updatedDetails.addressToObj.phoneNumber,
  //       email: updatedDetails.addressToObj.email,
  //     },
  //     parcels: [
  //       {
  //         weight: updatedDetails.parcels[0].weight,
  //         length: updatedDetails.parcels[0].length,
  //         width: updatedDetails.parcels[0].width,
  //         height: updatedDetails.parcels[0].height,
  //         distance_unit: updatedDetails.parcels[0].distance_unit,
  //         mass_unit: updatedDetails.parcels[0].mass_unit,
  //       },
  //     ],
  //     extra: {},
  //     async: false,
  //   };

  //   console.log(finalData);

  //   createShipment(finalData).then((response) => {
  //     console.log("FullData", response);

  //     console.log("parceelllllRatesData", response.rates);
  //     setShipmentId(response.shipmentID);
  //     setResponseMsg(response.messages);
  //     setParcelRes(response.rates);
  //     setResponseLoader(false);

  //     console.log(response.rates.length);

  //     if (response.rates.length > 0) {
  //       setParcelBoolean(true);
  //     } else {
  //       setParcelRatesBoolean(true);
  //     }
  //   });
  // };

  const handleBuy = async (value) => {
    console.log("value", value);

    const updatedShipmentData = {
      shipmentID: shipmentId,
      orderID: orderId,
      amountBtnt: value.amount_btnt,
      amountUsd: value.amount_usd,
      objectId: value.object_id,
    };

    const updatedShipmentResp = await shipmentDetailsUpdate(updatedShipmentData);

    console.log("updatedResp", updatedShipmentResp);

    history.push({
      pathname: `/shipper/parcel-payment-details/${shipmentId}`,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <VuiBox sx={{ marginTop: "1rem", marginBottom: "2rem", textAlign: "center" }}>
        <VuiTypography color="text" fontWeight="bold">
          Verify your Shipment
        </VuiTypography>
      </VuiBox>

      <Card>
        <TabPanel value={selectedMainTab} index={0}>
          <AppBar
            position="static"
            sx={{
              background: "#d8dcf3",
              borderRadius: "1.25rem",
            }}
          >
            <Tabs
              variant="fullWidth"
              centered={true}
              orientation={tabsOrientation}
              value={selectedFreightosTab}
              onChange={handleSetFreightosTabValue}
              sx={{
                // "& button": { backgroundColor: "#F2F0FE" },
                // "& button.Mui-selected": { backgroundColor: "#110F26" },
                "& button.Mui-selected": {
                  // tabTexTColor
                  color: "#F2F0FE !important",
                },

                "& span.MuiTabs-indicator": {
                  // selectedTabColor
                  backgroundColor: "#2D3B92",
                },
              }}
            >
              <Tab label="Sender" sx={{ color: "#110F26 !important" }} />

              <Tab label="Receiver" sx={{ color: "#110F26 !important" }} />

              <Tab label="Load" sx={{ color: "#110F26 !important" }} />
            </Tabs>
          </AppBar>
          <TabPanel value={selectedFreightosTab} index={0}>
            <Card sx={{ overflow: "visible" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Name*
                  </VuiTypography>
                  <VuiInput placeholder="eg. Micheal" disabled value={fromName} />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Address*
                  </VuiTypography>

                  <VuiInput
                    name="fromStreet1"
                    placeholder="eg:Potters Row"
                    disabled
                    value={fromStreet1}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Country*
                  </VuiTypography>
                  <VuiInput
                    placeholder="eg:United Kingdom"
                    value={fromCountry}
                    name="fromCountry"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    City*
                  </VuiTypography>
                  <VuiInput placeholder="eg:London" disabled value={fromCity} name="fromCity" />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    State
                  </VuiTypography>
                  <VuiInput placeholder="" disabled value={fromState} name="fromState" />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Zip*
                  </VuiTypography>
                  <VuiInput placeholder="eg:E20 1GX" disabled value={fromZip} name="fromZip" />
                </Grid>

                <Grid item xs={12} sm={3} mt={"1.5rem"}>
                  <VuiButton
                    variant="gradient"
                    color="info"
                    size="large"
                    fontSize="16px"
                    onClick={() => handleSenderNxt()}
                  >
                    Next
                  </VuiButton>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value={selectedFreightosTab} index={1}>
            <Card sx={{ overflow: "visible" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Name*
                  </VuiTypography>
                  <VuiInput placeholder="eg. Micheal" disabled value={toName} />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Address*
                  </VuiTypography>

                  <VuiInput
                    name="toStreet1"
                    disabled
                    placeholder="eg:High Beech Road"
                    value={toStreet1}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Country*
                  </VuiTypography>

                  <VuiInput
                    placeholder="eg:United Kingdom"
                    disabled
                    value={toCountry}
                    name="toCountry"
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    City*
                  </VuiTypography>
                  <VuiInput placeholder="Loughton" disabled value={toCity} name="toCity" />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    State*
                  </VuiTypography>
                  <VuiInput placeholder="eg:California" disabled value={toState} name="toState" />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Zip*
                  </VuiTypography>
                  <VuiInput placeholder="IG10" disabled value={toZip} name="toZip" />
                </Grid>

                <Grid item xs={12} sm={3} mt={"1.5rem"}>
                  <VuiButton
                    variant="gradient"
                    color="info"
                    size="large"
                    fontSize="16px"
                    onClick={() => handleReceiverNxt()}
                  >
                    Next
                  </VuiButton>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value={selectedFreightosTab} index={2}>
            <Card sx={{ overflow: "visible" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                  <VuiBox>
                    <VuiTypography variant="button" color="text" fontWeight="regular">
                      Dimensions
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ display: "flex" }}>
                    <VuiInput
                      sx={{ borderRadius: "15px 0px 0px 15px" }}
                      placeholder="Length:9"
                      type="number"
                      name="parcelLength"
                      value={parcelLength}
                      disabled
                    />
                    <VuiInput
                      sx={{ borderRadius: "0px" }}
                      placeholder="Width:9"
                      type="number"
                      name="parcelWidth"
                      value={parcelWidth}
                      disabled
                    />
                    <VuiInput
                      sx={{ borderRadius: "0px 15px 15px 0px" }}
                      placeholder="Height:8"
                      type="number"
                      name="parcelHeight"
                      value={parcelHeight}
                      disabled
                    />
                  </VuiBox>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Measurement (D)
                  </VuiTypography>

                  <VuiInput
                    placeholder="Select"
                    name="parcelDistanceUnit"
                    value={parcelDistanceUnit}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Package Weight
                  </VuiTypography>
                  <VuiInput
                    placeholder="Upto 140 lb or 65kg"
                    type="number"
                    name="parcelWeight"
                    value={parcelWeight}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <VuiTypography variant="button" color="text" fontWeight="regular">
                    Measurement (PW)
                  </VuiTypography>

                  <VuiInput
                    placeholder="Select"
                    disabled
                    name="parcelMassUnit"
                    value={parcelMassUnit}
                  />
                </Grid>
                <Grid item xs={12} mt={"1rem"} sx={{ textAlign: "center" }}>
                  <VuiButton
                    variant="gradient"
                    color="info"
                    size="large"
                    // onClick={() => handleConfirmedData()}
                  >
                    Search
                  </VuiButton>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
        </TabPanel>
      </Card>
      <VuiBox sx={{ margin: "3rem", textAlign: "center" }}>
        <VuiTypography color="text" fontWeight="bold">
          Get real-time updates about your shipment from source to destination.
        </VuiTypography>
      </VuiBox> */}
      <VuiBox sx={{ marginTop: "1rem", textAlign: "center" }}>
        <VuiTypography color="text" fontWeight="bold">
          Rates might have changed due to change in address
        </VuiTypography>
      </VuiBox>
      {parcelboolean === true ? (
        parcelRes.map((val, idx) => {
          return (
            <VuiBox sx={{ overflow: "visible" }}>
              {isMobile ? (
                <VuiBox>
                  <Card sx={{ overflow: "visible", marginTop: "1rem" }}>
                    <VuiBox>
                      <VuiBox>
                        <VuiBadge
                          color="badge"
                          variant="gradient"
                          badgeContent={val.attributes[0]}
                        />
                        <VuiBadge
                          color="badge"
                          variant="gradient"
                          badgeContent={val.attributes[1]}
                        />
                      </VuiBox>
                      <VuiBox>
                        <img src={val.provider_image_200} width="100px" alt="logo" />
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                          {val.provider}
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                          {val.servicelevel.name}
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                          {val.estimated_days} days
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiBox sx={{ display: "flex", alignItems: "center" }}>
                          <VuiBox sx={{ marginRight: "0.5rem" }}>
                            <VuiTypography color="text" fontWeight="light" fontSize="0.8rem">
                              ${val.amount_btnt_in_usd}
                            </VuiTypography>
                          </VuiBox>

                          <VuiBox>
                            <VuiButton
                              name="mobileView"
                              onClick={(e) => handleBuy(val)}
                              sx={{ padding: "10px" }}
                              variant="gradient"
                              color="info"
                              disabled={val.provider === "UPS"}
                            >
                              {val.provider === "UPS" ? "Not Available" : "Pay with BTNT"}
                            </VuiButton>
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </VuiBox>
              ) : (
                <VuiBox sx={{ overflow: "visible" }}>
                  <Card sx={{ overflow: "visible", marginTop: "1rem" }}>
                    <VuiBox sx={{ display: "flex" }}>
                      <VuiBadge color="badge" variant="gradient" badgeContent={val.attributes[0]} />
                      <VuiBadge color="badge" variant="gradient" badgeContent={val.attributes[1]} />
                    </VuiBox>
                    <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
                      <VuiBox sx={{ display: "flex" }}>
                        <VuiBox sx={{}}>
                          <img src={val.provider_image_200} width={40} alt="logo" />
                        </VuiBox>
                        <VuiBox sx={{ marginLeft: "1rem" }}>
                          <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                            {val.provider}
                            {"   "}
                            {val.servicelevel.name}
                          </VuiTypography>
                          <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                            Estimated delivery {val.estimated_days} days
                          </VuiTypography>
                        </VuiBox>
                      </VuiBox>

                      <VuiBox>
                        <VuiBox sx={{ display: "flex", alignItems: "center" }}>
                          <VuiBox marginRight="1rem">
                            <VuiTypography color="text" fontWeight="light" fontSize="0.9rem">
                              ${val.amount_btnt_in_usd}
                            </VuiTypography>
                          </VuiBox>
                          <VuiBox>
                            <VuiButton
                              name="fullView"
                              onClick={(e) => handleBuy(val)}
                              sx={{ fontSize: "0.6rem" }}
                              variant="gradient"
                              color="info"
                              disabled={val.provider === "UPS"}
                            >
                              {val.provider === "UPS" ? "Not Available" : "Pay with BTNT"}
                            </VuiButton>
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </VuiBox>
              )}
            </VuiBox>
          );
        })
      ) : responseLoader ? (
        <VuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <img src={NewLoader} alt="logo" />
          <VuiTypography color="text">Please wait</VuiTypography>
        </VuiBox>
      ) : null}
      {parcelRatesboolean === true ? (
        <Card sx={{ marginTop: "2rem" }}>
          <VuiTypography color="text" textAlign="center" fontSize="2rem">
            No shipment found
          </VuiTypography>
        </Card>
      ) : null}
    </DashboardLayout>
  );
};

export default VerifyParcelDetails;
